import {
    DEAD_FISH_COUNTING_ENGINE_API_KEY,
    DEAD_FISH_COUNTING_ENGINE_STREAMING_RESULT_API,
    DEAD_FISH_COUNTING_ENGINE_STREAMING_VIDEO_API,
    ENGINE_BASE_URL_KEY
} from 'common/constants';
import { EventSourcePolyfill } from 'event-source-polyfill';

export const getStreamingResult = () => {
    const engineBaseUrl = getEngineBaseUrl();
    if (!engineBaseUrl) return;

    const streamingResultUrl = engineBaseUrl + DEAD_FISH_COUNTING_ENGINE_STREAMING_RESULT_API;
    const headers = {
        'X-API-Key': DEAD_FISH_COUNTING_ENGINE_API_KEY,
        'Content-Type': 'text/event-stream'
    };

    return new EventSourcePolyfill(streamingResultUrl, { headers });
};

export const getStreamingVideoUrl = async () => {
    const engineBaseUrl = getEngineBaseUrl();
    if (!engineBaseUrl) return;

    return `${engineBaseUrl}${DEAD_FISH_COUNTING_ENGINE_STREAMING_VIDEO_API}?x_api_key=${DEAD_FISH_COUNTING_ENGINE_API_KEY}`;
};

export const getEngineBaseUrl = () => {
    return localStorage.getItem(ENGINE_BASE_URL_KEY);
};

export const existEngineBaseUrl = () => {
    return localStorage.getItem(ENGINE_BASE_URL_KEY) !== null;
};

export const saveEngineBaseUrl = (url) => {
    localStorage.setItem(ENGINE_BASE_URL_KEY, url);
};

export const removeEngineBaseUrl = () => {
    localStorage.removeItem(ENGINE_BASE_URL_KEY);
};
