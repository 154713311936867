import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import useAppAbility from 'casl/can';
import { Forbidden } from 'pages';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ children, isPrivate = false, role }) => {
    const { can } = useAppAbility();
    const { inProgress } = useMsal();

    if (inProgress !== InteractionStatus.None) return;

    if (isPrivate && !can('manage', role, ['all'])) {
        return <Forbidden />;
    }

    return <>{children}</>;
};

ProtectedRoute.propTypes = {
    children: PropTypes.object,
    isPrivate: PropTypes.bool,
    role: PropTypes.string
};

export default ProtectedRoute;
