import { HomeOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Button, Spin } from 'antd';
import { alertErrorMessage, getAuthority } from 'common/utils';
import { withPageViewTracking } from 'hoc';
import CountryPicker from 'layouts/Header/CountryPicker';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getAvailableTenants } from 'redux/thunks';
import './Organization.scss';

const Organization = () => {
    const { instance } = useMsal();
    const { accounts } = useMsal();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: tenants, loading } = useSelector((s) => s.tenant);

    useEffect(() => {
        if (accounts.length === 0) return;

        dispatch(
            getAvailableTenants({
                userEmail: accounts[0].username,
                homeTenantId: accounts[0].tenantId
            })
        );
    }, [accounts]);

    const handleOrganizationClick = async (tenantId) => {
        localStorage.clear();

        const authority = getAuthority(tenantId);
        const redirectUri = '/';

        try {
            await instance.loginRedirect({ authority, redirectUri });
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <div className="organization bg-inherit">
            <div className="content">
                <div className="flex items-center space-x-2 fixed top-4 right-8 z-10">
                    <div className="country-picker">
                        <CountryPicker />
                    </div>
                    <button
                        className="inline bg-transparent border-none text-white hover:cursor-pointer hover:text-sky-400"
                        onClick={handleLogout}
                    >
                        {t('button.logout')}
                    </button>
                </div>
                <div className="welcome absolute xs:top-[10vh] md:top-[25vh] w-full px-2">
                    <h1 className="xs:text-xl sm:text-2xl md:text-3xl text-white text-center">
                        {t('organization.chooseOrganization')}
                    </h1>
                </div>
                <div className="option flex xs:flex-col md:flex-row xs:space-y-4 md:space-y-0 xs:space-x-0 md:space-x-4 justify-center items-center h-full">
                    {loading ? (
                        <Spin size="large" />
                    ) : tenants.length ? (
                        tenants.map((tenant) => (
                            <Button
                                className="xs:px-2 xs:py-4 sm:px-10 sm:py-4 font-semibold text-sky-900 focus-within:text-sky-900 hover:text-sky-700
                                    xs:w-64 xs:h-32 sm:w-80 sm:h-32 md:w-80 md:h-32 lg:w-96 lg:h-48
                                    bg-white rounded-lg border-white border-solid cursor-pointer transition 
                                    flex flex-col justify-center items-center align-middle space-y-4"
                                onClick={() => handleOrganizationClick(tenant.azureTenantId)}
                                key={tenant.azureTenantId}
                            >
                                <HomeOutlined className="xs:text-4xl sm:text-5xl" />
                                <span className="xs:text-sm sm:text-lg lg:text-xl">
                                    {tenant.tenantName}
                                </span>
                            </Button>
                        ))
                    ) : (
                        <p className="xs:text-base lg:text-lg italic text-red-400 text-center">
                            {t('message.tenantNotFound')}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withPageViewTracking(Organization, 'Choose organization');
