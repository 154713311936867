import {
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    LIGHT_GRAY,
    SUCCESSFULLY_DELETION_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDTable } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { deleteSite, getSites, getSitesContainingPens } from 'redux/thunks';
import Swal from 'sweetalert2';
import RestoreSite from './RestoreSite';
import SiteForm from './SiteForm';

const Site = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: sites, loading } = useSelector((s) => s.site);

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [modalState, setModalState] = useState({ open: false, site: null });
    const [restoreModalState, setRestoreModalState] = useState({ open: false, site: null });

    const columns = [
        {
            dataIndex: 'siteName',
            className: 'siteName',
            title: 'site.table.name',
            sorter: {
                compare: (a, b) => a.siteName.localeCompare(b.siteName),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'description',
            className: 'description',
            title: 'general.table.description'
        },
        {
            dataIndex: 'latitude',
            className: 'latitude',
            title: 'site.table.lat'
        },
        {
            dataIndex: 'longitude',
            className: 'longitude',
            title: 'site.table.long'
        },
        {
            dataIndex: 'localityNumber',
            className: 'localityNumber',
            title: 'site.table.localityNumber',
            sorter: {
                compare: (a, b) => a.localityNumber - b.localityNumber,
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'capacity',
            className: 'capacity',
            title: 'site.table.capacity'
        },
        {
            dataIndex: 'municipality',
            className: 'municipality',
            title: 'site.table.municipality'
        },
        {
            dataIndex: 'address',
            className: 'address',
            title: 'site.table.address'
        },
        {
            dataIndex: 'action',
            className: 'action',
            title: 'general.table.action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={`restore-site ${BUTTON_GRAY_CLASS}`}
                            onClick={() => setRestoreModalState({ open: true, site: record })}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={`edit-site ${BUTTON_GRAY_CLASS}`}
                                onClick={() => handleShowModal(record)}
                            >
                                <EditOutlined />
                                <span>{t('general.action.edit')}</span>
                            </Button>
                            <Button
                                className={`delete-site ${BUTTON_GREEN_CLASS}`}
                                onClick={() => handleDeleteSite(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('general.action.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getSites({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleTabChange = (isDeleted) => {
        setIsDeleteTab(isDeleted);

        appInsights.trackEvent({ name: 'Site tab change', properties: { isDeleted } });
    };

    const handleReload = (isDeleted) => {
        if (isDeleteTab === isDeleted) dispatch(getSites({ isDeleted: isDeleted }));

        dispatch(getSitesContainingPens());
        setIsDeleteTab(isDeleted);
    };

    const handleShowModal = (site) => setModalState({ open: true, site });

    const handleRestore = () => {
        dispatch(getSites({ isDeleted: isDeleteTab }));
        dispatch(getSitesContainingPens());
    };

    const handleDeleteSite = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteSite(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(SUCCESSFULLY_DELETION_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });

        appInsights.trackEvent({ name: 'Site delete', properties: { siteId: id } });
    };

    return (
        <>
            <h2 className="mb-2">{t('site.title')}</h2>

            <Row justify="center">
                <Col span={24}>
                    <Button
                        className={`new-site ${BUTTON_BLUE_CLASS}`}
                        onClick={() => handleShowModal(null)}
                    >
                        <PlusOutlined />
                        <span>{t('site.newSiteBtn')}</span>
                    </Button>

                    <Spin spinning={loading}>
                        <Tabs
                            className="site-tabs"
                            activeKey={isDeleteTab}
                            animated={true}
                            onChange={handleTabChange}
                            items={[
                                {
                                    label: (
                                        <span className="active-sites">
                                            <GlobalOutlined />
                                            <span>{t('site.activeSites')}</span>
                                        </span>
                                    ),
                                    key: false,
                                    children: (
                                        <FDTable
                                            className="site-table"
                                            headers={columns}
                                            data={sites}
                                            rowKey="id"
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <span className="deleted-sites">
                                            <RestOutlined />
                                            <span>{t('site.deletedSites')}</span>
                                        </span>
                                    ),
                                    key: true,
                                    children: (
                                        <FDTable
                                            className="site-deleted-table"
                                            headers={columns}
                                            data={sites}
                                            rowKey="id"
                                        />
                                    )
                                }
                            ]}
                        />
                    </Spin>
                </Col>
            </Row>

            <SiteForm
                open={modalState.open}
                site={modalState.site}
                onClose={() => setModalState({ open: false, site: null })}
                onReload={handleReload}
            />

            {restoreModalState.open && (
                <RestoreSite
                    open={restoreModalState.open}
                    site={restoreModalState.site}
                    onClose={() => setRestoreModalState({ open: false, site: null })}
                    onReload={handleRestore}
                />
            )}
        </>
    );
};

export default withPageViewTracking(Site, 'Site management');
