import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Dropdown, Menu } from 'antd';
import { FILE_EXPORT_FORMAT_DATE } from 'common/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'services';

const DropdownDownload = ({ options = [], fileName, exportApi }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const downloadOptions = (
        <Menu
            items={options.map((option, index) => {
                return {
                    label: option.label,
                    key: index,
                    icon: <DownloadOutlined />,
                    className: option.className,
                    onClick: () => exportExcelFile(exportApi, option.params, fileName)
                };
            })}
        />
    );

    const exportExcelFile = async (api, params, fileName) => {
        setLoading(true);

        await API.export(api, params)
            .then((res) => {
                const outputFilename = `${fileName}-${moment().format(
                    FILE_EXPORT_FORMAT_DATE
                )}.xlsx`;

                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                throw Error(error);
            });

        setLoading(false);

        appInsights.trackEvent({ name: 'Report download', properties: { fileName } });
    };

    return (
        <Dropdown placement="bottomRight" arrow overlay={downloadOptions}>
            <div className="h-full flex items-end">
                <Button
                    loading={loading}
                    type="default"
                    shape="round"
                    className="btn-download report-download-button"
                >
                    {t('dailyReport.report.download.title')} <DownOutlined />
                </Button>
            </div>
        </Dropdown>
    );
};

DropdownDownload.propTypes = {
    options: PropTypes.array,
    fileName: PropTypes.string,
    exportApi: PropTypes.string
};

export default DropdownDownload;
