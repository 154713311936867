import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CompanyNotification from './CompanyNotification';
import PenNotification from './PenNotification';
import SiteNotification from './SiteNotification';

const NotificationContent = () => {
    const { t } = useTranslation();

    const initialFilters = useSelector((s) => s.filter.data);

    return (
        <div className="mb-5 mt-5">
            <h2 className="mb-2">{t('dailyReport.mortalityStatistics.title')}</h2>
            {initialFilters.daily.penId ? (
                <PenNotification />
            ) : initialFilters.daily.siteId ? (
                <SiteNotification />
            ) : (
                <CompanyNotification />
            )}
        </div>
    );
};

export default NotificationContent;
