import { FDDialogBox } from 'components';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ConfirmDialog = ({ show, message }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    return (
        <FDDialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            message={message}
        />
    );
};

ConfirmDialog.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string
};

export default ConfirmDialog;
