import { createSlice } from '@reduxjs/toolkit';
import { getDailyMortality, updateDailyMortality } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const dailyMortalitySlice = createSlice({
    name: 'dailyMortality',
    initialState,
    reducers: {},
    extraReducers: {
        [getDailyMortality.pending]: (state) => {
            state.loading = true;
        },
        [getDailyMortality.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getDailyMortality.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [updateDailyMortality.pending]: (state) => {
            state.loading = true;
        },
        [updateDailyMortality.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateDailyMortality.rejected]: (state) => {
            state.loading = false;
        }
    }
});
