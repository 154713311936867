import { Button, Result } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const FDGlobalBoundaryError = ({ error, resetErrorBoundary = () => {} }) => {
    const location = useLocation();
    const errorLocation = useRef(location.pathname);

    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            resetErrorBoundary();
        }
    }, [location.pathname, resetErrorBoundary]);

    const reset = () => {
        resetErrorBoundary();
        window.location.href = '/';
    };

    return (
        <Result
            status="warning"
            title="There are some problems with your operation."
            subTitle={error?.message}
            extra={
                <Button type="primary" key="console" onClick={reset}>
                    Go Back
                </Button>
            }
        />
    );
};

FDGlobalBoundaryError.propTypes = {
    error: PropTypes.object,
    resetErrorBoundary: PropTypes.func
};

export default FDGlobalBoundaryError;
