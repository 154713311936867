import { Ability, AbilityBuilder } from '@casl/ability';
import { ADMINISTRATOR, SITE_MANAGER, USER } from 'common/constants';
import isEmpty from 'lodash/isEmpty';

const defineAbilityFor = (roles = []) => {
    const { rules, can } = new AbilityBuilder(Ability);
    if (!isEmpty(roles)) {
        roles.forEach((role, _) => {
            switch (role) {
                case ADMINISTRATOR:
                    can('manage', ADMINISTRATOR, ['all']);
                    can('manage', SITE_MANAGER, ['all']);
                    can('manage', USER, ['all']);
                    break;
                case SITE_MANAGER:
                    can('manage', SITE_MANAGER, ['all']);
                    can('manage', USER, ['all']);
                    break;
                case USER:
                    can('manage', USER, ['all']);
                    break;

                default:
                    break;
            }
        });
    }

    return new Ability(rules);
};

export default defineAbilityFor;
