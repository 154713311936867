import { SyncOutlined } from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Spin } from 'antd';
import { BUTTON_BLUE_CLASS, BUTTON_WHITE_CLASS, PRODUCTION_FISH_NAMES } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, generateRandomFishGroupId } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { addFishGroup } from 'redux/thunks';
import XRegExp from 'xregexp';

const NewGroupForm = ({ open = false, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fishGroup.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);

    const siteId = Form.useWatch('siteId', form);

    const handleCreateFishGroup = async (req) => {
        req.actionDate.startOf('day');

        try {
            const response = await dispatch(addFishGroup(req)).unwrap();

            alertSuccessMessage(response);
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'FFI create',
            properties: { fishGroupId: req.fishGroupId }
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const handleSiteChange = (siteId, site) => {
        if (!site?.pens?.some((pen) => pen.id === form.getFieldsValue().penId)) {
            form.setFieldValue('penId', null);
        }
    };

    return (
        <Modal
            title={t('fishGroup.newGroupForm.title')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS}>
                        {t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Button
                    type="dashed"
                    shape="round"
                    onClick={() =>
                        form.setFields([
                            {
                                name: 'fishGroupId',
                                value: generateRandomFishGroupId(),
                                errors: null
                            }
                        ])
                    }
                    size="small"
                    style={{ left: '50%', transform: 'translate(-50%, -50%)' }}
                    icon={<SyncOutlined />}
                >
                    {t('fishGroup.newGroupForm.randomFishGroupId')}
                </Button>

                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={handleCreateFishGroup}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('fishGroup.newGroupForm.fishGroupId.title')}
                        name="fishGroupId"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.newGroupForm.fishGroupId.required')
                            },
                            {
                                pattern: XRegExp('^\\p{L}{3}\\d{3}$'),
                                message: t('fishGroup.newGroupForm.fishGroupId.invalidFishGroupId')
                            }
                        ]}
                    >
                        <Input placeholder={t('fishGroup.newGroupForm.fishGroupId.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.actionDate.title')}
                        name="actionDate"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.actionDate.required')
                            }
                        ]}
                    >
                        <DatePicker placeholder={t('fishGroup.actionDate.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.newGroupForm.typeOfFish.title')}
                        name="typeOfFish"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.newGroupForm.typeOfFish.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('fishGroup.newGroupForm.typeOfFish.placeholder')}
                            listSelectItem={PRODUCTION_FISH_NAMES.map((type) => ({
                                ...type,
                                text: t(type.text)
                            }))}
                        />
                    </Form.Item>

                    <Form.Item label={t('fishGroup.totalFishCount.title')} name="totalFishCount">
                        <InputNumber
                            className="w-full"
                            placeholder={t('fishGroup.totalFishCount.placeholder')}
                        />
                    </Form.Item>

                    <Form.Item label={t('general.form.site.title')} name="siteId">
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSiteChange}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => !pen.hasFishGroup && (!siteId || pen.siteId === siteId)
                            )}
                            onChange={(penId, pen) => form.setFieldValue('siteId', pen?.siteId)}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

NewGroupForm.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default NewGroupForm;
