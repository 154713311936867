import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'common/constants';
import PropTypes from 'prop-types';
import './FDDate.scss';

const FDDate = ({
    clearIcon = false,
    defaultValue,
    value,
    placeholder = '',
    onChange = () => {}
}) => {
    return (
        <DatePicker
            defaultValue={defaultValue}
            value={value}
            format={DATE_FORMAT}
            onChange={(date) => onChange(date)}
            clearIcon={clearIcon}
            placeholder={placeholder}
        />
    );
};

FDDate.propTypes = {
    clearIcon: PropTypes.bool,
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
};

export default FDDate;
