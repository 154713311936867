import { Dropdown } from 'antd';
import { NOTIFICATION_METHOD, NOTIFICATION_PLACEMENT, NOTIFICATION_TYPE } from 'common/constants';
import { FDNotificationBell } from 'components';
import { maxBy } from 'lodash';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getNotifications, readNotification } from 'redux/thunks';
import { NOTIFICATION_SERVICE, SIGNALR_SERVICE } from 'services';
import NotificationItem from './NotificationItem';
import './NotificationList.scss';

const NotificationList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: notifications } = useSelector((s) => s.notification);

    const notificationRef = useRef(false);

    const unreadNotifications = notifications.filter((notification) => !notification.readTime);

    const items = [
        {
            key: 'title',
            label: (
                <div>
                    <h1 className="text-2xl font-semibold m-0 py-2">
                        {t('notificationHub.notifications')}
                    </h1>
                </div>
            ),
            disabled: true
        },
        ...(notifications?.length
            ? notifications.map((notification) => ({
                  key: notification.id,
                  label: (
                      <NotificationItem
                          onNotificationItemClick={() =>
                              handleNotificationItemClick(
                                  notification.id,
                                  notification.penId,
                                  notification.readTime
                              )
                          }
                          notification={notification}
                      />
                  )
              }))
            : [
                  {
                      key: 'no-notification',
                      label: (
                          <span className="text-gray-500">
                              {t('notificationHub.noNotificationsFound')}
                          </span>
                      ),
                      disabled: true
                  }
              ])
    ];

    useEffect(() => {
        dispatch(getNotifications());

        const connection = SIGNALR_SERVICE.getSignalRConnection('notification-hubs');

        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on(NOTIFICATION_METHOD.hightAverage, () => {
                        notificationRef.current = true;
                        dispatch(getNotifications());
                    });
                })
                .catch((err) => {
                    console.log('Connect to notification hub failed: ', err);
                });
        }

        return () => {
            connection?.stop();
        };
    }, []);

    useEffect(() => {
        if (notificationRef.current && unreadNotifications.length > 0) {
            const nearestNotification = maxBy(unreadNotifications, (notification) =>
                moment(notification.sendTime)
            );

            const notificationTitle = `${t(nearestNotification.title)}`;
            const notificationDescription = `${t('notificationHub.locations')}: ${t(
                'notificationHub.site'
            )}
                ${nearestNotification.siteName}, ${t('notificationHub.pen')} ${
                nearestNotification.penNumber
            }`;
            NOTIFICATION_SERVICE.pushNotification({
                type: NOTIFICATION_TYPE.warning,
                title: notificationTitle,
                description: notificationDescription,
                placement: NOTIFICATION_PLACEMENT.bottomRight
            });

            notificationRef.current = false;
        }
    }, [unreadNotifications]);

    const handleNotificationItemClick = async (notificationId, penId, readTime) => {
        navigate(`pen-detail/${penId}`);

        if (!readTime) {
            await dispatch(readNotification(notificationId)).unwrap();
            dispatch(getNotifications());
        }
    };

    return (
        <div className="notification-list">
            <Dropdown
                menu={{ items }}
                placement="bottomRight"
                trigger={['click']}
                getPopupContainer={(trigger) => trigger.parentNode}
                className="cursor-pointer z-10"
            >
                <FDNotificationBell count={unreadNotifications.length} />
            </Dropdown>
        </div>
    );
};

export default NotificationList;
