import { Col, Collapse, Row, Spin } from 'antd';
import { LIVE_DEAD_FISH_COUNTING_PAGE } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import propTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deadFishCountingSitesSelector } from 'redux/selector';
import { resetDeadFishCountingSites, setDeadFishCountingStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getDeadFishCountingSites, startDeadFishCountingEngine } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import PenList from '../PenList';
import SiteStatus from './SiteStatus';
import { CalculatorOutlined } from '@ant-design/icons';

const DeadFishCounting = () => {
    const { Panel } = Collapse;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [penListState, setPenListState] = useState({ isOpen: false, site: null });
    const { data: sites, loading } = useSelector(deadFishCountingSitesSelector);

    useEffect(() => {
        dispatch(getDeadFishCountingSites());

        return () => {
            dispatch(resetDeadFishCountingSites());
        };
    }, []);

    const [sitesWithEngineConfigAndBaseUrl, sitesWithEngineConfig, sitesWithoutEngineConfig] =
        useMemo(() => {
            const sitesWithEngineConfigAndBaseUrl = [];
            const sitesWithEngineConfig = [];
            const sitesWithoutEngineConfig = [];

            sites.forEach((site) => {
                if (site.hasEngineConfig && site.hasEngineBaseUrl) {
                    sitesWithEngineConfigAndBaseUrl.push(site);
                } else if (site.hasEngineConfig) {
                    sitesWithEngineConfig.push(site);
                } else {
                    sitesWithoutEngineConfig.push(site);
                }
            });

            return [
                sitesWithEngineConfigAndBaseUrl,
                sitesWithEngineConfig,
                sitesWithoutEngineConfig
            ];
        }, [sites]);

    const handlePenListClose = useCallback(() => {
        setPenListState({ isOpen: false, site: null });
    }, []);

    const handlePenListOpen = useCallback((site) => {
        setPenListState({ isOpen: true, site });
    }, []);

    const handleDeadFishCountingEngineStart = useCallback(
        async (penNumber) => {
            try {
                const response = await dispatch(
                    startDeadFishCountingEngine({
                        baseUrl: penListState.site?.engineBaseUrl,
                        penNumber,
                        siteName: penListState.site?.siteName,
                        localityNumber: penListState.site?.localityNumber
                    })
                ).unwrap();

                if (response.hasBeenStarting) {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveDeadFishCounting.notification.hasStartedCounting'),
                        duration: 15
                    });
                } else {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveDeadFishCounting.notification.startCounting'),
                        duration: 10
                    });
                }

                dispatch(
                    setDeadFishCountingStreaming({
                        isCounting: true,
                        penNumber,
                        engineBaseUrl: penListState.site?.engineBaseUrl,
                        siteId: penListState.site?.id,
                        siteName: penListState.site?.siteName,
                        localityNumber: penListState.site?.localityNumber
                    })
                );

                handlePenListClose();
                navigate(LIVE_DEAD_FISH_COUNTING_PAGE);
            } catch (err) {
                alertErrorMessage(err);
            }
        },
        [penListState.site, handlePenListClose]
    );

    return (
        <div id="dead-fish-counting">
            <div className="mt-10">
                <h2>
                    <CalculatorOutlined className="mr-2" />
                    {t('liveDeadFishCounting.siteStatus')}
                </h2>

                {loading ? (
                    <Spin />
                ) : sites.length ? (
                    <>
                        <SiteList
                            sites={sitesWithEngineConfigAndBaseUrl}
                            onPenListOpen={handlePenListOpen}
                        />
                        <SiteList sites={sitesWithEngineConfig} onPenListOpen={handlePenListOpen} />
                        <Collapse ghost>
                            <Panel header={t('liveDeadFishCounting.sitesWithoutEngineConfig')}>
                                <SiteList
                                    sites={sitesWithoutEngineConfig}
                                    onPenListOpen={handlePenListOpen}
                                />
                            </Panel>
                        </Collapse>
                    </>
                ) : (
                    <p className="m-0 text-base text-red-600">
                        {t('liveDeadFishCounting.noSiteAvailable')}
                    </p>
                )}
            </div>

            <PenList
                isOpen={penListState.isOpen}
                onClose={handlePenListClose}
                onEngineStart={(penNumber) => handleDeadFishCountingEngineStart(penNumber)}
            />
        </div>
    );
};

const SiteList = ({ sites = [], onPenListOpen = () => {} }) => {
    if (!sites.length) return;

    return (
        <Row gutter={[10, 10]} className="mb-3">
            {sites.map((site) => (
                <Col xs={12} sm={8} md={6} lg={4} xxl={3} key={site.id}>
                    <SiteStatus site={site} key={site.id} onPenListOpen={onPenListOpen} />
                </Col>
            ))}
        </Row>
    );
};

SiteList.propTypes = {
    sites: propTypes.array,
    onPenListOpen: propTypes.func
};

export default DeadFishCounting;
