import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const selfSelector = (state) => state.eventDetail;

export const eventDetailSelector = createSelector(selfSelector, (event) => get(event, 'data', []));

export const eventDetailStartTimeSelector = createSelector(selfSelector, (event) =>
    get(event, 'data.startTime', [])
);

export const stressLevelSelector = createSelector(selfSelector, (event) =>
    get(event, 'data.stressLevels', [])
);

export const oxygensSelector = createSelector(selfSelector, (event) =>
    get(event, 'data.oxygens', [])
);

export const temperaturesSelector = createSelector(selfSelector, (event) =>
    get(event, 'data.temperatures', [])
);

export const channelsSelector = createSelector(selfSelector, (event) =>
    get(event, 'data.channels', [])
);

export const crowdingAreasSelector = createSelector(selfSelector, (event) =>
    get(event, 'data.crowdingAreas', [])
);
