import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getProcessWelfareScore, getScoringEventDetail } from 'redux/thunks';
import ScoringInfo from './ScoringInfo';
import ScoringPerFish from './ScoringPerFish';
import { Spin } from 'antd';
import WelfareScore from 'pages/process/WelfareScore';
import { useTranslation } from 'react-i18next';
import { resetWelfareScore } from 'redux/slices';

const ScoringDetail = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const selectedScoringEventId = useSelector((s) => s.filter.data.selectedScoringEventId);
    const scoringEvents = useSelector((s) => s.scoringEvent.list.data);
    const { data: scoringDetails, loading } = useSelector((s) => s.scoringEvent.detail);

    useEffect(() => {
        if (selectedScoringEventId) {
            const scoringEvent = scoringEvents.find(
                (scoringEvent) => scoringEvent.id === selectedScoringEventId
            );

            if (!scoringEvent) return;

            dispatch(getScoringEventDetail({ ids: scoringEvent.ids || [] }));

            if (scoringEvent.processId) {
                dispatch(getProcessWelfareScore(scoringEvent.processId));
            } else {
                dispatch(resetWelfareScore());
            }
        }
    }, [selectedScoringEventId]);

    return (
        <Spin spinning={loading}>
            <div className="mt-5">
                {scoringDetails.map((scoring) => (
                    <div key={scoring.id}>
                        {scoring.isBeforeDelicingEvent ? (
                            <h2>{t('scoringEvent.preDelicing')}</h2>
                        ) : (
                            <h2>{t('scoringEvent.postDelicing')}</h2>
                        )}
                        <ScoringInfo scoringEvent={scoring} />
                        <ScoringPerFish scoringEvent={scoring} />
                    </div>
                ))}

                <h2>{t('scoringEvent.welfareScore')}</h2>
                <WelfareScore hasTitle={false} />
            </div>
        </Spin>
    );
};

export default ScoringDetail;
