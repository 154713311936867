import { withPageViewTracking } from 'hoc';
import FilterBar from './FilterBar';
import './FishGroupDetail.scss';
import HierarchyChart from './HierarchyChart';

const FishGroupDetail = () => {
    return (
        <div className="fish-group-detail">
            <FilterBar />

            <HierarchyChart />
        </div>
    );
};

export default withPageViewTracking(FishGroupDetail, 'FFI detail');
