import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EventTable from './EventTable';
import FilterBar from './FilterBar';
import NotificationContent from './Notification';
import ReportTable from './ReportTable';

const DailyReport = () => {
    const { mortalityStatisticsSwitch, stressEventSwitch } = useFlags();

    return (
        <div className="daily-report">
            <FilterBar />

            {mortalityStatisticsSwitch && <NotificationContent />}

            <ReportTable />

            {stressEventSwitch && <EventTable />}
        </div>
    );
};

export default withPageViewTracking(DailyReport, 'Daily report');
