import { Divider } from 'antd';
import { alertErrorMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { triggerEngineStart } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import CountingResult from '../CountingResult';
import CountingForm from './CountingForm';
import { NOTIFICATION_TYPE } from 'common/constants';

const FormAndResult = ({
    selectedSite,
    preSelectedPen,
    setSelectedSite = () => {},
    setSelectedPen = () => {},
    setPreSelectedPen = () => {},
    onStartTimer = () => {},
    onUpdateTimer = () => {}
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: engineData, loading } = useSelector((s) => s.engine);

    const handleCountingStart = async (pen = {}, onPenListClose = () => {}) => {
        try {
            var response = await dispatch(
                triggerEngineStart({
                    penNumber: pen.penNumber,
                    siteName: selectedSite?.text,
                    localityNumber: selectedSite?.localityNumber
                })
            ).unwrap();

            if (response.hasBeenStarting) {
                if (
                    response.localityNumber !== selectedSite?.localityNumber ||
                    response.penNumber !== pen.penNumber
                ) {
                    NOTIFICATION_SERVICE.pushNotification({
                        type: NOTIFICATION_TYPE.error,
                        title: `${t('countingPage.notification.countingOnAnotherLocation')} (${
                            response.siteName
                        } - ${response.penNumber})`,
                        duration: 15
                    });

                    return;
                }

                // the engine has started counting before
                NOTIFICATION_SERVICE.pushNotification({
                    title: t('countingPage.notification.hasStartedCounting'),
                    duration: 15
                });

                pen = { siteName: response.siteName, penNumber: response.penNumber };
                onUpdateTimer(response.countingTime || 0);
            } else {
                NOTIFICATION_SERVICE.pushNotification({
                    title: t('countingPage.notification.startedCounting'),
                    duration: 10
                });
                onStartTimer();
            }

            setSelectedPen(pen);
            setPreSelectedPen(pen);
            onPenListClose();
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    return (
        <div data-testid="counting-form-and-result">
            <CountingForm
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                onStartCounting={handleCountingStart}
            />

            {/* only show the result after counting is done*/}
            {preSelectedPen && (
                <>
                    <Divider />
                    <CountingResult
                        siteName={preSelectedPen.siteName}
                        penNumber={preSelectedPen.penNumber}
                        result={engineData}
                        loading={loading}
                    />
                </>
            )}
        </div>
    );
};

FormAndResult.propTypes = {
    selectedSite: PropTypes.object,
    preSelectedPen: PropTypes.object,
    setSelectedSite: PropTypes.func,
    setSelectedPen: PropTypes.func,
    setPreSelectedPen: PropTypes.func,
    onStartTimer: PropTypes.func,
    onUpdateTimer: PropTypes.func
};

export default FormAndResult;
