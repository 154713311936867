import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    DEAD_FISH_COUNTING_ENGINE_START_API,
    DEAD_FISH_COUNTING_ENGINE_STATUS_API,
    DEAD_FISH_COUNTING_ENGINE_STOP_API,
    ENGINE_START_STOP_SITE_URL
} from 'common/constants';
import { API, DEAD_FISH_COUNTING_ENGINE_API } from 'services';

export const triggerEngineStart = createAsyncThunk(
    'engines/triggerEngineStart',
    async ({ penNumber, siteName, localityNumber }, { rejectWithValue }) => {
        try {
            const { data } = await DEAD_FISH_COUNTING_ENGINE_API.get(
                `${DEAD_FISH_COUNTING_ENGINE_START_API}/${penNumber}/${siteName}/${localityNumber}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const triggerEngineStop = createAsyncThunk(
    'engines/triggerEngineStop',
    async ({ penNumber, siteName, localityNumber }, { rejectWithValue }) => {
        try {
            const { data } = await DEAD_FISH_COUNTING_ENGINE_API.get(
                `${DEAD_FISH_COUNTING_ENGINE_STOP_API}/${penNumber}/${siteName}/${localityNumber}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEngineStatus = createAsyncThunk(
    'engines/getEngineStatus',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await DEAD_FISH_COUNTING_ENGINE_API.get(
                DEAD_FISH_COUNTING_ENGINE_STATUS_API,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEngineStartStopSiteList = createAsyncThunk(
    'engines/getEngineStartStopSiteList',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_START_STOP_SITE_URL, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
