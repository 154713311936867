import { DribbbleOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import 'antd/dist/antd.min.css';
import PropTypes from 'prop-types';
import FDTree from '../Tree';
import './FDTreeTransfer.scss';

const FDTreeTransfer = ({
    treeData = [],
    selectedKeys = [],
    messageForEmptyTreeData = '',
    onSelectedKeysChange = () => {}
}) => {
    const onCheck = (selectedKeys) => {
        onSelectedKeysChange(
            selectedKeys.filter((key) => !treeData.find((node) => node.key === key))
        );
    };

    const selectedTreeData = treeData
        .map((treeItem) => ({
            ...treeItem,
            children: treeItem.children
                ?.filter((child) => selectedKeys.includes(child.key))
                .map((child) => ({
                    ...child,
                    switcherIcon: <DribbbleOutlined />
                }))
        }))
        .filter((treeItem) => treeItem.children?.length);

    return (
        <div className="tree-transfer">
            <Row type="flex" gutter={20}>
                <Col span={12}>
                    <Card className="h-full">
                        {treeData.length > 0 ? (
                            <FDTree
                                treeData={treeData}
                                checkedKeys={selectedKeys}
                                onCheck={onCheck}
                            />
                        ) : (
                            <p className="text-center text-red-600">{messageForEmptyTreeData}</p>
                        )}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <FDTree checkable={false} treeData={selectedTreeData} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

FDTreeTransfer.propTypes = {
    treeData: PropTypes.array,
    selectedKeys: PropTypes.array,
    messageForEmptyTreeData: PropTypes.string,
    onSelectedKeysChange: PropTypes.func
};

export default FDTreeTransfer;
