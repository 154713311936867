import { Bullet } from '@ant-design/plots';
import { BLACK, BLUE, LIGHT_GRAY, RANGE_AREA_GREEN, SILVER } from 'common/constants';
import PropTypes from 'prop-types';

const FDBullet = ({ data = [], height, ...chartConfig }) => {
    const config = {
        data,
        height,
        measureField: 'measures',
        rangeField: 'ranges',
        targetField: 'target',
        tooltip: false,
        ...chartConfig,
        size: {
            target: 40
        },
        color: {
            range: RANGE_AREA_GREEN
        },
        bulletStyle: {
            target: {
                lineWidth: 18,
                stroke: BLUE
            }
        },
        label: {
            measure: false,
            target: {
                position: 'middle',
                style: {
                    fontSize: 16,
                    fontWeight: 600,
                    fill: BLUE
                }
            }
        },
        xAxis: false,
        yAxis: {
            type: 'linear',
            grid: {
                line: {
                    style: {
                        stroke: SILVER
                    }
                }
            },
            line: {
                style: {
                    stroke: LIGHT_GRAY
                }
            },
            label: {
                style: {
                    fontSize: 12,
                    fill: BLACK
                }
            },
            ...chartConfig.yAxis
        }
    };

    return <Bullet {...config} />;
};

FDBullet.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    chartConfig: PropTypes.object
};

export default FDBullet;
