import { Card, Spin } from 'antd';
import { formatSeconds } from 'common/utils';
import { FDRangeArea } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CrowdingTimePerThrow = () => {
    const { t } = useTranslation();

    const { data: crowdingTimesPerThrow, loading: crowdingTimesPerThrowLoading } = useSelector(
        (s) => s.stressAnalysis.crowdingTimePerThrow
    );

    const formattedData = {
        areas: crowdingTimesPerThrow.map((item, index) => ({
            name: String(index + 1),
            value: [Math.round(item.lowest), Math.round(item.highest)]
        })),
        lines: crowdingTimesPerThrow.map((item, index) => ({
            name: String(index + 1),
            value: Math.round(item.average)
        }))
    };

    return (
        <Spin spinning={crowdingTimesPerThrowLoading}>
            <Card>
                <p className="font-medium text-base">
                    {t('stressAnalysis.averageCrowdingTimePerThrow')}
                </p>
                <FDRangeArea
                    data={formattedData}
                    tooltipLabel={{
                        area: `${t('stressAnalysis.lowest')} - ${t('stressAnalysis.highest')}`,
                        line: t('stressAnalysis.average')
                    }}
                    yAxisFormatter={formatSeconds}
                />
            </Card>
        </Spin>
    );
};

export default CrowdingTimePerThrow;
