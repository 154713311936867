import { createAsyncThunk } from '@reduxjs/toolkit';
import { EVENT_CHART_API, EVENT_DETAIL_API, EVENT_DETAIL_LIST_API, EVENT_LIST_API } from 'common/constants';
import moment from 'moment';
import { API } from 'services';

export const getEventChart = createAsyncThunk(
    'reports/getEventChart',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(EVENT_CHART_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventDetail = createAsyncThunk(
    'events/getEventDetail',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${EVENT_DETAIL_API}/${id}/detail`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventList = createAsyncThunk(
    'events/getEventList',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(EVENT_LIST_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventDetailList = createAsyncThunk(
    'events/getEventDetailList',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(EVENT_DETAIL_LIST_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventNotesByEventId = createAsyncThunk(
    'events/getEventNotesByEventId',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${EVENT_DETAIL_API}/${id}/notes`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addEventNote = createAsyncThunk(
    'events/addEventNote',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.post(`${EVENT_DETAIL_API}/${id}/notes`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const pushEventNote = createAsyncThunk(
    'events/pushEventNote',
    async (data, { rejectWithValue }) => {
        try {
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDeadFishCountingMortalityByEventId = createAsyncThunk(
    'events/getDeadFishCountingMortalityByEventId',
    async ({ id, filters }, { rejectWithValue }) => {
        try {
            const { data } = await API.get(
                `${EVENT_DETAIL_API}/${id}/dead-fish-counting-mortality`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventFishHealth = createAsyncThunk(
    'events/getEventFishHealth',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${EVENT_DETAIL_API}/${id}/fish-health`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
