import { FILE_EXPORT_FORMAT_DATE, MEDIA_MANAGEMENT_API } from 'common/constants';
import JSZip from 'jszip';
import moment from 'moment';
import { v4 } from 'uuid';
import { saveAs } from 'file-saver';
import { MEDIA_API } from 'services';

const getQueryParamsFromUrl = (url) => {
    const params = new URLSearchParams(new URL(url).search);
    const paramObject = {};
    for (const [key, value] of params.entries()) {
        paramObject[key] = value;
    }
    return paramObject;
};

export const exportZipOfImages = async (
    folders = [],
    fileName = moment().format(FILE_EXPORT_FORMAT_DATE)
) => {
    const zip = new JSZip();
    const imageFetchPromises = [];

    folders.forEach((folderItem) => {
        const folder = zip.folder(folderItem.name);

        folderItem.imageUrls?.forEach((imageUrl) => {
            const fetchPromise = (async () => {
                try {
                    const queryParams = getQueryParamsFromUrl(imageUrl);
                    const response = await MEDIA_API.get(MEDIA_MANAGEMENT_API, queryParams, {
                        responseType: 'blob'
                    });

                    const blob = response.data;
                    folder.file(`${queryParams.fileName || v4()}.png`, blob);
                } catch (error) {
                    console.error(`Failed to download image ${imageUrl}`, error);
                }
            })();
            imageFetchPromises.push(fetchPromise);
        });
    });

    // Wait for all images to be fetched and added to the zip
    await Promise.all(imageFetchPromises);

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${fileName}.zip`);
};
