import { Button, Col, Row } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS, FISH_NAMES } from 'common/constants';
import { FDSelection } from 'components';
import FDDate from 'components/common/Date/FDDate';
import { FDMultipleSelection } from 'components/common/MultipleSelection';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getPensSelection,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const allFishwellFishIds = useSelector((s) => s.fishwellFishIdSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [fishGroupsSelection, setFishGroupsSelection] = useState([]);
    const [filters, setFilters] = useState({
        fromDate: initialFilters.daily.fromDate,
        toDate: initialFilters.daily.toDate,
        siteId: initialFilters.daily.siteId,
        penId: initialFilters.daily.penId,
        fishNames: initialFilters.fishNames,
        fishGroupIds: initialFilters.fishGroupIds
    });

    useEffect(() => {
        dispatch(getPensSelection());
        dispatch(getSitesSelection());
        dispatch(getFishwellFishIdsSelection());
    }, []);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useEffect(() => {
        setFishGroupsSelection(allFishwellFishIds);
    }, [allFishwellFishIds]);

    const handleSitesChange = (siteId) => {
        const newPensSelections = !siteId
            ? allPens
            : allPens.filter((pen) => pen.siteId === siteId);

        const newFishGroupsSelections = !siteId
            ? allFishwellFishIds
            : allFishwellFishIds.filter((fishGroup) => fishGroup.siteId === siteId);

        setPensSelection(newPensSelections);
        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            siteId: siteId,
            penId: newPensSelections.some((pen) => pen.id === filters.penId) ? filters.penId : null
        });
    };

    const handlePensChange = (penId) => {
        const newFishGroupsSelections = !penId
            ? allFishwellFishIds
            : allFishwellFishIds.filter((fishGroup) => fishGroup.penId === penId);

        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            penId: penId,
            fishGroupIds: newFishGroupsSelections.some(
                (fishGroup) => fishGroup.id === filters.fishGroupIds
            )
                ? filters.fishGroupIds
                : []
        });
    };

    const handleFishesChange = (fishes) => {
        setFilters({
            ...filters,
            fishNames: fishes
        });
    };

    const handleFishGroupsChange = (fishGroupIds) => {
        setFilters({
            ...filters,
            fishGroupIds: fishGroupIds
        });
    };

    const handleDateChange = (date) => {
        setFilters({
            ...filters,
            fromDate: date.clone().startOf('day'),
            toDate: date.clone().endOf('day')
        });
    };

    const handleFilterApply = () => {
        dispatch(
            updateFilters({
                daily: {
                    fromDate: filters.fromDate,
                    toDate: filters.toDate,
                    siteId: filters.siteId,
                    penId: filters.penId
                },
                fishNames: filters.fishNames,
                fishGroupIds: filters.fishGroupIds
            })
        );

        appInsights.trackEvent({ name: 'Daily filter apply' });
    };

    return (
        <div className="filter-bar">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.site')}</div>
                    <FDSelection
                        placeholder={t('general.filterBar.sitePlaceholder')}
                        listSelectItem={sitesSelection}
                        onChange={handleSitesChange}
                        value={filters.siteId}
                        className="w-full"
                        allowClear={true}
                        showSearch={true}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.pen')}</div>
                    <FDSelection
                        placeholder={t('general.filterBar.penPlaceholder')}
                        listSelectItem={pensSelection}
                        onChange={handlePensChange}
                        value={filters.penId}
                        className="w-full"
                        allowClear={true}
                        showSearch={true}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.fish')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.fishesPlaceholder')}
                        listSelectItem={FISH_NAMES.map((fish) => ({
                            ...fish,
                            text: t(fish.text)
                        }))}
                        onChange={handleFishesChange}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.fishGroups')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.fishGroupsPlaceholder')}
                        listSelectItem={fishGroupsSelection.map((fishGroup) => ({
                            ...fishGroup,
                            text: `${fishGroup.text} - ${fishGroup.penNumber} - ${fishGroup.siteName}`
                        }))}
                        onChange={handleFishGroupsChange}
                        value={filters.fishGroupIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.date')}</div>
                    <FDDate
                        placeholder={t('general.filterBar.datePlaceholder')}
                        onChange={handleDateChange}
                        value={filters.fromDate}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3} className="flex items-end">
                    <Button className={`${BUTTON_BLUE_CLASS} mt-6`} onClick={handleFilterApply}>
                        {t('general.filterBar.apply')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
