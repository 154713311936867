import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PenList = ({ visible, pens = [], onClose = () => {}, onStartCounting = () => {} }) => {
    const { t } = useTranslation();

    const { loading } = useSelector((s) => s.engine);

    const [selectedPen, setSelectedPen] = useState();

    const handlePenChange = (pen) => {
        setSelectedPen(pen);
    };

    return (
        <Modal
            title={t('countingPage.countingForm.penList.title')}
            open={visible}
            onCancel={onClose}
            onOk={onStartCounting}
            className="pen-list-modal"
            footer={
                <Button
                    onClick={() => onStartCounting(selectedPen)}
                    disabled={!selectedPen}
                    icon={<CaretRightOutlined />}
                    loading={loading}
                    className="bg-sky-700 hover:bg-sky-800 focus-within:bg-sky-700 hover:text-white focus-within:text-white text-white text-lg 
                            font-semibold h-10 px-2 rounded border-none mb-2 w-full flex justify-center items-center start-counting-btn"
                >
                    {t('countingPage.countingForm.penList.startCounting')}
                </Button>
            }
        >
            <Row gutter={[8, 8]}>
                {pens.map((pen) => {
                    return (
                        <Col xs={12} sm={8} md={6} lg={6} xl={6} xxl={6} key={pen.id}>
                            <Button
                                onClick={() => handlePenChange(pen)}
                                className={`h-20 w-full sm:text-xl text-black bg-transparent rounded font-semibold border-solid border-gray-300 hover:bg-slate-600 hover:text-white
                                    cursor-pointer flex justify-center items-center align-middle transition ${
                                        pen.id === selectedPen?.id && '!bg-slate-600 !text-white'
                                    }`}
                                data-testid="pen-option"
                            >
                                {pen.penNumber}
                            </Button>
                        </Col>
                    );
                })}
            </Row>
        </Modal>
    );
};

PenList.propTypes = {
    visible: PropTypes.bool,
    pens: PropTypes.array,
    onClose: PropTypes.func,
    onStartCounting: PropTypes.func
};

export default PenList;
