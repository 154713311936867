import { Avatar, Button, Drawer, Spin } from 'antd';
import defaultLogo from 'assets/Empty_Box.png';
import { default as Goodeye_Logo, default as Goodyeye_Logo } from 'assets/Good_Eye_Black.png';
import { Title } from 'common/constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getServices } from 'redux/thunks';
import './ServicesDrawer.scss';

const ServicesDrawer = () => {
    const goodeyeMenuUrl = process.env.REACT_APP_GOODEYE_MENU_URL;

    const dispatch = useAppDispatch();

    const { data: services, loading } = useSelector((s) => s.service);

    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        dispatch(getServices());
    }, []);

    return (
        <>
            <Button
                className="googeye-button p-2 my-2 bg-white hover:bg-slate-50 transition-all rounded flex border-2 border-gray-400 focus-within:border-gray-400"
                onClick={() => setDrawerOpen(true)}
            >
                <img src={Goodyeye_Logo} alt="Googeye logo" className="w-auto h-4" />
            </Button>
            <Drawer
                title={
                    <a href={goodeyeMenuUrl}>
                        <img
                            src={Goodeye_Logo}
                            alt="Goodeye logo"
                            className="h-8 hover:pl-2 transition-all"
                        />
                    </a>
                }
                placement="right"
                onClose={() => setDrawerOpen(false)}
                open={drawerOpen}
                className="service-drawer"
            >
                <Spin spinning={loading}>
                    {!services.length && (
                        <p className="text-center text-base">No services available</p>
                    )}

                    {services.map((service) => (
                        <a
                            href={service.url}
                            className="service-item h-20 flex items-center cursor-pointer"
                            key={service.id}
                            data-testid="service-url"
                        >
                            <Avatar.Group>
                                <img
                                    className="object-contain rounded h-14 w-20"
                                    src={service.logo || defaultLogo}
                                    data-testid="service-logo"
                                    alt="Service logo"
                                />
                                <div className="avatar-info">
                                    <div className="avatar-info ml-2">
                                        <Title level={5}>{service.name}</Title>
                                        <p className="m-0">
                                            {service.companyOwner.name || 'Service owner'}
                                        </p>
                                    </div>
                                </div>
                            </Avatar.Group>
                        </a>
                    ))}
                </Spin>
            </Drawer>
        </>
    );
};

export default ServicesDrawer;
