import { createSlice } from '@reduxjs/toolkit';
import {
    getMortalityAnalysisWoundTypeDeadFishCountReport,
    getMortalityAnalysisWoundTypeStockCountReport
} from 'redux/thunks';

const initialState = {
    woundTypeWithDeadFishCount: [],
    woundTypeWithStockCount: [],
    loading: false
};

export const mortalityAnalysisWoundTypeReportSlice = createSlice({
    name: 'mortalityAnalysisWoundTypeReport',
    initialState,
    reducers: {},
    extraReducers: {
        [getMortalityAnalysisWoundTypeDeadFishCountReport.pending]: (state) => {
            state.loading = true;
        },
        [getMortalityAnalysisWoundTypeDeadFishCountReport.fulfilled]: (state, action) => {
            state.loading = false;
            state.woundTypeWithDeadFishCount = action.payload || initialState.data;
        },
        [getMortalityAnalysisWoundTypeDeadFishCountReport.rejected]: (state) => {
            state.loading = false;
            state.woundTypeWithDeadFishCount = initialState.data;
        },

        [getMortalityAnalysisWoundTypeStockCountReport.pending]: (state) => {
            state.loading = true;
        },
        [getMortalityAnalysisWoundTypeStockCountReport.fulfilled]: (state, action) => {
            state.loading = false;
            state.woundTypeWithStockCount = action.payload || initialState.data;
        },
        [getMortalityAnalysisWoundTypeStockCountReport.rejected]: (state) => {
            state.loading = false;
            state.woundTypeWithStockCount = initialState.data;
        }
    }
});
