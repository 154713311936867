import { useCallback, useEffect, useState } from 'react';
import { ENGINE_SERVICE } from 'services';
import CountingResult from '../../CountingResult';
import PropTypes from 'prop-types';

const StreamingResult = ({
    siteName = '',
    penNumber = '',
    eventSourceRef,
    onCountingStop = () => {}
}) => {
    const [streamingData, setStreamingData] = useState();

    const handleStreamMessage = useCallback((e) => {
        const streamData = JSON.parse(e.data);

        if (!streamData.counting) {
            // force stop counting when the engine stops counting by someone else
            onCountingStop();
            eventSourceRef.current.close();
            return;
        }

        setStreamingData((prevState) => ({
            ...prevState,
            laks: streamData?.laks,
            berggylt: streamData?.berggylt,
            rognkjeks: streamData?.rognkjeks
        }));
    }, []);

    useEffect(() => {
        const startStreaming = async () => {
            if (eventSourceRef) {
                eventSourceRef.current = await ENGINE_SERVICE.getStreamingResult();
                eventSourceRef.current.onmessage = handleStreamMessage;
            }
        };
        startStreaming();

        return () => {
            if (eventSourceRef?.current) {
                // stop getting streaming data from engine when the component is unmounted
                eventSourceRef.current.close();
            }
        };
    }, [handleStreamMessage]);

    return <CountingResult siteName={siteName} penNumber={penNumber} result={streamingData} />;
};

StreamingResult.propTypes = {
    siteName: PropTypes.string,
    penNumber: PropTypes.string,
    eventSourceRef: PropTypes.object,
    onCountingStop: PropTypes.func
};

export default StreamingResult;
