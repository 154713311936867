export const PRODUCTION_FISH_NAMES = [
    {
        id: 'laks',
        text: 'general.fishMortality.laks'
    },
    {
        id: 'ørret',
        text: 'general.fishMortality.ørret'
    }
];

export const FISH_NAMES = [
    ...PRODUCTION_FISH_NAMES,
    {
        id: 'berggylt',
        text: 'general.fishMortality.berggylt'
    },
    {
        id: 'rognkjeks',
        text: 'general.fishMortality.rognkjeks'
    }
];

// The type of fish is shown the mortality rate on the dashboard page
export const FISH_ATTRIBUTES_LAKS = 'fishAttributes_laks';
export const FISH_DIAGNOSTIC_ATTRIBUTES_LAKS = 'fishDiagnosticAttributes_laks';
