import {
    AreaChartOutlined,
    CalculatorOutlined,
    HomeOutlined,
    LogoutOutlined,
    RadarChartOutlined,
    UserOutlined
} from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Avatar, Button, Layout as LibLayout, Menu, Popover } from 'antd';
import defaultAvatar from 'assets/Default_Avatar.png';
import useAppAbility from 'casl/can';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_UN_SELECTED,
    LIVE_FISH_EVENT_PAGE,
    SITE_MANAGER
} from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CountryPicker from 'layouts/Header/CountryPicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const { t } = useTranslation();
    const { Header } = LibLayout;
    const { instance } = useMsal();

    const { showLiveDeadFishCounting, showLiveStressDetection } = useFlags();
    const { can } = useAppAbility();

    const location = useLocation();
    const isLiveFishEventPage = location.pathname === LIVE_FISH_EVENT_PAGE;

    const { data: profile } = useSelector((s) => s.profile);

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Header theme="dark" className="header flex items-center justify-between z-10 px-1 md:px-6">
            <div className="flex items-center gap-x-2">
                <Link to="/">
                    <Button
                        type="button"
                        icon={<HomeOutlined className="text-xl" />}
                        className={`${BUTTON_BLUE_CLASS} flex items-center`}
                    >
                        <p className="hidden sm:block ml-2 mb-0">{t('liveFishEvent.dashboard')}</p>
                    </Button>
                </Link>

                {isLiveFishEventPage && (
                    <>
                        {showLiveDeadFishCounting && (
                            <Button
                                type="button"
                                icon={<CalculatorOutlined className="text-xl" />}
                                className={`${BUTTON_GRAY_UN_SELECTED} flex items-center`}
                                onClick={() => scrollToSection('dead-fish-counting')}
                            >
                                <p className="hidden sm:block ml-2 mb-0">
                                    {t('liveFishEvent.deadFishCounting')}
                                </p>
                            </Button>
                        )}

                        {can('manage', SITE_MANAGER, ['all']) && showLiveStressDetection && (
                            <Button
                                type="button"
                                icon={<RadarChartOutlined className="text-xl" />}
                                className={`${BUTTON_GRAY_UN_SELECTED} flex items-center`}
                                onClick={() => scrollToSection('stress-detection')}
                            >
                                <p className="hidden sm:block ml-2 mb-0">
                                    {t('liveFishEvent.stressDetection')}
                                </p>
                            </Button>
                        )}
                    </>
                )}
            </div>

            <div className="flex gap-x-5">
                <CountryPicker />

                <Popover
                    className="profile-dropdown"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    content={
                        <Menu mode="inline" className="auth-menu" selectable={false}>
                            <Menu.Item key="1">
                                <NavLink to="/profile">
                                    <UserOutlined /> {t('layout.header.viewProfile')}
                                </NavLink>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="2">
                                <NavLink to="/">
                                    <AreaChartOutlined /> {t('layout.header.analysis')}
                                </NavLink>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="3" onClick={handleLogout}>
                                <LogoutOutlined /> {t('layout.header.logout')}
                            </Menu.Item>
                        </Menu>
                    }
                    placement="bottomRight"
                >
                    <span className="user-name cursor-pointer hidden xl:!inline">
                        {t('layout.header.greeting')} {profile.name || '...'}
                    </span>
                    <Avatar className="cursor-pointer w-8" src={profile.avatar || defaultAvatar} />
                </Popover>
            </div>
        </Header>
    );
};

export default Header;
