import { createAsyncThunk } from '@reduxjs/toolkit';
import { FLOW_REPORT_API } from 'common/constants';
import { API } from 'services';

export const getFlowReports = createAsyncThunk(
    'flows/getFlowReports',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(FLOW_REPORT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
