import { appInsights } from 'AppInsights';
import { Card, Col, Row, Spin } from 'antd';
import { STRESS_EVENT_PAGE } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDPercentage } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { updateFilters } from 'redux/thunks';

const AnalysisInfo = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: eventAnalysis, loading: eventAnalysisLoading } = useSelector(
        (s) => s.stressAnalysis.event
    );
    const { data: fishAnalysis, loading: fishAnalysisLoading } = useSelector(
        (s) => s.stressAnalysis.fish
    );

    const infoList = [
        {
            key: t('stressAnalysis.events'),
            value: eventAnalysis.numberOfEvents
        },
        {
            key: t('stressAnalysis.throws'),
            value: eventAnalysis.numberOfThrows
        },
        {
            key: t('stressAnalysis.boats'),
            value: eventAnalysis.numberOfServiceBoats
        },
        {
            key: t('stressAnalysis.locations'),
            value: eventAnalysis.numberOfPens
        },
        {
            key: t('stressAnalysis.treatedFishes'),
            value: fishAnalysis.numberOfTreatedFishes
        },
        {
            key: t('stressAnalysis.fishGroups'),
            value: fishAnalysis.numberOfFishGroups
        }
    ];

    const handleEventClick = async (eventId) => {
        await dispatch(
            updateFilters({
                selectedStressEventId: eventId
            })
        ).unwrap();

        navigate(STRESS_EVENT_PAGE, { state: { navigatedEventId: eventId } });

        appInsights.trackEvent({ name: 'Event click', properties: { eventId } });
    };

    return (
        <Card className="mt-2">
            <Spin spinning={eventAnalysisLoading || fishAnalysisLoading}>
                <Row className="text-base" gutter={8}>
                    <Col xs={24} sm={16} md={24} xl={11} xxl={13}>
                        <Row>
                            {infoList.map((info) => (
                                <Col
                                    key={info.key}
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    xl={8}
                                    xxl={8}
                                    className="flex flex-col mb-5"
                                >
                                    <span className="font-semibold">{info.key}</span>
                                    <span>{info.value || 0}</span>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={8} xl={4} xxl={4} className="flex flex-col">
                        <span className="font-semibold">{t('stressAnalysis.mortality')}</span>
                        <FDPercentage height={110} width={110} value={fishAnalysis.mortality} />
                    </Col>

                    <Col xs={24} sm={24} md={16} xl={9} xxl={7} className="flex flex-col">
                        <div>
                            <span className="font-semibold mr-1">{t('stressAnalysis.events')}</span>
                            <span className="text-sm">{t('stressAnalysis.clickToSeeDetail')}</span>
                        </div>
                        <Card className="h-[110px] events bg-gray-100">
                            {!eventAnalysis.eventList?.length ? (
                                <p className="m-0 text-center text-red-600">
                                    {t('eventDetail.noEventsFound')}
                                </p>
                            ) : (
                                <div className="max-h-[90px] overflow-y-auto overflow-x-auto">
                                    <table className="whitespace-nowrap">
                                        <tbody>
                                            {eventAnalysis.eventList?.map((event) => (
                                                <tr
                                                    key={event.id}
                                                    className={`cursor-pointer hover:font-medium transition`}
                                                    onClick={() => handleEventClick(event.id)}
                                                >
                                                    <td className="px-2">
                                                        {formatDateTime(event.date)}
                                                    </td>
                                                    <td className="px-2">{event.siteName}</td>
                                                    <td className="px-2">{event.penNumber}</td>
                                                    <td className="px-2">{event.eventType}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </Card>
    );
};

export default AnalysisInfo;
