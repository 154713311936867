import { Button, Card, Col, Row, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDMultipleSelection, FDRangeDate } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import {
    getPensSelection,
    getScoringEventList,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';

const FilterEvents = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { data: scoringEvents, loading } = useSelector((s) => s.scoringEvent.list);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [filters, setFilters] = useState({
        siteIds: initialFilters.siteIds,
        penIds: initialFilters.penIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useEffect(() => {
        dispatch(
            getScoringEventList({
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );
    }, [
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useEffect(() => {
        const { navigatedEventId } = location.state || {};

        if (scoringEvents.some((e) => e.id === initialFilters.selectedScoringEventId)) {
            if (navigatedEventId) {
                location.state.navigatedEventId = null;
            }
            return;
        }

        handleEventChange(navigatedEventId || scoringEvents[0]?.id || null);

        if (navigatedEventId) {
            location.state.navigatedEventId = null;
        }
    }, [scoringEvents]);

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.siteIds;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens
        });
    };

    const handlePensChange = (penIds) => {
        setFilters({
            ...filters,
            penIds: penIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        dispatch(
            updateFilters({
                siteIds: filters.siteIds,
                penIds: filters.penIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );

        appInsights.trackEvent({ name: 'Scoring event filter apply' });
    };

    const handleEventChange = (eventId) => {
        dispatch(
            updateFilters({
                selectedScoringEventId: eventId
            })
        );

        appInsights.trackEvent({ name: 'Selected scoring event change', properties: { eventId } });
    };

    return (
        <div className="filter-bar flex flex-col">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>
            <div className="filter md:text-base rounded-lg flex flex-wrap">
                <div className="filter-bar grow">
                    <Row>
                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.site')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.sitesPlaceholder')}
                                listSelectItem={sitesSelection}
                                onChange={handleSitesChange}
                                value={filters.siteIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.pen')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.pensPlaceholder')}
                                listSelectItem={pensSelection}
                                onChange={handlePensChange}
                                value={filters.penIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={7}>
                            <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                            <FDRangeDate
                                placeholder={[
                                    t('general.filterBar.startDatePlaceholder'),
                                    t('general.filterBar.endDatePlaceholder')
                                ]}
                                onChange={handleRangeDateChange}
                                value={[filters.fromDate, filters.toDate]}
                            />
                        </Col>

                        <Col className="xs:mt-0 sm:mt-6">
                            <Button
                                loading={loading}
                                className={BUTTON_BLUE_CLASS}
                                onClick={handleFilterSubmit}
                            >
                                {t('general.filterBar.apply')}
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className="events min-w-[200px] pt-2.5 pr-2.5 pl-2.5 mb-2.5 grow xl:grow-0">
                    <div className="font-semibold">{t('scoringEvent.events')}</div>
                    <Card className="h-[110px]">
                        <Spin spinning={loading}>
                            {!(scoringEvents.length || loading) ? (
                                <p className="m-0 text-center text-red-600">
                                    {t('scoringEvent.noEventsFound')}
                                </p>
                            ) : (
                                <div className="max-h-[90px] overflow-y-auto overflow-x-hidden">
                                    <table className="whitespace-nowrap">
                                        <tbody>
                                            {scoringEvents.map((event) => (
                                                <tr
                                                    key={event.id}
                                                    className={`cursor-pointer hover:font-medium transition px-2 
                                                            ${
                                                                event.id ===
                                                                    initialFilters.selectedScoringEventId &&
                                                                'bg-sky-700 text-white font-medium'
                                                            }`}
                                                    onClick={() => handleEventChange(event.id)}
                                                >
                                                    <td className="px-2">
                                                        {formatDateTime(event.scoringTime)}
                                                    </td>
                                                    <td className="px-2">{event.siteName}</td>
                                                    <td className="px-2">{event.penNumber}</td>
                                                    <td className="px-2">
                                                        {t('eventType.scoring')}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Spin>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default FilterEvents;
