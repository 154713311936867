import { Card } from 'antd';
import { BLUE, DARK_GREEN, GREEN, ORANGE } from 'common/constants';
import { FDColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mortalityDetailSelector } from 'redux/selector';

const MortalityCount = () => {
    const { t } = useTranslation();

    const { data: mortality } = useSelector(mortalityDetailSelector);

    var mortalityCounts = mortality.fishes?.map((fish) => ({
        name: t(`general.fishMortality.${fish.fishName}`),
        type: t(`general.fishMortality.${fish.fishName}`),
        value: fish.count
    }));

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('mortality.title')}</p>
            <FDColumn
                data={mortalityCounts}
                color={[ORANGE, GREEN, DARK_GREEN, BLUE]}
                height={300}
            />
        </Card>
    );
};

export default MortalityCount;
