import { Card, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    GRAY,
    GREEN,
    ORANGE,
    RED,
    STRESS_EVENT_PAGE,
    STRESS_ZONES,
    YELLOW
} from 'common/constants';
import { formatSeconds, getDifferenceInSeconds } from 'common/utils';
import { FDBar } from 'components';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { processStressEventSelector } from 'redux/selector';
import { useAppDispatch } from 'redux/store';
import { updateFilters } from 'redux/thunks';

const StressZone = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { data: stressEvent, loading } = useSelector(processStressEventSelector);

    const stressZones = map(STRESS_ZONES, (stressZone) => ({
        name: t(stressZone.name),
        value: stressEvent.stressLevels
            ?.filter(
                (stressLevel) =>
                    stressZone.min <= stressLevel.level && stressLevel.level <= stressZone.max
            )
            .reduce(
                (accumulator, stressLevel) =>
                    accumulator +
                    getDifferenceInSeconds(stressLevel.start_time, stressLevel.stop_time),
                0
            )
    }));

    const handleStressZoneChartClick = async () => {
        if (!stressEvent.id) return;

        await dispatch(
            updateFilters({
                selectedStressEventId: stressEvent.id
            })
        ).unwrap();

        navigate(STRESS_EVENT_PAGE, { state: { navigatedEventId: stressEvent.id } });

        appInsights.trackEvent({
            name: 'Stress event is navigated from process page',
            properties: { eventId: stressEvent.id }
        });
    };

    return (
        <Spin spinning={loading}>
            <Card
                className={`mt-2 ${stressEvent.id && 'cursor-pointer'}`}
                onClick={() => handleStressZoneChartClick()}
                role="button"
            >
                <p className="font-medium text-base">{t('process.stressZone')}</p>
                <FDBar
                    data={stressZones}
                    colors={[GRAY, RED, ORANGE, YELLOW, GREEN]}
                    height={300}
                    formatValue={formatSeconds}
                    chartConfig={{
                        animation: false
                    }}
                />
            </Card>
        </Spin>
    );
};

export default StressZone;
