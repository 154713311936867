import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from 'AppInsights';
import React, { useEffect } from 'react';

const withPageViewTracking = (Component, componentName) => {
    const TrackedComponent = (props) => {
        useEffect(() => {
            appInsights.trackPageView({ name: componentName });
        }, []);

        return <Component {...props} />;
    };

    return withAITracking(reactPlugin, TrackedComponent, componentName);
};

export default withPageViewTracking;
