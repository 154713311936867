import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS, FILE_EXPORT_FORMAT_DATE } from 'common/constants';
import { exportZipOfImages } from 'common/utils';
import { WoundAndSizeAttachment } from 'components';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ViewMoreAttachments = ({ open = false, attachment = {}, onClose = () => {} }) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);

    const fishAttribute = {
        wound: 'wound',
        woundOnGill: 'wound_on_gill',
        woundOnMouth: 'wound_on_mouth',
        woundOnBack: 'wound_on_back',
        woundOnBelly: 'wound_on_belly',
        woundOnTail: 'wound_on_tail',
        fillet: 'fillet',
        looserFish: 'looser_fish',
        noWound: 'no_wound'
    };

    const downloadAttachments = async () => {
        if (attachment == null) return;
        setIsDownloading(true);

        const folders = Object.entries(fishAttribute).map(([key, value]) => ({
            name: t(`general.fishAttribute.${key}`),
            imageUrls: attachment[`${value}_attachments`]
        }));
        const fileName = `${t('dailyReport.report.table.woundAndSizeImages')} - ${moment().format(
            FILE_EXPORT_FORMAT_DATE
        )}`;

        await exportZipOfImages(folders, fileName);

        setIsDownloading(false);
        appInsights.trackEvent({ name: 'Wound and size images download' });
    };

    return (
        <div>
            <Modal
                title={t('dailyReport.report.table.woundAndSizeImages')}
                open={open}
                onCancel={() => onClose()}
                width={800}
                footer={
                    <>
                        <Button onClick={() => onClose()}>{t('button.close')}</Button>
                        <Button
                            onClick={() => downloadAttachments()}
                            className={BUTTON_BLUE_CLASS}
                            icon={<DownloadOutlined />}
                            loading={isDownloading}
                        >
                            {t('button.download')}
                        </Button>
                    </>
                }
            >
                {Object.entries(fishAttribute).map(([key, value]) => (
                    <div className="mb-2" key={key}>
                        <span className="font-medium">{t(`general.fishAttribute.${key}`)}</span>
                        <WoundAndSizeAttachment
                            attachments={attachment[`${value}_attachments`]}
                            limit={attachment[`${value}_attachments`]?.length}
                        />
                    </div>
                ))}
            </Modal>
        </div>
    );
};

ViewMoreAttachments.propTypes = {
    open: PropTypes.bool,
    attachment: PropTypes.object,
    onClose: PropTypes.func
};

export default ViewMoreAttachments;
