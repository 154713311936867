import { loginRequest, msalInstance } from 'authConfig';
import { SESSION_EXPIRED_PAGE } from 'common/constants';
import { getAuthority } from 'common/utils';

export const getToken = async () => {
    const account = msalInstance.getAllAccounts()[0];
    if (!account) return;

    const request = {
        account: account,
        scopes: loginRequest.scopes,
        authority: getAuthority(account.tenantId)
    };

    try {
        const response = await msalInstance.acquireTokenSilent(request);
        return response.idToken;
    } catch (error) {
        window.location.href = SESSION_EXPIRED_PAGE;
    }
};
