import { appInsights } from 'AppInsights';
import { Button, DatePicker, Form, InputNumber, Modal, Spin } from 'antd';
import { BUTTON_BLUE_CLASS, BUTTON_WHITE_CLASS } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { moveFishGroup } from 'redux/thunks';

const MoveGroupForm = ({ open = false, fishGroup, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fishGroup.loading);
    const pensSelection = useSelector((s) => s.penSelection.data);
    const stockCount = useSelector((s) => s.stockCount.data);

    useEffect(() => {
        form.setFieldValue('totalFishCount', stockCount.count);
    }, [stockCount]);

    const handleMoveFishGroup = async (req) => {
        req.actionDate.startOf('day');

        try {
            const response = await dispatch(
                moveFishGroup({
                    id: fishGroup.id,
                    item: req
                })
            ).unwrap();

            alertSuccessMessage(response);
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'FFI move',
            properties: { fishwellFishGroupId: fishGroup.id }
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('fishGroup.moveGroupForm.title')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS}>
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={handleMoveFishGroup}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('fishGroup.actionDate.title')}
                        name="actionDate"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.actionDate.required')
                            }
                        ]}
                    >
                        <DatePicker placeholder={t('fishGroup.actionDate.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => !pen.hasFishGroup && pen.siteId === fishGroup?.siteId
                            )}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item label={t('fishGroup.totalFishCount.title')} name="totalFishCount">
                        <InputNumber
                            className="w-full"
                            placeholder={t('fishGroup.totalFishCount.placeholder')}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

MoveGroupForm.propTypes = {
    open: PropTypes.bool,
    fishGroup: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default MoveGroupForm;
