import { appInsights } from 'AppInsights';
import { Button, Form, Input, Space } from 'antd';
import { BUTTON_BLUE_CLASS, BUTTON_GRAY_CLASS, GUEST_USER_INVITATION_API } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { TextEditor } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { API } from 'services';
import InvitationEmailTemplate from './InvitationEmailTemplate';

const GuestUserInvitation = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const userName = Form.useWatch('name', form);
    const { data: profile } = useSelector((s) => s.profile);
    const [loading, setLoading] = useState(false);

    const emailBody = ReactDOMServer.renderToStaticMarkup(
        <InvitationEmailTemplate userName={userName} companyName={profile.company?.name} />
    );

    useEffect(() => {
        form.setFieldValue('emailBody', emailBody);
    }, [emailBody]);

    const sendInvitation = async (req) => {
        try {
            setLoading(true);
            await API.post(GUEST_USER_INVITATION_API, req);
            alertSuccessMessage('message.sendInvitationSuccessfully');
            form.resetFields();
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }

        appInsights.trackEvent({
            name: 'Guest user invitation send',
            properties: { name: req.name, email: req.email }
        });
    };

    return (
        <>
            <h2 className="mb-2">{t('guestUserInvitation.title')}</h2>
            <Form
                name="basic"
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 5 },
                    md: { span: 4 },
                    lg: { span: 4 },
                    xl: { span: 3 },
                    xxl: { span: 2 }
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 16 },
                    md: { span: 19 },
                    lg: { span: 16 },
                    xl: { span: 12 },
                    xxl: { span: 9 }
                }}
                onFinish={sendInvitation}
                autoComplete="off"
                form={form}
                data-testid="guest-user-invitation-form"
            >
                <Form.Item
                    label={t('guestUserInvitation.form.name.title')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t('guestUserInvitation.form.name.required')
                        }
                    ]}
                >
                    <Input placeholder={t('guestUserInvitation.form.name.placeholder')} />
                </Form.Item>

                <Form.Item
                    label={t('guestUserInvitation.form.email.title')}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: t('guestUserInvitation.form.email.required')
                        },
                        {
                            type: 'email',
                            message: t('guestUserInvitation.form.email.invalid')
                        }
                    ]}
                >
                    <Input placeholder={t('guestUserInvitation.form.email.placeholder')} />
                </Form.Item>

                <Form.Item
                    label={t('guestUserInvitation.form.emailBody.title')}
                    name="emailBody"
                    rules={[
                        {
                            required: true,
                            message: t('guestUserInvitation.form.emailBody.required')
                        }
                    ]}
                >
                    <TextEditor htmlContent={emailBody} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        xs: { offset: 0, span: 24 },
                        sm: { offset: 5, span: 16 },
                        md: { offset: 4, span: 19 },
                        lg: { offset: 4, span: 16 },
                        xl: { offset: 3, span: 12 },
                        xxl: { offset: 2, span: 9 }
                    }}
                >
                    <Space>
                        <Button className={BUTTON_GRAY_CLASS} onClick={() => form.resetFields()}>
                            {t('button.clear')}
                        </Button>
                        <Button className={BUTTON_BLUE_CLASS} htmlType="submit" loading={loading}>
                            {t('button.invite')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};

export default withPageViewTracking(GuestUserInvitation, 'Invite guest user');
