import { ORGANIZATION_PAGE } from 'common/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const InvitationEmailTemplate = ({ userName, companyName }) => {
    const { t } = useTranslation();

    return (
        <>
            <p>
                <span>{t('guestUserInvitation.emailTemplate.greeting')},&nbsp;</span>
                <strong>{userName || t('guestUserInvitation.emailTemplate.userName')}</strong>
                <span>.</span>
            </p>
            <p>
                <span>{t('guestUserInvitation.emailTemplate.invitation')}&nbsp;</span>
                <strong>{companyName || t('guestUserInvitation.emailTemplate.companyName')}</strong>
                <span>.</span>
            </p>
            <p>
                <span>{t('guestUserInvitation.emailTemplate.click')}&nbsp;</span>
                <a href={window.location.origin + ORGANIZATION_PAGE} target="_self">
                    {t('guestUserInvitation.emailTemplate.link')}
                </a>
                <span>&nbsp;{t('guestUserInvitation.emailTemplate.getStarted')}</span>
                <span>.</span>
            </p>
        </>
    );
};

InvitationEmailTemplate.propTypes = {
    userName: PropTypes.string,
    companyName: PropTypes.string
};

export default InvitationEmailTemplate;
