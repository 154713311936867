import { appInsights } from 'AppInsights';
import { Button, Spin } from 'antd';
import {
    BAR_CHART,
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    LINE_CHART
} from 'common/constants';
import { FDColumn, FDLine } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ComparisonChart = ({ charts = [], loading = false }) => {
    const [chartType, setChartType] = useState(LINE_CHART);
    const { t } = useTranslation();

    const handleChartTypeChange = (newType) => {
        setChartType(newType);

        appInsights.trackEvent({ name: 'Chart type change', properties: { type: newType } });
    };

    return (
        <>
            {charts.length > 0 && (
                <div className="bg-white p-5 min-h-[400px] mb-5" data-testid="comparison-chart">
                    <div className="flex justify-end">
                        <Button
                            className={
                                chartType === LINE_CHART
                                    ? BUTTON_BLUE_SELECTED
                                    : BUTTON_BLUE_UN_SELECTED
                            }
                            onClick={() => handleChartTypeChange(LINE_CHART)}
                        >
                            {t('comparison.chart.lineChart')}
                        </Button>
                        &nbsp;
                        <Button
                            className={
                                chartType === BAR_CHART
                                    ? BUTTON_BLUE_SELECTED
                                    : BUTTON_BLUE_UN_SELECTED
                            }
                            onClick={() => handleChartTypeChange(BAR_CHART)}
                        >
                            {t('comparison.chart.barChart')}
                        </Button>
                    </div>

                    <Spin spinning={loading}>
                        {chartType === LINE_CHART ? (
                            <FDLine data={charts} />
                        ) : (
                            <FDColumn data={charts} />
                        )}
                    </Spin>
                </div>
            )}
        </>
    );
};

ComparisonChart.propTypes = {
    charts: PropTypes.array,
    loading: PropTypes.bool
};

export default ComparisonChart;
