import { createSlice } from '@reduxjs/toolkit';
import { updateLocale } from 'redux/thunks';

const initialState = {
    data: localStorage.getItem('language') ?? 'nb'
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {},
    extraReducers: {
        [updateLocale.pending]: (state) => {
            state.loading = true;
        },
        [updateLocale.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
            localStorage.setItem('language', state.data);
        },
        [updateLocale.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
