import { EditOutlined } from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Card, Col, Form, InputNumber, Row, Spin, Tag } from 'antd';
import { BLUE, BUTTON_BLUE_CLASS, LIGHT_GRAY, SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { withPageViewTracking } from 'hoc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCompanyConfigs, updateCompanyConfig } from 'redux/thunks';
import Swal from 'sweetalert2';

const CompanyConfig = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: companyConfigs, loading } = useSelector((s) => s.companyConfig);

    const [inputValues, setInputValues] = useState({});

    useEffect(() => {
        dispatch(getCompanyConfigs());
    }, []);

    const handleUpdateCompanyConfig = (item, values) => {
        Swal.fire({
            title: t('general.action.saveModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.saveModal.confirmText'),
            cancelButtonText: t('general.action.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const newValue = [
                        { ...item.value[0], to: values.firstValue },
                        {
                            ...item.value[1],
                            from: values.firstValue + 0.001,
                            to: values.secondValue - 0.001
                        },
                        { ...item.value[2], from: values.secondValue }
                    ];
                    await dispatch(
                        updateCompanyConfig({ id: item.id, item: { value: newValue } })
                    ).unwrap();

                    dispatch(getCompanyConfigs());

                    alertSuccessMessage(SUCCESSFULLY_EDIT_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });

        appInsights.trackEvent({ name: 'Company config updated', properties: { name: item.name } });
    };

    return (
        <>
            <h2 className="mb-2">{t('companyConfig.title')}</h2>

            <Spin spinning={loading}>
                <Row gutter={[16, 16]}>
                    {companyConfigs.map((item) => (
                        <Col xs={24} xl={12} key={item.id}>
                            <Form
                                id={`form-${item.id}`}
                                initialValues={{
                                    firstValue: item.value[0].to,
                                    secondValue: item.value[2].from
                                }}
                                onFinish={(values) => handleUpdateCompanyConfig(item, values)}
                                onValuesChange={(changedValues, allValues) => {
                                    setInputValues((prevValues) => ({
                                        ...prevValues,
                                        [item.id]: allValues
                                    }));
                                }}
                            >
                                <Card
                                    title={t(`companyConfig.table.${item.name}`)}
                                    actions={[
                                        <Button
                                            className={BUTTON_BLUE_CLASS}
                                            form={`form-${item.id}`}
                                            key="submit"
                                            htmlType="submit"
                                            disabled={
                                                inputValues[item.id]?.firstValue >=
                                                inputValues[item.id]?.secondValue
                                            }
                                        >
                                            <EditOutlined />
                                            <span>{t('general.form.saveChange')}</span>
                                        </Button>
                                    ]}
                                >
                                    <div className="flex flex-wrap items-center justify-center px-5">
                                        <Tag color={item.value[0].color} className="m-0">
                                            {t(`companyConfig.table.${item.value[0].key}`)}
                                        </Tag>

                                        <span className="px-1 font-bold">&lt;&#61;</span>

                                        <Form.Item name="firstValue" noStyle>
                                            <InputNumber min={0} max={100} style={{ width: 60 }} />
                                        </Form.Item>

                                        <span className="px-1 font-bold">&lt;</span>

                                        <Tag color={item.value[1].color} className="m-0">
                                            {t(`companyConfig.table.${item.value[1].key}`)}
                                        </Tag>

                                        <span className="px-1 font-bold">&lt;</span>

                                        <Form.Item name="secondValue" noStyle>
                                            <InputNumber min={0} max={100} style={{ width: 60 }} />
                                        </Form.Item>

                                        <span className="px-1 font-bold">&lt;&#61;</span>
                                        <Tag color={item.value[2].color} className="m-0">
                                            {t(`companyConfig.table.${item.value[2].key}`)}
                                        </Tag>
                                    </div>
                                </Card>
                            </Form>
                        </Col>
                    ))}
                </Row>
            </Spin>
        </>
    );
};

export default withPageViewTracking(CompanyConfig, 'Company config');
