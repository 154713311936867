import { createAsyncThunk } from '@reduxjs/toolkit';
import { OTHER_DEAD_FISH_COUNT_API } from 'common/constants';
import { API } from 'services';

export const getOtherDeadFishCounts = createAsyncThunk(
    'otherDeadFishCounts/getOtherDeadFishCounts',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(OTHER_DEAD_FISH_COUNT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addOtherDeadFishCount = createAsyncThunk(
    'otherDeadFishCounts/addOtherDeadFishCount',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(OTHER_DEAD_FISH_COUNT_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateOtherDeadFishCount = createAsyncThunk(
    'otherDeadFishCounts/updateOtherDeadFishCount',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${OTHER_DEAD_FISH_COUNT_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteOtherDeadFishCount = createAsyncThunk(
    'otherDeadFishCounts/deleteOtherDeadFishCount',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${OTHER_DEAD_FISH_COUNT_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
