import { Button, Card, Col, Row } from 'antd';
import { BUTTON_GRAY_SELECTED, BUTTON_GRAY_UN_SELECTED, DATE_TIME_FORMAT } from 'common/constants';
import { calculateTimeDifference, formatDateTime } from 'common/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EventInfo = ({ eventThrow, onEventThrowChange = () => {} }) => {
    const { t } = useTranslation();

    const { data: event } = useSelector((s) => s.eventDetail);

    const infoList = [
        {
            key: 'eventDetail.location',
            value: `${event.siteName ?? ''} - ${event.penNumber ?? ''}`,
            col: { xs: 12, xl: 6, xxl: 4 }
        },
        {
            key: 'eventDetail.boatName',
            value: event.boatName,
            col: { xs: 12, xl: 6, xxl: 4 }
        },
        {
            key: 'eventDetail.eventType',
            value: event.eventType,
            col: { xs: 12, xl: 6, xxl: 4 }
        },
        {
            key: 'eventDetail.time',
            value: (
                <>
                    <span>{formatDateTime(event.startTime, DATE_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(event.stopTime, DATE_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'eventDetail.actualCrowdingTime',
            value: calculateTimeDifference(event.startTime, event.stopTime),
            col: { xs: 12, xl: 6, xxl: 6 }
        }
    ];

    return (
        <Card className="event-info">
            <Row className="text-base" gutter={8}>
                {infoList.map((info) => (
                    <Col key={info.key} {...info.col} className="flex flex-col mb-2">
                        <span className="font-semibold">{t(info.key)}</span>
                        <span>{info.value}</span>
                    </Col>
                ))}
            </Row>

            <div className="w-full flex justify-start flex-wrap">
                <Button
                    className={`${
                        eventThrow ? BUTTON_GRAY_UN_SELECTED : BUTTON_GRAY_SELECTED
                    } mr-4 mt-4 w-20`}
                    onClick={() => onEventThrowChange(null)}
                >
                    {t('eventDetail.overall')}
                </Button>

                {event.throws?.map((throwItem) => (
                    <Button
                        className={`${
                            eventThrow?.id === throwItem.id
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        } mr-4 mt-4 w-20`}
                        onClick={() => onEventThrowChange(throwItem)}
                        key={throwItem.id}
                    >
                        {t('eventDetail.throw')} {throwItem.throwName}
                    </Button>
                ))}
            </div>
        </Card>
    );
};

EventInfo.propTypes = {
    eventThrow: PropTypes.object,
    onEventThrowChange: PropTypes.func
};

export default EventInfo;
