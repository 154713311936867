import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    STRESS_ANALYSIS_FOR_CROWDING_TIME_PER_THROW_API,
    STRESS_ANALYSIS_FOR_EVENT_API,
    STRESS_ANALYSIS_FOR_FISH_API,
    STRESS_ANALYSIS_FOR_MORTALITY_PER_EVENT_API,
    STRESS_ANALYSIS_FOR_OXYGEN_LEVEL_PER_THROW_API,
    STRESS_ANALYSIS_FOR_STRESS_LEVEL_PER_THROW_API,
    STRESS_ANALYSIS_FOR_STRESS_ZONE_PER_THROW_API
} from 'common/constants';
import { API } from 'services';

export const getStressAnalysisForEvent = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForEvent',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_EVENT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForFish = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForFish',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_FISH_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForStressLevelPerThrow = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForStressLevelPerThrow',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_STRESS_LEVEL_PER_THROW_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForCrowdingTimePerThrow = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForCrowdingTimePerThrow',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(
                STRESS_ANALYSIS_FOR_CROWDING_TIME_PER_THROW_API,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForStressZonePerThrow = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForStressZonePerThrow',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_STRESS_ZONE_PER_THROW_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForOxygenLevelPerThrow = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForOxygenLevelPerThrow',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_OXYGEN_LEVEL_PER_THROW_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForMortalityPerEvent = createAsyncThunk(
    'stressAnalyses/getStressAnalysisForMortalityPerEvent',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_MORTALITY_PER_EVENT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
