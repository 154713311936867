import { createSlice } from '@reduxjs/toolkit';
import { getStressAnalysisForCorrelation, getStressAnalysisForRegression } from 'redux/thunks';
import { getStressAnalysisCorrelationTypes } from 'redux/thunks';

const initialState = {
    correlationType: { data: [], loading: false },
    regression: { data: [], loading: false },
    correlation: { data: {}, loading: false }
};

export const correlationAnalysisSlice = createSlice({
    name: 'correlationAnalysis',
    initialState,
    reducers: {},
    extraReducers: {
        [getStressAnalysisCorrelationTypes.pending]: (state) => {
            state.correlationType.loading = true;
        },
        [getStressAnalysisCorrelationTypes.fulfilled]: (state, action) => {
            state.correlationType.loading = false;
            state.correlationType.data = action.payload;
        },
        [getStressAnalysisCorrelationTypes.rejected]: (state) => {
            state.correlationType.loading = false;
            state.correlationType.data = initialState.correlationType.data;
        },

        [getStressAnalysisForRegression.pending]: (state) => {
            state.regression.loading = true;
        },
        [getStressAnalysisForRegression.fulfilled]: (state, action) => {
            state.regression.loading = false;
            state.regression.data = action.payload;
        },
        [getStressAnalysisForRegression.rejected]: (state) => {
            state.regression.loading = false;
            state.regression.data = initialState.regression.data;
        },

        [getStressAnalysisForCorrelation.pending]: (state) => {
            state.correlation.loading = true;
        },
        [getStressAnalysisForCorrelation.fulfilled]: (state, action) => {
            state.correlation.loading = false;
            state.correlation.data = action.payload;
        },
        [getStressAnalysisForCorrelation.rejected]: (state) => {
            state.correlation.loading = false;
            state.correlation.data = initialState.correlation.data;
        }
    }
});
