import { createAsyncThunk } from '@reduxjs/toolkit';
import { PEN_COMPARISON_API, SITE_COMPARISON_API } from 'common/constants';
import moment from 'moment';
import { API } from 'services';

export const getSiteComparison = createAsyncThunk(
    'comparisons/getSiteComparison',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(SITE_COMPARISON_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getPenComparison = createAsyncThunk(
    'comparisons/getPenComparison',
    async (filters, { rejectWithValue }) => {
        if (!filters.toDate) {
            filters = {
                ...filters,
                toDate: moment().endOf('day')
            };
        }

        try {
            const { data } = await API.get(PEN_COMPARISON_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
