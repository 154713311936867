import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    DEAD_FISH_COUNTING_ENGINE_START_API,
    DEAD_FISH_COUNTING_ENGINE_STATUS_API,
    DEAD_FISH_COUNTING_ENGINE_STOP_API,
    DEAD_FISH_COUNTING_SITE_API
} from 'common/constants';
import { API, NEW_DEAD_FISH_COUNTING_ENGINE_API } from 'services';

export const getDeadFishCountingSites = createAsyncThunk(
    'liveDeadFishCounting/getDeadFishCountingSites',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(DEAD_FISH_COUNTING_SITE_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDeadFishCountingEngineStatus = createAsyncThunk(
    'liveDeadFishCounting/getDeadFishCountingEngineStatus',
    async ({ baseUrl }, { rejectWithValue }) => {
        try {
            const { data } = await NEW_DEAD_FISH_COUNTING_ENGINE_API.get(
                baseUrl + DEAD_FISH_COUNTING_ENGINE_STATUS_API
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const startDeadFishCountingEngine = createAsyncThunk(
    'liveDeadFishCounting/startDeadFishCountingEngine',
    async ({ baseUrl, penNumber = 1, siteName, localityNumber }, { rejectWithValue }) => {
        try {
            const { data } = await NEW_DEAD_FISH_COUNTING_ENGINE_API.get(
                `${baseUrl}${DEAD_FISH_COUNTING_ENGINE_START_API}/${penNumber}/${siteName}/${localityNumber}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const stopDeadFishCountingEngine = createAsyncThunk(
    'liveDeadFishCounting/stopDeadFishCountingEngine',
    async ({ baseUrl, penNumber = 1, siteName, localityNumber }, { rejectWithValue }) => {
        try {
            const { data } = await NEW_DEAD_FISH_COUNTING_ENGINE_API.get(
                `${baseUrl}${DEAD_FISH_COUNTING_ENGINE_STOP_API}/${penNumber}/${siteName}/${localityNumber}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
