import { Card } from 'antd';
import { ORANGE, TEMPERATURE_CHART_CONFIG } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { reduce } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { eventDetailStartTimeSelector, temperaturesSelector } from 'redux/selector';

const Temperature = ({ sliderValues }) => {
    const { t } = useTranslation();

    const temperatures = useSelector(temperaturesSelector);
    const eventDetailStartTime = useSelector(eventDetailStartTimeSelector);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const filteredData = reduce(
            temperatures,
            (accumulator, temperature) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    eventDetailStartTime,
                    temperature.date
                );

                if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                    accumulator.push({
                        name: formatSeconds(differenceInSeconds),
                        value: temperature.value
                    });

                return accumulator;
            },
            []
        );

        setChartData(filteredData);
    }, [sliderValues]);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.temperature')}</p>
            <FDLine
                data={chartData}
                height={300}
                isSingleLine={true}
                color={ORANGE}
                chartConfig={TEMPERATURE_CHART_CONFIG}
            />
        </Card>
    );
};

Temperature.propTypes = {
    sliderValues: PropTypes.array
};

export default Temperature;
