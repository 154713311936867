import { Card, Spin } from 'antd';
import { BLUE } from 'common/constants';
import { FDLine } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const MortalityPerEvent = () => {
    const { t } = useTranslation();

    const { data: mortalitiesPerEvent, loading: mortalitiesPerEventLoading } = useSelector(
        (s) => s.stressAnalysis.mortalityPerEvent
    );

    const formattedData = mortalitiesPerEvent.map((mortality, index) => ({
        name: String(index + 1),
        value: mortality.numberOfDeadFishes
    }));

    return (
        <Spin spinning={mortalitiesPerEventLoading}>
            <Card>
                <p className="font-medium text-base">{t('stressAnalysis.mortalityPerEvent')}</p>
                <FDLine data={formattedData} isSingleLine={true} color={BLUE} height={320} />
            </Card>
        </Spin>
    );
};

export default MortalityPerEvent;
