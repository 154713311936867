import React from 'react';
import FilterBar from './FilterBar/FilterBar';
import TableEventList from './TableEventList/TableEventList';
import { withPageViewTracking } from 'hoc';

const StressEventList = () => {
    return (
        <>
            <FilterBar />
            <TableEventList />
        </>
    );
}

export default withPageViewTracking(StressEventList, 'Stress event list');
