import { Card, Col, Row } from 'antd';
import { DATE_TIME_FORMAT } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ScoringInfo = ({ scoringEvent = {} }) => {
    const { t } = useTranslation();

    const infoList = [
        {
            key: 'scoringEvent.location',
            value: `${scoringEvent.siteName ?? ''} - ${scoringEvent.penNumber ?? ''}`,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.boatName',
            value: scoringEvent.boatName,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.method',
            value: scoringEvent.method,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.seaTemperature',
            value: scoringEvent.seaTemperature,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.orcastOrStringOfPearls',
            value:
                scoringEvent.orcastOrStringOfPearls === 0
                    ? t('scoringEvent.orcast')
                    : t('scoringEvent.stringOfPearls'),
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.numberOfFish',
            value: scoringEvent.scoringPerFishes?.length,
            col: { xs: 12, xl: 6 }
        },
        {
            key: 'scoringEvent.time',
            value: (
                <>
                    <span>{formatDateTime(scoringEvent.startTime, DATE_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(scoringEvent.stopTime, DATE_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 24, xl: 12 }
        }
    ];

    return (
        <Card className="event-info mt-2">
            <Row className="text-base" gutter={8}>
                {infoList.map((info) => (
                    <Col key={info.key} {...info.col} className="flex flex-col mb-2">
                        <span className="font-semibold">{t(info.key)}</span>
                        <span>{info.value}</span>
                    </Col>
                ))}
            </Row>
        </Card>
    );
};

ScoringInfo.propTypes = {
    scoringEvent: PropTypes.object
};

export default ScoringInfo;
