import { Badge, Card, Col, Image, Row, Spin } from 'antd';
import defaultCompanyLogo from 'assets/Default_Company_Logo.jpg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FDDailyMortalityCount from '../DailyMortalityCount';
import FDStockCount from '../StockCount';
import './FDGeneralInfo.scss';

const FDGeneralInfo = ({ penId, siteId, logo, infoList = [], status = {}, loading = false }) => {
    const { t } = useTranslation();

    const info = (
        <Card bordered className="text-base">
            <Row gutter={8}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={7}
                    xl={5}
                    xxl={4}
                    className="flex justify-center items-center avatar"
                >
                    <Image
                        className="rounded-sm border-inherit object-contain"
                        style={{ border: '1px #c7c9cb solid' }}
                        width={150}
                        height={150}
                        alt="Company logo"
                        src={logo || defaultCompanyLogo}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={17} xl={17} xxl={10} className="flex items-center">
                    <div className="w-full">
                        <Row gutter={8}>
                            {infoList.map((info, index) => (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={8}
                                    xxl={8}
                                    className="py-4"
                                    key={index}
                                >
                                    <div className="font-semibold">{info.key}</div>
                                    <div className="info-value">{info.value}</div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Col>

                <Col
                    className="daily-report w-full flex justify-center"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={10}
                >
                    <div className="flex xs:flex-col sm:flex-row justify-evenly w-full">
                        <FDStockCount />
                        <FDDailyMortalityCount siteId={siteId} penId={penId} />
                    </div>
                </Col>
            </Row>
        </Card>
    );

    return (
        <div className="general-info">
            <Spin spinning={loading}>
                {status?.key ? (
                    <Badge.Ribbon text={t(`mortalityStatus.${status.key}`)} color={status.color}>
                        {info}
                    </Badge.Ribbon>
                ) : (
                    info
                )}
            </Spin>
        </div>
    );
};

FDGeneralInfo.propTypes = {
    penId: PropTypes.string,
    siteId: PropTypes.string,
    logo: PropTypes.string,
    infoList: PropTypes.array,
    status: PropTypes.object,
    loading: PropTypes.bool
};

export default FDGeneralInfo;
