import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    COMPANY_NOTIFICATION_API,
    NOTIFICATION_API,
    PEN_NOTIFICATION_API,
    SITE_NOTIFICATION_API
} from 'common/constants';
import { API } from 'services';

export const getCompanyNotifications = createAsyncThunk(
    'notifications/getCompanyNotifications',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(COMPANY_NOTIFICATION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSitesNotifications = createAsyncThunk(
    'notifications/getSitesNotifications',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SITE_NOTIFICATION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getPensNotifications = createAsyncThunk(
    'notifications/getPensNotifications',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(PEN_NOTIFICATION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getNotifications = createAsyncThunk(
    'notifications/getNotifications',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(NOTIFICATION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const readNotification = createAsyncThunk(
    'notifications/readNotification',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${NOTIFICATION_API}/read/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
