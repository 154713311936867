import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getEventDetail } from 'redux/thunks';
import EventCharts from './EventCharts';
import EventInfo from './EventInfo';
import SliderFilter from './SliderFilter';
import { appInsights } from 'AppInsights';

const EventData = () => {
    const dispatch = useAppDispatch();

    const { data: event } = useSelector((s) => s.eventDetail);
    const initialFilters = useSelector((s) => s.filter.data);

    const [eventThrow, setEventThrow] = useState();
    const [sliderValues, setSliderValues] = useState();

    useEffect(() => {
        if (initialFilters.selectedStressEventId) {
            dispatch(getEventDetail(initialFilters.selectedStressEventId));
        }
    }, [initialFilters.selectedStressEventId]);

    useEffect(() => {
        setEventThrow(null);
    }, [event.id]);

    const handleEventThrowChange = (newEventThrow) => {
        setEventThrow(newEventThrow);

        appInsights.trackEvent({
            name: 'Event throw change',
            properties: { eventThrow: newEventThrow }
        });
    };

    const handleSliderValuesChange = (newSliderValues) => {
        setSliderValues(newSliderValues);

        appInsights.trackEvent({
            name: 'Event slider values change',
            properties: { sliderValues: newSliderValues }
        });
    };

    return (
        <>
            <EventInfo eventThrow={eventThrow} onEventThrowChange={handleEventThrowChange} />

            <SliderFilter eventThrow={eventThrow} onSliderValuesChange={handleSliderValuesChange} />

            <EventCharts sliderValues={sliderValues} />
        </>
    );
};

export default EventData;
