import { createSlice } from '@reduxjs/toolkit';
import {
    addOtherDeadFishCount,
    deleteOtherDeadFishCount,
    getOtherDeadFishCounts,
    updateOtherDeadFishCount
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const otherDeadFishCountSlice = createSlice({
    name: 'otherDeadFishCount',
    initialState,
    reducers: {},
    extraReducers: {
        [getOtherDeadFishCounts.pending]: (state) => {
            state.loading = true;
        },
        [getOtherDeadFishCounts.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getOtherDeadFishCounts.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [addOtherDeadFishCount.pending]: (state) => {
            state.loading = true;
        },
        [addOtherDeadFishCount.fulfilled]: (state) => {
            state.loading = false;
        },
        [addOtherDeadFishCount.rejected]: (state) => {
            state.loading = false;
        },

        [updateOtherDeadFishCount.pending]: (state) => {
            state.loading = true;
        },
        [updateOtherDeadFishCount.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateOtherDeadFishCount.rejected]: (state) => {
            state.loading = false;
        },

        [deleteOtherDeadFishCount.pending]: (state) => {
            state.loading = true;
        },
        [deleteOtherDeadFishCount.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteOtherDeadFishCount.rejected]: (state) => {
            state.loading = false;
        }
    }
});
