export const GRADIENT_CHART_CONFIG = {
    yAxis: {
        tickCount: 10,
        max: 100,
        min: 0,
        grid: {
            line: {
                style: {
                    lineWidth: 0
                }
            }
        }
    },
    padding: [12, 15, 25, 28],
    animation: false
};

export const CROWDING_CHART_CONFIG = {
    yAxis: {
        max: 100,
        min: 0
    },
    animation: false
};

export const TEMPERATURE_CHART_CONFIG = {
    yAxis: {
        tickCount: 10,
        max: 18,
        min: 0
    },
    animation: false
};

export const OXYGEN_CHART_CONFIG = {
    ...GRADIENT_CHART_CONFIG,
    yAxis: {
        ...GRADIENT_CHART_CONFIG.yAxis,
        max: 130,
        min: 0
    },
    animation: false
};
