import { Card } from 'antd';
import { TIME_PERIODS, TIME_PERIOD_DEFAULT } from 'common/constants';
import { FDSelection } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateFilters } from 'redux/thunks';
import EventChart from './EventChart';
import FDFishMortalityCard from './FishMortalityCard';
import FDFishMortalityChart from './FishMortalityChart';
import './index.scss';
import { appInsights } from 'AppInsights';

const FDFishMortality = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { stressEventSwitch } = useFlags();

    const initialFilters = useSelector((s) => s.filter.data);

    useEffect(() => {
        dispatch(
            updateFilters({
                fromDate: initialFilters.timePeriod.fromDate,
                toDate: initialFilters.timePeriod.fromDate ? moment().endOf('day') : null
            })
        );
    }, []);

    const handlePeriodChange = (value, object) => {
        const timePeriod = {
            value,
            fromDate: object.from
        };

        dispatch(
            updateFilters({
                timePeriod,
                fromDate: timePeriod.fromDate,
                toDate: timePeriod.fromDate ? moment().endOf('day') : null
            })
        );

        appInsights.trackEvent({
            name: 'Fish mortality period change',
            properties: { fromDate: object.from }
        });
    };

    return (
        <div className="fish-mortality mt-5">
            <div className="title mb-2">
                <h2 className="inline mr-1">{t('dashboard.fishMortality.title')}</h2>
                <FDSelection
                    placeholder="Select a period"
                    listSelectItem={TIME_PERIODS.map((period) => ({
                        ...period,
                        text: t(period.text)
                    }))}
                    defaultValue={TIME_PERIOD_DEFAULT.id}
                    className="w-40"
                    onChange={handlePeriodChange}
                    value={initialFilters.timePeriod.value}
                />
            </div>

            <FDFishMortalityCard />

            <FDFishMortalityChart />

            {stressEventSwitch && (
                <Card>
                    <p className="font-medium text-base m-0">{t('dashboard.events')}</p>
                    <EventChart />
                </Card>
            )}
        </div>
    );
};

export default FDFishMortality;
