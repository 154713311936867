import { createAsyncThunk } from '@reduxjs/toolkit';
import { SITES_API, SITES_CONTAINING_PENS_API } from 'common/constants';
import { API } from 'services';

export const getSites = createAsyncThunk('sites/getSites', async (filters, { rejectWithValue }) => {
    try {
        const { data } = await API.get(SITES_API, filters);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const addSite = createAsyncThunk('sites/addSite', async (item, { rejectWithValue }) => {
    try {
        const { data } = await API.post(SITES_API, item);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const updateSite = createAsyncThunk(
    'sites/updateSite',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${SITES_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteSite = createAsyncThunk('sites/deleteSite', async (id, { rejectWithValue }) => {
    try {
        const { data } = await API.delete(`${SITES_API}/${id}`);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const restoreSite = createAsyncThunk(
    'sites/deleteSite',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${SITES_API}/restore`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSitesContainingPens = createAsyncThunk(
    'sites/getSitesContainingPens',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SITES_CONTAINING_PENS_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSiteDetail = createAsyncThunk(
    'sites/getSiteDetail',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${SITES_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
