import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditor = ({ onChange = () => {}, htmlContent }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if (!htmlContent) {
            setEditorState(EditorState.createEmpty());
            return;
        }

        const contentBlock = htmlToDraft(htmlContent);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        let newEditorState = EditorState.createWithContent(contentState);

        setEditorState(newEditorState);
    }, [htmlContent]);

    const isEmptyContent = (editorContent) => {
        const text = editorContent.getPlainText().trim();
        return !text;
    };

    const convertTextToHtml = (editorState) => {
        const content = editorState.getCurrentContent();

        return isEmptyContent(content) ? null : draftToHtml(convertToRaw(content));
    };

    const handleEditorStateChange = (state) => {
        setEditorState(state);
        onChange(convertTextToHtml(state));
    };

    return (
        <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper border border-slate-300 border-solid"
            editorClassName="demo-editor bg-white px-2 h-64"
            onEditorStateChange={handleEditorStateChange}
            data-testid="text-editor"
        />
    );
};

TextEditor.propTypes = {
    onChange: PropTypes.func,
    htmlContent: PropTypes.string
};

export default TextEditor;
