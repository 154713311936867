import { appInsights } from 'AppInsights';
import { Button, Form, Input, Modal, Spin } from 'antd';
import { SUCCESSFULLY_UPDATE_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDUploadImage } from 'components/common/UploadImage';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCurrentProfile, updateCurrentProfile } from 'redux/thunks';

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }

    return e?.fileList;
};

const UpdateProfile = ({ visible, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const { data, loading } = useSelector((s) => s.profile);

    useEffect(() => {
        form.setFieldsValue({ ...data, company: data.company?.name });
    }, [data]);

    const updateProfile = async (req) => {
        try {
            await dispatch(
                updateCurrentProfile({
                    avatar: req.avatar,
                    name: req.name,
                    bio: req.bio,
                    email: req.email,
                    phoneNumber: req.phoneNumber,
                    address: req.address
                })
            ).unwrap();

            dispatch(getCurrentProfile());
            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({ name: 'Profile update' });
    };

    const uploadedAvatarHandler = (url) => {
        form.setFieldValue('avatar', url);
    };

    return (
        <Modal
            title={t('profile.updatedForm.title')}
            open={visible}
            onCancel={onClose}
            width={580}
            footer={[
                <Button type="default" onClick={onClose} className="m-1">
                    {t('profile.updatedForm.cancel')}
                </Button>,
                <Button
                    onClick={form.submit}
                    className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    loading={loading}
                >
                    {t('profile.updatedForm.update')}
                </Button>
            ]}
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={updateProfile}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('profile.updatedForm.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('profile.updatedForm.rules.requiredName')
                            }
                        ]}
                    >
                        <Input placeholder={t('profile.updatedForm.name') + '...'} />
                    </Form.Item>
                    <Form.Item label={t('profile.updatedForm.bio')} name="bio">
                        <Input placeholder={t('profile.updatedForm.bio') + '...'} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t('profile.updatedForm.email')}
                        rules={[
                            {
                                type: 'email',
                                message: t('profile.updatedForm.rules.invalidEmail')
                            },
                            {
                                required: true,
                                message: t('profile.updatedForm.rules.requiredEmail')
                            }
                        ]}
                    >
                        <Input
                            placeholder={t('profile.updatedForm.email') + '...'}
                            disabled={true}
                        />
                    </Form.Item>
                    <Form.Item name="company" label={t('profile.updatedForm.company')}>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item label={t('profile.updatedForm.address')} name="address">
                        <Input placeholder={t('profile.updatedForm.address') + '...'} />
                    </Form.Item>

                    <Form.Item
                        name="phoneNumber"
                        label={t('profile.updatedForm.phoneNumber')}
                        rules={[
                            {
                                pattern: '^(0047|\\+47|47)?[2-9]\\d{7}$',
                                message: t('profile.updatedForm.rules.invalidPhoneNumber')
                            }
                        ]}
                    >
                        <Input
                            style={{
                                width: '100%'
                            }}
                            placeholder={t('profile.updatedForm.phoneNumber') + '...'}
                        />
                    </Form.Item>
                    <Form.Item
                        name="avatar"
                        label={t('profile.updatedForm.avatar')}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra="*.png, *.jpg"
                    >
                        <FDUploadImage
                            isAvatar={true}
                            uploadedHandler={uploadedAvatarHandler}
                            url={data.avatar}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

UpdateProfile.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func
};

export default UpdateProfile;
