import axios from 'axios';
import { BASE_MEDIA_API_URL } from 'common/constants';
import qs from 'qs';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const get = (url, params = {}, options = {}) => {
    return instance.get(BASE_MEDIA_API_URL + url, {
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    return Promise.reject(error.response.data.errors || error.response.data.message);
};

instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { get };
