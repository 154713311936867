import { createSlice } from '@reduxjs/toolkit';
import { getMortalityDetail, getMortalityList } from 'redux/thunks';

const initialState = {
    list: { data: [], loading: false },
    detail: { data: {}, loading: false }
};

export const mortalitySlice = createSlice({
    name: 'mortalitySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMortalityList.pending, (state) => {
            state.list.loading = true;
        });
        builder.addCase(getMortalityList.fulfilled, (state, action) => {
            state.list.loading = false;
            state.list.data =
                action.payload?.map((mortality) => ({
                    id: mortality.ids.toString(),
                    ...mortality
                })) || initialState.list.data;
        });
        builder.addCase(getMortalityList.rejected, (state) => {
            state.list.loading = false;
            state.list.data = initialState.list.data;
        });

        builder.addCase(getMortalityDetail.pending, (state) => {
            state.detail.loading = true;
        });
        builder.addCase(getMortalityDetail.fulfilled, (state, action) => {
            state.detail.loading = false;
            state.detail.data = action.payload || initialState.detail.data;
        });
        builder.addCase(getMortalityDetail.rejected, (state) => {
            state.detail.loading = false;
            state.detail.data = initialState.detail.data;
        });
    }
});
