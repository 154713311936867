import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NotFound } from 'pages';
import AnalysisCharts from './AnalysisCharts';
import AnalysisInfo from './AnalysisInfo';
import FilterBar from './FilterBar';
import CorrelationAnalysis from './CorrelationAnalysis';

const StressAnalysis = () => {
    const { showStressAnalysis } = useFlags();

    if (!showStressAnalysis) return <NotFound />;

    return (
        <>
            <FilterBar />
            <AnalysisInfo />
            <AnalysisCharts />
            <CorrelationAnalysis />
        </>
    );
};

export default withPageViewTracking(StressAnalysis, 'Stress analysis');
