import { Button, Col, Row } from 'antd';
import { BUTTON_BLUE_CLASS, FISH_NAMES } from 'common/constants';
import { FDMultipleSelection, FDRangeDate } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getPensSelection,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';
import { appInsights } from 'AppInsights';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const allFishwellFishIds = useSelector((s) => s.fishwellFishIdSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [fishGroupsSelection, setFishGroupsSelection] = useState([]);
    const [filters, setFilters] = useState({
        siteIds: initialFilters.siteIds,
        penIds: initialFilters.penIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate,
        fishNames: initialFilters.fishNames,
        fishGroupIds: initialFilters.fishGroupIds
    });

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
        dispatch(getFishwellFishIdsSelection());
    }, []);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useEffect(() => {
        setFishGroupsSelection(allFishwellFishIds);
    }, [allFishwellFishIds]);

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.penIds;
        let newPensSelections = allPens;

        let selectedFishGroups = filters.fishGroupIds;
        let newFishGroupsSelections = allFishwellFishIds;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );

            newFishGroupsSelections = allFishwellFishIds.filter((fishGroup) =>
                siteIds.includes(fishGroup.siteId)
            );

            selectedFishGroups = filters.fishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
        }

        setPensSelection(newPensSelections);
        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens,
            fishGroupIds: selectedFishGroups
        });
    };

    const handlePensChange = (penIds) => {
        let selectedFishGroups = filters.fishGroupIds;
        let newFishGroupsSelections = allFishwellFishIds;

        if (penIds.length) {
            newFishGroupsSelections = allFishwellFishIds.filter((fishGroup) =>
                penIds.includes(fishGroup.penId)
            );

            selectedFishGroups = filters.fishGroupIds.filter((fishGroupId) =>
                newFishGroupsSelections.some(
                    (fishwellFishIdSelection) => fishwellFishIdSelection.id === fishGroupId
                )
            );
        }

        setFishGroupsSelection(newFishGroupsSelections);
        setFilters({
            ...filters,
            penIds: penIds,
            fishGroupIds: selectedFishGroups
        });
    };

    const handleFishesChange = (fishes) => {
        setFilters({
            ...filters,
            fishNames: fishes
        });
    };

    const handleFishGroupsChange = (fishGroupIds) => {
        setFilters({
            ...filters,
            fishGroupIds: fishGroupIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        dispatch(
            updateFilters({
                siteIds: filters.siteIds,
                penIds: filters.penIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
                fishNames: filters.fishNames,
                fishGroupIds: filters.fishGroupIds
            })
        );

        appInsights.trackEvent({ name: 'Mortality filter apply' });
    };

    return (
        <div className="filter-bar">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.site')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.sitesPlaceholder')}
                        listSelectItem={sitesSelection}
                        onChange={handleSitesChange}
                        value={filters.siteIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.pen')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.pensPlaceholder')}
                        listSelectItem={pensSelection}
                        onChange={handlePensChange}
                        value={filters.penIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.fish')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.fishesPlaceholder')}
                        listSelectItem={FISH_NAMES.map((fish) => ({
                            ...fish,
                            text: t(fish.text)
                        }))}
                        onChange={handleFishesChange}
                        value={filters.fishNames}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('general.filterBar.fishGroups')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.fishGroupsPlaceholder')}
                        listSelectItem={fishGroupsSelection.map((fishGroup) => ({
                            ...fishGroup,
                            text: `${fishGroup.text} - ${fishGroup.penNumber} - ${fishGroup.siteName}`
                        }))}
                        onChange={handleFishGroupsChange}
                        value={filters.fishGroupIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={5}>
                    <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                    <FDRangeDate
                        placeholder={[
                            t('general.filterBar.startDatePlaceholder'),
                            t('general.filterBar.endDatePlaceholder')
                        ]}
                        onChange={handleRangeDateChange}
                        value={[filters.fromDate, filters.toDate]}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3} className="flex items-end">
                    <Button className={BUTTON_BLUE_CLASS} onClick={handleFilterSubmit}>
                        {t('general.filterBar.apply')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
