import { appInsights } from 'AppInsights';
import { Button, Card, Col, Row, Spin, Statistic } from 'antd';
import {
    AGGREGATE_GRAPH,
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    PER_DAY_GRAPH
} from 'common/constants';
import { formatDateTime } from 'common/utils';
import { DualMortalityPercentagesChart } from 'components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getDeadFishMortalityByFishGroupId, updateFilters } from 'redux/thunks';

const DeadFishMortality = ({ fishGroupDetail }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: fishGroupDeadFishMortality, loading } = useSelector(
        (s) => s.fishGroupDeadFishMortality
    );

    const remainingStockCount =
        fishGroupDetail?.totalFishCount -
        (fishGroupDeadFishMortality.counts?.find(
            (item) => item.fishName === fishGroupDetail?.typeOfFish
        )?.totalAmount ?? 0) -
        fishGroupDeadFishMortality.totalOtherDeadFishDetections;

    useEffect(() => {
        dispatch(
            getDeadFishMortalityByFishGroupId({
                id: fishGroupDetail.id,
                filters: { graphType: initialFilters.graphType }
            })
        );
    }, [fishGroupDetail, initialFilters.graphType]);

    const handleGraphTypeChange = (newType) => {
        dispatch(
            updateFilters({
                graphType: newType
            })
        );

        appInsights.trackEvent({
            name: 'FFI Fish mortality chart type change',
            properties: { type: newType }
        });
    };

    return (
        <Spin spinning={loading}>
            <div className="mt-5">
                <h2 className="mb-2">{t('mortalityAnalysis.fishMortality.title')}</h2>

                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={[8, 8]} className="h-full">
                            {fishGroupDeadFishMortality.counts?.map((item) => (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={24}
                                    xl={24}
                                    key={item.fishName}
                                    className={item.fishName}
                                >
                                    <Card className="flex justify-center content-center h-full card-count">
                                        <Statistic
                                            title={t(`general.fishMortality.${item.fishName}`)}
                                            value={item.totalAmount}
                                            valueStyle={{
                                                color: '#cf1322'
                                            }}
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Card>
                            <div className="flex justify-between">
                                <span className="text-base font-semibold">
                                    {t('fishGroup.remainingStockCount')}: {remainingStockCount}
                                </span>

                                <div>
                                    <Button
                                        className={
                                            initialFilters?.graphType === AGGREGATE_GRAPH
                                                ? BUTTON_BLUE_SELECTED
                                                : BUTTON_BLUE_UN_SELECTED
                                        }
                                        onClick={() => handleGraphTypeChange(AGGREGATE_GRAPH)}
                                    >
                                        {t(
                                            'mortalityAnalysis.fishMortality.mortalityChart.aggregate'
                                        )}
                                    </Button>
                                    &nbsp;
                                    <Button
                                        className={
                                            initialFilters?.graphType === PER_DAY_GRAPH
                                                ? BUTTON_BLUE_SELECTED
                                                : BUTTON_BLUE_UN_SELECTED
                                        }
                                        onClick={() => handleGraphTypeChange(PER_DAY_GRAPH)}
                                    >
                                        {t('mortalityAnalysis.fishMortality.mortalityChart.perDay')}
                                    </Button>
                                </div>
                            </div>

                            <DualMortalityPercentagesChart
                                data={fishGroupDeadFishMortality.charts?.map((item) => ({
                                    type: t(`general.fishMortality.${item.name}`),
                                    value: item.amount,
                                    name: formatDateTime(item.day),
                                    percentages: item.percentages
                                }))}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

DeadFishMortality.propTypes = {
    fishGroupDetail: PropTypes.object.isRequired
};

export default DeadFishMortality;
