import { Switch } from 'antd';
import PropTypes from 'prop-types';
import './Switching.scss';

const FDSwitching = ({
    name,
    checked = false,
    dataTestId,
    onChange = () => {},
    handleSwitchChange = () => {}
}) => {
    const onClick = () => {
        handleSwitchChange(name);
    };

    const handleOnchange = (checked) => {
        onChange(checked);
    };

    return (
        <Switch
            checkedChildren={name}
            unCheckedChildren={name}
            className="font-medium switch"
            onClick={onClick}
            checked={checked}
            onChange={handleOnchange}
            data-testid={dataTestId}
        />
    );
};

FDSwitching.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    dataTestId: PropTypes.string,
    onChange: PropTypes.func,
    handleSwitchChange: PropTypes.func
};

export default FDSwitching;
