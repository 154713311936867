import { AreaChartOutlined, CalculatorOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ENGINE_COUNTING_URL } from 'common/constants';
import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import './index.scss';

const Opening = () => {
    const { countFishSwitch } = useFlags();

    return (
        <div className="opening-page bg-inherit">
            <div className="content">
                <div className="welcome absolute w-full">
                    <h1 className="xs:text-2xl sm:text-3xl md:text-4xl text-white text-center">
                        Welcome to Fishwell Detection
                    </h1>
                </div>
                <div className="option flex justify-center items-center h-full">
                    {countFishSwitch && (
                        <Link to={ENGINE_COUNTING_URL}>
                            <Button
                                className="xs:px-2 xs:py-4 xs:mx-2 xs:text-lg sm:px-10 sm:py-6 sm:mx-16 sm:text-xl bg-transparent text-white rounded-lg font-semibold border-solid
                                border-white hover:bg-white hover:text-sky-900 cursor-pointer flex justify-center items-center align-middle transition"
                            >
                                <CalculatorOutlined />
                                &nbsp;
                                <span>Count Fish</span>
                            </Button>
                        </Link>
                    )}

                    <Link to="/">
                        <Button
                            className="xs:px-2 xs:py-4 xs:mx-2 xs:text-lg sm:px-10 sm:py-6 sm:mx-16 sm:text-xl bg-transparent text-white rounded-lg font-semibold border-solid 
                                border-white hover:bg-white hover:text-sky-900 cursor-pointer flex justify-center items-center align-middle transition"
                        >
                            <AreaChartOutlined />
                            &nbsp;
                            <span>Analysis</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default withPageViewTracking(Opening, 'Opening');
