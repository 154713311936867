import { createSlice } from '@reduxjs/toolkit';
import { addEventNote, getEventNotesByEventId, pushEventNote } from 'redux/thunks';

const initialState = {
    data: [],
    isWaitingEventNote: false, // status of waiting for new message from event note hub
    loading: false
};

export const eventNoteSlice = createSlice({
    name: 'eventNote',
    initialState,
    reducers: {},
    extraReducers: {
        [getEventNotesByEventId.pending]: (state) => {
            state.loading = true;
        },
        [getEventNotesByEventId.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getEventNotesByEventId.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [addEventNote.pending]: (state) => {
            state.isWaitingEventNote = true;
        },
        [addEventNote.fulfilled]: (state) => {
            state.isWaitingEventNote = false;
        },
        [addEventNote.rejected]: (state) => {
            state.isWaitingEventNote = false;
        },

        [pushEventNote.pending]: (state) => {
            state.isWaitingEventNote = true;
        },
        [pushEventNote.fulfilled]: (state, action) => {
            state.isWaitingEventNote = false;
            state.data = [...state.data, action.payload];
        },
        [pushEventNote.rejected]: (state) => {
            state.isWaitingEventNote = false;
        }
    }
});
