import { createSlice } from '@reduxjs/toolkit';
import { getDeadFishMortalityByFishGroupId } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const fishGroupDeadFishMortalitySlice = createSlice({
    name: 'fishGroupDeadFishMortality',
    initialState,
    reducers: {},
    extraReducers: {
        [getDeadFishMortalityByFishGroupId.pending]: (state) => {
            state.loading = true;
        },
        [getDeadFishMortalityByFishGroupId.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getDeadFishMortalityByFishGroupId.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
