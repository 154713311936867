import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import { getEngineStartStopSiteList } from 'redux/thunks';
import Content from './Content';

const EngineCounting = () => {
    const dispatch = useAppDispatch();
    const { countFishSwitch } = useFlags();

    useEffect(() => {
        countFishSwitch && dispatch(getEngineStartStopSiteList({}));
    }, [dispatch, countFishSwitch]);

    return <>{countFishSwitch && <Content />}</>;
};

export default withPageViewTracking(EngineCounting, 'Engine counting');
