import { Button, Card } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_SELECTED, BUTTON_BLUE_UN_SELECTED, EFFICIENCY_TOGGLE } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { flatMap } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelector, eventDetailStartTimeSelector } from 'redux/selector';

const NumberOfFish = ({ sliderValues }) => {
    const { t } = useTranslation();

    const channels = useSelector(channelsSelector);
    const eventStartTime = useSelector(eventDetailStartTimeSelector);
    const [toggleEfficiencyState, setToggleEfficiencyState] = useState(
        EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
    );

    const [numberOfFishChartData, setNumberOfFish] = useState([]);
    const [tonPerHourChartData, setTonPerHourChartData] = useState([]);

    useEffect(() => {
        const filteredNumberFishData = flatMap(channels, (channel) => {
            var previousNumberFish;
            return channel.numberFishes
                ?.map((numberFish) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        eventStartTime,
                        numberFish.stop_time
                    );

                    const newNumberFish = {
                        type: t('eventDetail.channel') + ' ' + channel.channelName,
                        name: formatSeconds(differenceInSeconds),
                        differenceInSeconds: differenceInSeconds,
                        value:
                            numberFish.value &&
                            previousNumberFish &&
                            numberFish.value > previousNumberFish
                                ? numberFish.value - previousNumberFish
                                : 0
                    };
                    previousNumberFish = numberFish.value;

                    return newNumberFish;
                })
                .filter((numberFish) =>
                    isTimeWithinSliderValues(sliderValues, numberFish.differenceInSeconds)
                );
        });

        const filteredTonPerHourData = flatMap(channels, (channel) => {
            return channel.tonPerHours
                ?.map((tonPerHour) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        eventStartTime,
                        tonPerHour.stop_time
                    );

                    const newTonPerHour = {
                        type: t('eventDetail.channel') + ' ' + channel.channelName,
                        name: formatSeconds(differenceInSeconds),
                        differenceInSeconds: differenceInSeconds,
                        value: tonPerHour.value < 0 ? 0 : tonPerHour.value
                    };

                    return newTonPerHour;
                })
                .filter((numberFish) =>
                    isTimeWithinSliderValues(sliderValues, numberFish.differenceInSeconds)
                );
        });
        setNumberOfFish(filteredNumberFishData ?? []);
        setTonPerHourChartData(filteredTonPerHourData ?? []);
    }, [sliderValues]);

    const handleToggleStateChange = (state) => {
        setToggleEfficiencyState(state);
        appInsights.trackEvent({
            name: 'Event efficiency toggle state change',
            properties: { filterBy: state }
        });
    };

    return (
        <Card className="mt-2">
            <div className="flex justify-between">
                <p className="font-medium text-base">{t('eventDetail.efficiency')}</p>
                <div className="flex justify-end">
                    <Button
                        className={
                            toggleEfficiencyState === EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                                ? BUTTON_BLUE_SELECTED
                                : BUTTON_BLUE_UN_SELECTED
                        }
                        onClick={() =>
                            handleToggleStateChange(EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS)
                        }
                        style={{ marginRight: '4px' }}
                    >
                        {t('eventDetail.fishPer30Seconds')}
                    </Button>

                    <Button
                        className={
                            toggleEfficiencyState === EFFICIENCY_TOGGLE.TON_PER_HOUR
                                ? BUTTON_BLUE_SELECTED
                                : BUTTON_BLUE_UN_SELECTED
                        }
                        onClick={() => handleToggleStateChange(EFFICIENCY_TOGGLE.TON_PER_HOUR)}
                    >
                        {t('eventDetail.tonPerHour')}
                    </Button>
                </div>
            </div>
            <FDLine
                data={
                    toggleEfficiencyState === EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                        ? numberOfFishChartData
                        : tonPerHourChartData
                }
                height={300}
                chartConfig={{ animation: false }}
            />
        </Card>
    );
};

NumberOfFish.propTypes = {
    sliderValues: PropTypes.array
};

export default NumberOfFish;
