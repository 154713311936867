import axios from 'axios';
import { DEAD_FISH_COUNTING_ENGINE_API_KEY, ENGINE_BASE_URL_KEY } from 'common/constants';
import qs from 'qs';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        'X-API-Key': DEAD_FISH_COUNTING_ENGINE_API_KEY
    }
};

const get = (url, params = {}, options = {}) => {
    const engineBaseUrl = localStorage.getItem(ENGINE_BASE_URL_KEY);
    if (!engineBaseUrl) return;

    return instance.get(`${engineBaseUrl}${url}`, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    return Promise.reject(error.response.data.detail);
};

instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { get };
