import { appInsights } from 'AppInsights';
import { Button, Form, Input, Modal, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_UPDATE_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { addPen, getSitesSelection, updatePen } from 'redux/thunks';

const PenForm = ({ open = false, pen, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.pen.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);

    const isUpdateForm = !!pen;

    useEffect(() => {
        dispatch(getSitesSelection());
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(pen);
        } else {
            form.resetFields();
        }
    }, [pen]);

    const createOrUpdatePen = (req) => {
        if (isUpdateForm) {
            handleUpdatePen(req);
        } else {
            handleCreatePen(req);
        }
    };

    const handleCreatePen = async (req) => {
        try {
            await dispatch(addPen(req)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_CREATION_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Pen create',
            properties: { penNumber: req.penNumber }
        });
    };

    const handleUpdatePen = async (req) => {
        try {
            await dispatch(updatePen({ id: pen.id, item: req })).unwrap();

            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Pen update',
            properties: { penNumber: req.penNumber }
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={isUpdateForm ? t('pen.penForm.editPen') : t('pen.penForm.createPen')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS}>
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdatePen}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input placeholder="id..." />
                    </Form.Item>

                    <Form.Item
                        label={t('pen.penForm.penNumber.title')}
                        name="penNumber"
                        rules={[
                            {
                                required: true,
                                message: t('pen.penForm.penNumber.required')
                            }
                        ]}
                    >
                        <Input
                            placeholder={t('pen.penForm.penNumber.placeholder')}
                            disabled={isUpdateForm}
                        />
                    </Form.Item>

                    <Form.Item label={t('general.form.description.title')} name="description">
                        <TextArea placeholder={t('general.form.description.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.site.title')}
                        name="siteId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.site.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            disabled={isUpdateForm}
                            dataTestId="organization-option"
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

PenForm.propTypes = {
    open: PropTypes.bool,
    pen: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default PenForm;
