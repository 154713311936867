import { getRandomBoolean } from 'common/utils';
import { FDFishMortality, FDGeneralInfo, FDItemList } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getDeadFishDailyCounting, getSiteDetail, getStockCount } from 'redux/thunks';

const SiteDetail = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { siteId } = useParams();

    const { data: site, loading } = useSelector((s) => s.siteDetail);

    const viewedPens = JSON.parse(sessionStorage.getItem('viewedPens')) || [];

    useEffect(() => {
        dispatch(getSiteDetail(siteId));

        dispatch(
            getDeadFishDailyCounting({
                siteIds: [siteId]
            })
        );

        dispatch(
            getStockCount({
                siteIds: [siteId]
            })
        );
    }, [siteId]);

    const infoList = [
        {
            key: t('siteDetail.farmerCompany'),
            value: site.company?.name
        },
        {
            key: t('siteDetail.position'),
            value: site.latitude && site.longitude && `${site.latitude} , ${site.longitude}`
        },
        {
            key: t('siteDetail.localityNumber'),
            value: site.localityNumber
        },
        {
            key: t('siteDetail.siteName'),
            value: site.siteName
        },
        {
            key: t('siteDetail.municipality'),
            value: site.municipality
        },
        {
            key: t('siteDetail.capacity'),
            value: site.capacity
        }
    ];

    return (
        <>
            <h2 className="mb-2">{t('siteDetail.title')}</h2>
            <FDGeneralInfo
                siteId={site.id}
                loading={loading}
                logo={site.company?.logoUrl}
                infoList={infoList}
                status={site.status}
            />

            <FDFishMortality />

            <div className="mt-5">
                <FDItemList
                    loading={loading}
                    title={t('siteDetail.pensOfThisSite')}
                    itemList={site.pens?.map((item) => ({
                        ...item,
                        name: item.penNumber,
                        link: `../pen-detail/${item.id}`,
                        hasViewed: viewedPens.includes(item.id),
                        hasNotification: getRandomBoolean()
                    }))}
                />
            </div>
        </>
    );
};

export default withPageViewTracking(SiteDetail, 'Site detail');
