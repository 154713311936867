import { Scatter } from '@ant-design/plots';
import { BLACK, BLUE, GREEN, RANGE_AREA_GREEN } from 'common/constants';
import PropTypes from 'prop-types';

const FDScatter = ({
    data = [],
    height,
    xAxisTitle,
    yAxisTitle,
    xField = 'x',
    yField = 'y',
    xAxisFormatter = (value) => value,
    yAxisFormatter = (value) => value
}) => {
    const config = {
        data,
        height,
        xField,
        yField,
        size: 5,
        pointStyle: {
            stroke: GREEN,
            lineWidth: 1,
            fill: RANGE_AREA_GREEN
        },
        regressionLine: {
            type: 'linear', // linear, exp, loess, log, poly, pow, quad
            style: {
                stroke: BLUE,
                opacity: 1
            }
        },
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: BLACK
                },
                formatter: (value) => xAxisFormatter(value)
            },
            title: {
                text: xAxisTitle,
                style: {
                    fontSize: 12,
                    fill: BLACK
                }
            }
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: BLACK
                },
                formatter: (value) => yAxisFormatter(value)
            },
            title: {
                text: yAxisTitle,
                style: {
                    fontSize: 12,
                    fill: BLACK
                }
            }
        },
        tooltip: {
            customItems: (items) => {
                return items.map((item) => ({
                    ...item,
                    color: RANGE_AREA_GREEN,
                    name: item.name === xField ? xAxisTitle : yAxisTitle,
                    value:
                        item.name === xField
                            ? xAxisFormatter(item.value)
                            : yAxisFormatter(item.value)
                }));
            }
        }
    };

    return <Scatter {...config} />;
};

FDScatter.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    xField: PropTypes.string,
    yField: PropTypes.string,
    xAxisFormatter: PropTypes.func,
    yAxisFormatter: PropTypes.func
};

export default FDScatter;
