import { useFlags } from 'launchdarkly-react-client-sdk';
import { NotFound } from 'pages';
import FilterBar from './FilterBar';
import ReportTable from './ReportTable';

const Flow = () => {
    const { showFlow } = useFlags();

    if (!showFlow) return <NotFound />;

    return (
        <>
            <FilterBar />
            <ReportTable />
        </>
    );
};

export default Flow;
