import { SelectOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { ENGINE_BASE_URL_KEY, ENGINE_BY_LOCALITY_NO_API } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ENGINE_SERVICE, GLOBAL_API } from 'services';
import PenList from './PenList';

const CountingForm = ({ selectedSite, setSelectedSite = () => {}, onStartCounting = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { data: sitesSelection, loading: sitesSelectionLoading } = useSelector(
        (s) => s.engineSite
    );

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAllowChoosePen, setIsAllowChoosePen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        if (selectedSite) {
            form.setFieldsValue(selectedSite);
            updateEngineBaseUrl(selectedSite);
        }
    }, [selectedSite]);

    const handleSiteChange = (siteId) => {
        const site = sitesSelection.find((site) => site.id === siteId);
        setSelectedSite(site);
    };

    const handlePenListClose = () => {
        setIsModalVisible(false);
    };

    const handlePenListShow = () => {
        setIsModalVisible(true);
    };

    const handleStartCounting = (pen) => {
        onStartCounting(pen, handlePenListClose);
    };

    const updateEngineBaseUrl = async (site) => {
        try {
            setButtonLoading(true);
            const res = await GLOBAL_API.get(`${ENGINE_BY_LOCALITY_NO_API}/${site.localityNumber}`);
            const configuration = JSON.parse(res.data.configurationJson);
            const engineBaseUrl = configuration[ENGINE_BASE_URL_KEY];

            if (engineBaseUrl) {
                ENGINE_SERVICE.saveEngineBaseUrl(engineBaseUrl);
                setIsAllowChoosePen(true);
            } else {
                ENGINE_SERVICE.removeEngineBaseUrl();
                alertErrorMessage('message.engineNotConfiguredBaseUrl');
                setIsAllowChoosePen(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsAllowChoosePen(false);
            ENGINE_SERVICE.removeEngineBaseUrl();
        } finally {
            setButtonLoading(false);
        }
    };

    return (
        <div className="site-selection title" data-testid="counting-form">
            <div className="text-center text-gray-700 font-semibold text-lg">
                {t('countingPage.countingForm.title')}
            </div>

            <Form
                onFinish={handlePenListShow}
                className="flex justify-center items-center flex-col py-4"
                form={form}
            >
                <Form.Item
                    label={t('countingPage.countingForm.siteLabel')}
                    name="id"
                    rules={[
                        { required: true, message: t('countingPage.countingForm.requiredSite') }
                    ]}
                    className="w-72 font-semibold"
                >
                    <FDSelection
                        listSelectItem={sitesSelection.map((site) => ({
                            ...site,
                            text: site.hasEngineConfig
                                ? site.text
                                : `${site.text} ${t(
                                      'countingPage.countingForm.engineNotConfigured'
                                  )}`,
                            disabled: !site.hasEngineConfig
                        }))}
                        placeholder={t('countingPage.countingForm.sitePlaceholder')}
                        className="w-full text-base"
                        value={selectedSite?.id}
                        onChange={handleSiteChange}
                        dataTestId="site-option"
                        loading={sitesSelectionLoading}
                        disabled={sitesSelectionLoading}
                    />
                </Form.Item>

                <div className="start-counting flex flex-col justify-center items-center py-4">
                    <Button
                        onClick={() => form.submit()}
                        className="bg-sky-700 focus-within:bg-sky-700 text-white hover:bg-sky-800 hover:text-white focus-within:text-white text-xl font-semibold 
                            py-6 px-6 rounded border mb-2 flex justify-center items-center show-modal-btn"
                        disabled={!isAllowChoosePen}
                        loading={buttonLoading}
                        icon={<SelectOutlined />}
                    >
                        {t('countingPage.countingForm.choosePen')}
                    </Button>
                </div>
            </Form>

            <PenList
                visible={isModalVisible}
                pens={selectedSite?.pens}
                onClose={handlePenListClose}
                onStartCounting={handleStartCounting}
            />
        </div>
    );
};

CountingForm.propTypes = {
    selectedSite: PropTypes.object,
    setSelectedSite: PropTypes.func,
    onStartCounting: PropTypes.func
};

export default CountingForm;
