import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'antd';
import { useTranslation } from 'react-i18next';

const FDTree = ({
    checkable = true,
    treeData = [],
    checkedKeys = [],
    className = '',
    onCheck = () => {}
}) => {
    const { t } = useTranslation();
    const SELECT_ALL_KEY = 'select-all-key';

    const customTreeData = checkable
        ? [
              {
                  title: t('button.selectAll'),
                  key: SELECT_ALL_KEY,
                  children: treeData
              }
          ]
        : treeData;

    const handleTreeItemCheck = (checkedKeys) => {
        onCheck(checkedKeys.filter((key) => key !== SELECT_ALL_KEY));
    };

    return (
        <Tree
            checkable={checkable}
            defaultExpandedKeys={[SELECT_ALL_KEY]}
            onCheck={handleTreeItemCheck}
            checkedKeys={checkedKeys}
            treeData={customTreeData}
            className={className}
            showIcon={true}
        />
    );
};

FDTree.propTypes = {
    checkable: PropTypes.bool,
    selectAll: PropTypes.bool,
    treeData: PropTypes.array,
    checkedKeys: PropTypes.array,
    className: PropTypes.string,
    onCheck: PropTypes.func
};
export default FDTree;
