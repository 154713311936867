import { createAsyncThunk } from '@reduxjs/toolkit';
import { FISH_GROUP_API } from 'common/constants';
import { API } from 'services';

export const getFishGroups = createAsyncThunk(
    'fishGroups/getFishGroups',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(FISH_GROUP_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addFishGroup = createAsyncThunk(
    'fishGroups/addFishGroup',
    async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(FISH_GROUP_API, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const divideFishGroup = createAsyncThunk(
    'fishGroups/divideFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}/divide`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const moveFishGroup = createAsyncThunk(
    'fishGroups/moveFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}/move`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const releaseFishGroup = createAsyncThunk(
    'fishGroups/releaseFishGroup',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${FISH_GROUP_API}/${id}/release`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteFishGroup = createAsyncThunk(
    'fishGroups/deleteFishGroup',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${FISH_GROUP_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getDeadFishMortalityByFishGroupId = createAsyncThunk(
    'fishGroups/getDeadFishMortalityByFishGroupId',
    async ({ id, filters }, { rejectWithValue }) => {
        try {
            const { data } = await API.get(
                `${FISH_GROUP_API}/${id}/dead-fish-counting-mortality`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getReportTableByFishGroupId = createAsyncThunk(
    'fishGroups/getReportTableByFishGroupId',
    async ({ id, filters }, { rejectWithValue }) => {
        try {
            const { data } = await API.get(
                `${FISH_GROUP_API}/${id}/dead-fish-counting-report`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishGroupTreeByFishwellFishGroupId = createAsyncThunk(
    'fishGroups/getFishGroupTreeByFishwellFishGroupId',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${FISH_GROUP_API}/tree/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEventListByFishGroupId = createAsyncThunk(
    'fishGroups/getEventListByFishGroupId',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${FISH_GROUP_API}/${id}/events`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishHealthByFishGroupId = createAsyncThunk(
    'fishGroups/getFishHealthByFishGroupId',
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${FISH_GROUP_API}/${id}/fish-health`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
