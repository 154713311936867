import React, { useEffect } from 'react';
import FilterProcesses from './FilterProcesses';
import ProcessInfo from './ProcessInfo';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import StressZone from './StressZone';
import { useAppDispatch } from 'redux/store';
import {
    getProcessFlowDetection,
    getProcessStressEvent,
    getProcessWelfareScore
} from 'redux/thunks';
import Flow from './Flow';
import WelfareScore from './WelfareScore';

const Process = () => {
    const dispatch = useAppDispatch();
    const { selectedProcessId } = useSelector((s) => s.filter.data);

    useEffect(() => {
        if (selectedProcessId) {
            dispatch(getProcessStressEvent(selectedProcessId));
            dispatch(getProcessFlowDetection(selectedProcessId));
            dispatch(getProcessWelfareScore(selectedProcessId));
        }
    }, [selectedProcessId]);

    return (
        <>
            <FilterProcesses />
            {selectedProcessId && (
                <>
                    <ProcessInfo />
                    <Row gutter={8}>
                        <Col xs={24} xl={12}>
                            <StressZone />
                        </Col>
                        <Col xs={24} xl={12}>
                            <Flow />
                        </Col>
                        <Col xs={24}>
                            <WelfareScore />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default Process;
