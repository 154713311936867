import { createSlice } from '@reduxjs/toolkit';
import { getServices } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {},
    extraReducers: {
        [getServices.pending]: (state) => {
            state.loading = true;
        },
        [getServices.fulfilled]: (state, action) => {
            state.loading = false;
            state.data =
                action.payload.filter(
                    (service) => service.isUsed && service.status === 'Released'
                ) || initialState.data;
        },
        [getServices.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
