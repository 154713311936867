import { EyeOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS, DATE_TIME_FORMAT, STRESS_EVENT_PAGE } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDTable } from 'components';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getEventList, updateFilters } from 'redux/thunks';

const TableEventList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: events, loading } = useSelector((s) => s.eventList);

    useEffect(() => {
        dispatch(
            getEventList({
                serviceBoatIds: initialFilters.serviceBoatIds,
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );
    }, [
        initialFilters.serviceBoatIds,
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    const handleEventClick = async (eventId) => {
        await dispatch(
            updateFilters({
                selectedStressEventId: eventId,
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds
            })
        ).unwrap();

        navigate(STRESS_EVENT_PAGE, { state: { navigatedEventId: eventId } });

        appInsights.trackEvent({
            name: 'Stress event detail button click',
            properties: { eventId }
        });
    };

    const COLUMNS_FOR_EVENT_LIST = [
        {
            title: 'general.table.site',
            dataIndex: 'site'
        },
        {
            title: 'general.table.pen',
            dataIndex: 'pen'
        },
        {
            title: 'general.table.boat',
            dataIndex: 'boat'
        },
        {
            title: 'general.table.boatCompany',
            dataIndex: 'ownerOrganizationName'
        },
        {
            title: 'general.table.startTime',
            dataIndex: 'startTime',
            render: (startTime) => formatDateTime(startTime, DATE_TIME_FORMAT),
            sorter: {
                compare: (a, b) => moment(a.startTime) - moment(b.startTime),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'general.table.stopTime',
            dataIndex: 'stopTime',
            render: (stopTime) => formatDateTime(stopTime, DATE_TIME_FORMAT),
            sorter: {
                compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_BLUE_CLASS}
                        onClick={() => {
                            handleEventClick(record.id);
                        }}
                    >
                        <EyeOutlined />
                        <span>{t('stressEventList.detail')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('stressEventList.events')}</h2>
            <FDTable headers={COLUMNS_FOR_EVENT_LIST} data={events} loading={loading} />
        </div>
    );
};

export default TableEventList;
