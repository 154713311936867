import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { appInsights } from 'AppInsights';
import { FILE_EXPORT_FORMAT_DATE } from 'common/constants';
import { exportZipOfImages } from 'common/utils';
import { WoundAndSizeAttachment } from 'components';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mortalityAttachmentSelector } from 'redux/selector';

const WoundAttachment = () => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);
    const mortalityAttachment = useSelector(mortalityAttachmentSelector);

    const fishAttribute = {
        wound: 'wound',
        woundOnGill: 'wound_on_gill',
        woundOnMouth: 'wound_on_mouth',
        woundOnBack: 'wound_on_back',
        woundOnBelly: 'wound_on_belly',
        woundOnTail: 'wound_on_tail',
        fillet: 'fillet',
        looserFish: 'looser_fish',
        noWound: 'no_wound'
    };

    const downloadAttachments = async () => {
        if (!mortalityAttachment) return;
        setIsDownloading(true);

        const folders = Object.entries(fishAttribute).map(([key, value]) => ({
            name: t(`general.fishAttribute.${key}`),
            imageUrls: mortalityAttachment[value]
        }));
        const fileName = `${t('mortality.woundAndSizeImages')} - ${moment().format(
            FILE_EXPORT_FORMAT_DATE
        )}`;

        await exportZipOfImages(folders, fileName);

        setIsDownloading(false);
        appInsights.trackEvent({ name: 'Wound and size images download' });
    };

    return (
        <>
            <div className="mt-5 mb-2 flex justify-between items-center">
                <h2 className="mb-0">{t('mortality.woundAndSizeImages')}</h2>
                <Button
                    onClick={() => downloadAttachments()}
                    icon={<DownloadOutlined />}
                    loading={isDownloading}
                >
                    {t('button.download')}
                </Button>
            </div>
            <Card>
                <Row gutter={16}>
                    {Object.entries(fishAttribute).map(([key, value]) => (
                        <Col className="mb-2" key={key} xs={24} sm={12} lg={8}>
                            <span className="font-medium text-base">
                                {t(`general.fishAttribute.${key}`)}
                            </span>
                            <WoundAndSizeAttachment
                                attachments={mortalityAttachment?.[value]}
                                limit={mortalityAttachment?.[value]?.length}
                            />
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    );
};

export default WoundAttachment;
