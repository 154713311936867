import { appInsights } from 'AppInsights';
import { Button, DatePicker, Form, InputNumber, Modal, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    FISH_NAMES,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_UPDATE_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDSelection } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    addOtherDeadFishCount,
    getPensSelection,
    getSitesSelection,
    updateOtherDeadFishCount
} from 'redux/thunks';

const OtherDeadFishCountForm = ({
    open = false,
    otherDeadFishCount,
    onClose = () => {},
    onReload = () => {}
}) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.otherDeadFishCount.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);

    const siteId = Form.useWatch('siteId', form);

    const isUpdateForm = !!otherDeadFishCount;

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue({
                ...otherDeadFishCount,
                stopCountingTime: moment(otherDeadFishCount.stopCountingTime)
            });
        } else {
            form.resetFields();
        }
    }, [otherDeadFishCount]);

    const createOrUpdateOtherDeadFishCount = (req) => {
        req['startCountingTime'] = req['stopCountingTime'];

        if (isUpdateForm) {
            handleUpdateOtherDeadFishCount(req);
        } else {
            handleCreateOtherDeadFishCount(req);
        }
    };

    const handleCreateOtherDeadFishCount = async (req) => {
        try {
            await dispatch(addOtherDeadFishCount(req)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_CREATION_MESSAGE);
            form.resetFields();
            onClose();
            onReload();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'OtherDeadFishCount create',
            properties: { otherDeadFishCount: req }
        });
    };

    const handleUpdateOtherDeadFishCount = async (req) => {
        try {
            await dispatch(
                updateOtherDeadFishCount({ id: otherDeadFishCount.id, item: req })
            ).unwrap();

            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
            onReload();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'OtherDeadFishCount update',
            properties: { otherDeadFishCountId: otherDeadFishCount.id }
        });
    };

    const handleSiteChange = (siteId, site) => {
        if (!site?.pens?.some((pen) => pen.id === form.getFieldsValue().penId)) {
            form.setFieldValue('penId', null);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={
                isUpdateForm
                    ? t('otherDeadFishCount.upsertForm.editOtherDeadFishCount')
                    : t('otherDeadFishCount.upsertForm.createOtherDeadFishCount')
            }
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS}>
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={createOrUpdateOtherDeadFishCount}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('otherDeadFishCount.upsertForm.fishName.title')}
                        name="fishName"
                        rules={[
                            {
                                required: true,
                                message: t('otherDeadFishCount.upsertForm.fishName.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('otherDeadFishCount.upsertForm.fishName.placeholder')}
                            listSelectItem={FISH_NAMES.map((type) => ({
                                ...type,
                                text: t(type.text)
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('otherDeadFishCount.upsertForm.count.title')}
                        name="count"
                        rules={[
                            {
                                required: true,
                                message: t('otherDeadFishCount.upsertForm.count.required')
                            }
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            placeholder={t('otherDeadFishCount.upsertForm.count.placeholder')}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('otherDeadFishCount.upsertForm.stopCountingTime.title')}
                        name="stopCountingTime"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'otherDeadFishCount.upsertForm.stopCountingTime.required'
                                )
                            }
                        ]}
                    >
                        <DatePicker
                            placeholder={t(
                                'otherDeadFishCount.upsertForm.stopCountingTime.placeholder'
                            )}
                        />
                    </Form.Item>

                    <Form.Item label={t('general.form.site.title')} name="siteId">
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSiteChange}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => !siteId || pen.siteId === siteId
                            )}
                            onChange={(penId, pen) => form.setFieldValue('siteId', pen?.siteId)}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item label={t('general.form.description.title')} name="description">
                        <TextArea placeholder={t('general.form.description.placeholder')} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

OtherDeadFishCountForm.propTypes = {
    open: PropTypes.bool,
    otherDeadFishCount: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default OtherDeadFishCountForm;
