import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    STRESS_ANALYSIS_CORRELATION_TYPE_API,
    STRESS_ANALYSIS_FOR_CORRELATION_API,
    STRESS_ANALYSIS_FOR_REGRESSION_API
} from 'common/constants';
import { API } from 'services';

export const getStressAnalysisCorrelationTypes = createAsyncThunk(
    'correlationAnalyses/correlationTypes',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_CORRELATION_TYPE_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForRegression = createAsyncThunk(
    'correlationAnalyses/regression',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_REGRESSION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressAnalysisForCorrelation = createAsyncThunk(
    'correlationAnalyses/correlation',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(STRESS_ANALYSIS_FOR_CORRELATION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
