import { FDDialogBox, FDSwitching } from 'components';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import propTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const StreamingVideo = ({ isEventActive = true, streamingVideoUrl = '' }) => {
    const { t } = useTranslation();
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isEventActive);

    const [isVisible, setIsVisible] = useState(false);

    const handleIsVisibleChange = (value) => {
        setIsVisible(value);
    };

    const handleConfirmNavigation = () => {
        setIsVisible(false);
        confirmNavigation();
    };

    return (
        <div className="flex flex-col justify-center items-center streaming-video">
            <FDSwitching
                checked={isVisible}
                onChange={handleIsVisibleChange}
                name={t('button.video')}
            />

            <img
                src={isVisible ? streamingVideoUrl : null}
                alt="streaming-video"
                className={`shadow-lg object-contain mt-5 xs:w-full sm:w-4/5 lg:w-3/5 xxl:w-2/5 ${
                    isVisible ? 'block' : 'hidden'
                }`}
            />

            <FDDialogBox
                showDialog={showPrompt}
                confirmNavigation={handleConfirmNavigation}
                cancelNavigation={cancelNavigation}
                message={t('dialogBox.streamingConfirmMessage')}
            />
        </div>
    );
};

StreamingVideo.propTypes = {
    isEventActive: propTypes.bool,
    streamingVideoUrl: propTypes.string
};

export default memo(StreamingVideo);
