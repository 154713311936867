import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import Search from 'antd/es/input/Search';
import defaultAvatar from 'assets/Default_Avatar.png';
import { BUTTON_GRAY_CLASS, Title } from 'common/constants';
import { FDTable } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getUsers } from 'redux/thunks';
import UserForm from './UserForm';
import { appInsights } from 'AppInsights';

const User = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: users, loading } = useSelector((s) => s.user);

    const [searchText, setSearchText] = useState('');
    const [modalState, setModalState] = useState({ open: false, user: null });

    const columns = [
        {
            title: 'user.table.name',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            src={record.avatar || defaultAvatar}
                            size={55}
                            alt=""
                        />
                        <div className="avatar-info">
                            <div className="avatar-info">
                                <Title level={5}>&nbsp; {record.name}</Title>
                                <p>&nbsp; {record.email || 'Email address'}</p>
                            </div>
                        </div>
                    </Avatar.Group>
                </>
            )
        },
        {
            title: 'user.table.company',
            dataIndex: 'company',
            render: (_, record) => record.company?.name
        },
        {
            title: 'user.table.phoneNumber',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'user.table.address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => setModalState({ open: true, user: record })}
                    >
                        <EditOutlined />
                        <span>{t('user.userForm.assign.title')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const isMatchSearchText = (str) => new RegExp(searchText, 'i').test(str);

    const handleSearch = (searchText) => {
        setSearchText(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

        appInsights.trackEvent({
            name: 'User search',
            properties: { searchText }
        });
    };

    return (
        <div className="user-management">
            <h2 className="mb-2">{t('user.title')}</h2>

            <div>
                <div className="mb-1">
                    <Search
                        placeholder={t('user.searchPlaceholder')}
                        style={{ width: 200 }}
                        onSearch={(searchText) => handleSearch(searchText)}
                    />
                </div>

                <FDTable
                    headers={columns}
                    data={users.filter((user) => isMatchSearchText(user.name))}
                    loading={loading}
                    rowKey="id"
                />
            </div>

            <UserForm
                open={modalState.open}
                user={modalState.user}
                onClose={() => setModalState({ open: false, user: null })}
            />
        </div>
    );
};

export default withPageViewTracking(User, 'User management');
