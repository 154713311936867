import { AbilityContext } from 'casl';
import { useContext } from 'react';

export default function useAppAbility() {
    const ability = useContext(AbilityContext);
    return {
        can: (action = '', roles = [], conditions = []) => {
            if (!action) return false;

            if (typeof conditions === 'string')
                return ability.can(action, roles, conditions);

            return (conditions || [])
                .map((cd) => ability.can(action, roles, cd))
                .some((s) => s);
        }
    };
}
