import { Card } from 'antd';
import { reduce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    BLUE,
    GRADIENT_CHART_CONFIG,
    LIGHT_GREEN,
    LIGHT_ORANGE,
    LIGHT_RED,
    LIGHT_YELLOW
} from 'common/constants';
import {
    formatSeconds,
    generateGradientColor,
    getDifferenceInSeconds,
    isTimeWithinSliderValues
} from 'common/utils';
import { FDLine } from 'components';
import PropTypes from 'prop-types';
import { eventDetailStartTimeSelector, stressLevelSelector } from 'redux/selector';

const StressLevel = ({ sliderValues }) => {
    const { t } = useTranslation();

    const stressLevels = useSelector(stressLevelSelector);
    const eventStartTime = useSelector(eventDetailStartTimeSelector);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const filteredData = reduce(
            stressLevels,
            (accumulator, stress) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    eventStartTime,
                    stress.stop_time
                );

                if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                    accumulator.push({
                        name: formatSeconds(differenceInSeconds),
                        value: stress.level
                    });

                return accumulator;
            },
            []
        );

        setChartData(filteredData);
    }, [sliderValues]);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.stressLevel')}</p>
            <FDLine
                data={chartData}
                height={300}
                isSingleLine={true}
                color={BLUE}
                chartConfig={GRADIENT_CHART_CONFIG}
                style={{
                    background: generateGradientColor({
                        colors: [LIGHT_RED, LIGHT_ORANGE, LIGHT_YELLOW, LIGHT_GREEN]
                    })
                }}
            />
        </Card>
    );
};

StressLevel.propTypes = {
    sliderValues: PropTypes.array
};

export default StressLevel;
