import { withPageViewTracking } from 'hoc';
import FilterBar from './FilterBar';
import PensComparison from './PensComparison';
import SitesComparison from './SitesComparison';

const Comparison = () => {
    return (
        <div className="comparison">
            <FilterBar />

            <SitesComparison />

            <PensComparison />
        </div>
    );
};

export default withPageViewTracking(Comparison, 'Comparison');
