export const NOTIFICATION_TYPE = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error'
};

export const NOTIFICATION_PLACEMENT = {
    topRight: 'topRight',
    topLeft: 'topLeft',
    bottomRight: 'bottomRight',
    bottomLeft: 'bottomLeft'
};

export const NOTIFICATION_METHOD = {
    hightAverage: 'HIGH_AVERAGE_NOTIFICATION'
};
