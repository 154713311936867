import { Card } from 'antd';
import { FDColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mortalityAttributeSelector } from 'redux/selector';

const WoundCount = () => {
    const { t } = useTranslation();

    const fishAttribute = useSelector(mortalityAttributeSelector);

    const woundType = {
        woundOnGill: 'wound_on_gill',
        woundOnMouth: 'wound_on_mouth',
        woundOnBack: 'wound_on_back',
        woundOnBelly: 'wound_on_belly',
        woundOnTail: 'wound_on_tail'
    };

    var woundCounts = Object.entries(woundType).map(([key, value]) => ({
        name: t(`general.fishAttribute.${key}`),
        type: t(`general.fishAttribute.${key}`),
        value: fishAttribute?.[value]
    }));

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('mortality.typeOfWound')}</p>
            <FDColumn data={woundCounts} height={300} />
        </Card>
    );
};

export default WoundCount;
