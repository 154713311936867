import { createSelector } from '@reduxjs/toolkit';

const processDetailSelector = (state) => state.processDetail;

export const processListSelector = createSelector(processDetailSelector, (process) => process.list);

export const processStressEventSelector = createSelector(
    processDetailSelector,
    (process) => process.stressEvent
);

export const processFlowDetectionSelector = createSelector(
    processDetailSelector,
    (process) => process.flowDetection
);

export const processWelfareScoreSelector = createSelector(
    processDetailSelector,
    (process) => process.welfareScore
);
