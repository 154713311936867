import { Card } from 'antd';
import {
    BLUE,
    LIGHT_GREEN,
    LIGHT_ORANGE,
    LIGHT_RED,
    LIGHT_YELLOW,
    OXYGEN_CHART_CONFIG
} from 'common/constants';
import {
    formatSeconds,
    generateGradientColor,
    getDifferenceInSeconds,
    isTimeWithinSliderValues,
    oxygenGradient
} from 'common/utils';
import { FDLine } from 'components';
import { reduce } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { eventDetailStartTimeSelector, oxygensSelector } from 'redux/selector';

const OxygenLevel = ({ sliderValues }) => {
    const { t } = useTranslation();

    const oxygens = useSelector(oxygensSelector);
    const eventDetailStartTime = useSelector(eventDetailStartTimeSelector);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const filteredData = reduce(
            oxygens,
            (accumulator, oxygen) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    eventDetailStartTime,
                    oxygen.date
                );

                if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                    accumulator.push({
                        name: formatSeconds(differenceInSeconds),
                        value: oxygen.value
                    });

                return accumulator;
            },
            []
        );

        setChartData(filteredData);
    }, [sliderValues]);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.oxygenLevel')}</p>
            <FDLine
                data={chartData}
                height={300}
                isSingleLine={true}
                color={BLUE}
                chartConfig={{
                    ...OXYGEN_CHART_CONFIG,
                    xAxis: { position: 'top' },
                    reflect: 'y'
                }}
                style={{
                    background: generateGradientColor({
                        colors: [LIGHT_RED, LIGHT_ORANGE, LIGHT_YELLOW, LIGHT_GREEN, LIGHT_YELLOW],
                        stops: oxygenGradient.stops
                    })
                }}
            />
        </Card>
    );
};

OxygenLevel.propTypes = {
    sliderValues: PropTypes.array
};

export default OxygenLevel;
