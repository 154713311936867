import { formatDateTime } from 'common/utils';
import moment from 'moment';
import { DATE_TIME_FORMAT } from './format-type';

export const TABLE_COLUMNS = {
    SITE: {
        title: 'general.table.site',
        dataIndex: 'site',
        className: 'table-report-site',
        isDefault: true
    },
    PEN: {
        title: 'general.table.pen',
        dataIndex: 'pen',
        className: 'table-report-pen',
        isDefault: true
    },
    FISH_GROUPS: {
        title: 'general.table.fishGroups',
        dataIndex: 'fishGroups',
        className: 'table-report-fish-groups',
        render: (fishGroups) =>
            fishGroups
                .map((fishGroup) => `${fishGroup.fishGroupId}-${fishGroup.sibling}`)
                .join(', '),
        isDefault: true
    },
    START_TIME: {
        title: 'general.table.startTime',
        dataIndex: 'startTime',
        sorter: {
            compare: (a, b) => moment(a.startTime) - moment(b.startTime),
            multiple: 2
        },
        render: (startTime) => formatDateTime(startTime, DATE_TIME_FORMAT),
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-start-time',
        isDefault: true
    },
    STOP_TIME: {
        title: 'general.table.stopTime',
        dataIndex: 'stopTime',
        className: 'table-report-stop-time',
        render: (stopTime) => formatDateTime(stopTime, DATE_TIME_FORMAT),
        sorter: {
            compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
            multiple: 1
        },
        sortDirections: ['ascend', 'descend'],
        isDefault: true
    },
    DATE: {
        title: 'general.table.date',
        dataIndex: 'stopTime',
        className: 'table-report-date',
        render: (stopTime) => formatDateTime(stopTime),
        sorter: {
            compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
            multiple: 1
        },
        sortDirections: ['ascend', 'descend'],
        isDefault: true
    },
    LAKS: {
        title: 'general.fishMortality.laks',
        dataIndex: 'laks',
        sorter: {
            compare: (a, b) => a.laks - b.laks,
            multiple: 3
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-laks'
    },
    ØRRET: {
        title: 'general.fishMortality.ørret',
        dataIndex: 'ørret',
        sorter: {
            compare: (a, b) => a.ørret - b.ørret,
            multiple: 4
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-ørret'
    },
    BERGGYLT: {
        title: 'general.fishMortality.berggylt',
        dataIndex: 'berggylt',
        sorter: {
            compare: (a, b) => a.berggylt - b.berggylt,
            multiple: 5
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-berggylt'
    },
    ROGNKJEKS: {
        title: 'general.fishMortality.rognkjeks',
        dataIndex: 'rognkjeks',
        sorter: {
            compare: (a, b) => a.rognkjeks - b.rognkjeks,
            multiple: 6
        },
        sortDirections: ['ascend', 'descend'],
        className: 'table-report-rognkjeks'
    }
};

// table columns for report table
export const COLUMNS_FOR_PER_COUNT_REPORT = [
    TABLE_COLUMNS.SITE,
    TABLE_COLUMNS.PEN,
    TABLE_COLUMNS.FISH_GROUPS,
    TABLE_COLUMNS.START_TIME,
    TABLE_COLUMNS.STOP_TIME,
    TABLE_COLUMNS.LAKS,
    TABLE_COLUMNS.ØRRET,
    TABLE_COLUMNS.BERGGYLT,
    TABLE_COLUMNS.ROGNKJEKS
];

export const COLUMNS_FOR_PER_DAY_REPORT = [
    TABLE_COLUMNS.DATE,
    TABLE_COLUMNS.SITE,
    TABLE_COLUMNS.PEN,
    TABLE_COLUMNS.FISH_GROUPS,
    TABLE_COLUMNS.LAKS,
    TABLE_COLUMNS.ØRRET,
    TABLE_COLUMNS.BERGGYLT,
    TABLE_COLUMNS.ROGNKJEKS
];
