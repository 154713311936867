export const GREEN = '#50a06e';
export const BLUE = '#0369a1';
export const RED = '#f44a4a';
export const GRAY = '#334155';
export const YELLOW = '#fbbf24';
export const ORANGE = '#f97316';
export const WHITE = '#fff';
export const SILVER = '#e2e8f0';
export const LIGHT_GREEN = '#01a101';
export const LIGHT_BLUE = '#91d5ff';
export const LIGHT_YELLOW = '#f2ff00d0';
export const LIGHT_ORANGE = '#ffae00';
export const LIGHT_RED = '#FF0000';
export const LIGHT_GRAY = '#64748b';
export const DARK_GREEN = '#456170';
export const RANGE_AREA_GREEN = '#85c5A6';
export const BLACK = '#000';
