export const FISH_GROUP_ACTION = {
    CLOSE: 'close',
    CREATE: 'create',
    MOVE: 'move',
    DIVIDE: 'divide',
    RELEASE: 'release'
};

export const FISH_GROUP_STATUS = {
    RELEASED: 'Released'
};
