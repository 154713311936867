import { appInsights } from 'AppInsights';
import { Button, Form, Input, InputNumber, Modal, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_UPDATE_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { addSite, getOriginalSitesSelection, updateSite } from 'redux/thunks';

const SiteForm = ({ open = false, site, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.site.loading);
    const { data: originalSites, loading: originalSitesLoading } = useSelector(
        (s) => s.originalSiteSelection
    );

    const isUpdateForm = !!site;

    useEffect(() => {
        if (open && !isUpdateForm) {
            dispatch(getOriginalSitesSelection());
        }
    }, [open]);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue(site);
        } else {
            form.resetFields();
        }
    }, [site]);

    const createOrUpdateSite = (req) => {
        if (isUpdateForm) {
            handleUpdateSite(req);
        } else {
            handleCreateSite(req);
        }
    };

    const handleCreateSite = async (req) => {
        try {
            await dispatch(addSite(req)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_CREATION_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Site create',
            properties: { siteName: req.siteName }
        });
    };

    const handleUpdateSite = async (req) => {
        try {
            await dispatch(updateSite({ id: site.id, item: req })).unwrap();

            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Site update',
            properties: { siteName: req.siteName }
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={isUpdateForm ? t('site.siteForm.editSite') : t('site.siteForm.createSite')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={`${BUTTON_BLUE_CLASS} create-button`}>
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading || originalSitesLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdateSite}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input placeholder="id..." />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.name.title')}
                        name="originalSiteId"
                        className="originalSiteId"
                        rules={[
                            {
                                required: !isUpdateForm,
                                message: t('site.siteForm.name.required')
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <FDSelection
                            placeholder={t('site.siteForm.name.placeholder')}
                            listSelectItem={originalSites}
                            showSearch={true}
                            disabled={isUpdateForm}
                            dataTestId="site-option"
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.name.title')}
                        name="siteName"
                        hidden={!isUpdateForm}
                        required={false}
                    >
                        <Input placeholder={t('site.siteForm.name.title')} disabled />
                    </Form.Item>

                    <Form.Item label={t('general.form.description.title')} name="description">
                        <TextArea placeholder={t('general.form.description.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.lat.title')} name="latitude">
                        <Input placeholder={t('site.siteForm.lat.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.long.title')} name="longitude">
                        <Input placeholder={t('site.siteForm.long.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.localityNumber.title')}
                        name="localityNumber"
                        hidden={!isUpdateForm}
                        required={false}
                    >
                        <Input
                            placeholder={t('site.siteForm.localityNumber.placeholder')}
                            disabled
                        />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.capacity.title')} name="capacity">
                        <Input placeholder={t('site.siteForm.capacity.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('site.siteForm.municipality.title')} name="municipality">
                        <Input placeholder={t('site.siteForm.municipality.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.address.title')}
                        name="address"
                        className="address"
                    >
                        <Input placeholder={t('site.siteForm.address.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('site.siteForm.numbersOfPen.title')}
                        name="numbersOfPen"
                        hidden={isUpdateForm}
                    >
                        <InputNumber
                            min={0}
                            className="w-full"
                            placeholder={t('site.siteForm.numbersOfPen.placeholder')}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

SiteForm.propTypes = {
    open: PropTypes.bool,
    site: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default SiteForm;
