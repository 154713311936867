import { appInsights } from 'AppInsights';
import { Card, Col, Row } from 'antd';
import { STRESS_EVENT_PAGE } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDSliderSelection } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getEventListByFishGroupId, updateFilters } from 'redux/thunks';

const DetailCard = ({ fishGroupDetail }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: fishGroupEventList, loading } = useSelector((s) => s.fishGroupEventList);

    useEffect(() => {
        dispatch(getEventListByFishGroupId(fishGroupDetail.id));
    }, [fishGroupDetail]);

    const infoList = [
        {
            key: t('fishGroup.startDate'),
            value: formatDateTime(fishGroupDetail?.actionDate)
        },
        {
            key: t('fishGroup.endDate'),
            value: formatDateTime(fishGroupDetail?.inActiveDate)
        },
        {
            key: t('fishGroup.table.totalFishCount'),
            value: fishGroupDetail?.totalFishCount
        },
        {
            key: t('fishGroup.table.typeOfFish'),
            value:
                fishGroupDetail?.typeOfFish &&
                t(`general.fishMortality.${fishGroupDetail.typeOfFish}`)
        }
    ];

    const handleEventClick = async (eventId) => {
        await dispatch(
            updateFilters({
                selectedStressEventId: eventId,
                fromDate: fishGroupDetail?.actionDate
                    ? moment(fishGroupDetail?.actionDate)
                    : initialFilters.fromDate,
                toDate: fishGroupDetail?.inActiveDate
                    ? moment(fishGroupDetail?.inActiveDate)
                    : initialFilters.toDate
            })
        ).unwrap();

        navigate(STRESS_EVENT_PAGE, { state: { navigatedEventId: eventId } });

        appInsights.trackEvent({ name: 'FFI detail card event click', properties: { eventId } });
    };

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('fishGroup.detailCard.title')}</h2>

            <Card bordered className="text-base">
                <Row>
                    {infoList.map((info) => (
                        <Col xs={6} className="py-4" key={info.key}>
                            <div className="font-semibold">{info.key}</div>
                            <div>{info.value}</div>
                        </Col>
                    ))}
                </Row>

                <Row>
                    <Col xs={24}>
                        <div className="font-semibold">{t('stressAnalysis.events')}</div>
                        <FDSliderSelection
                            listSelectItem={fishGroupEventList.map((item) => ({
                                id: item.id,
                                content: (
                                    <div>
                                        <div>{formatDateTime(item.date)}</div>
                                        <div>{item.siteName}</div>
                                        <div>
                                            {t('general.table.penNumber')}: {item.penNumber}
                                        </div>
                                        <div>{item.eventType}</div>
                                    </div>
                                )
                            }))}
                            loading={loading}
                            onSelected={handleEventClick}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

DetailCard.propTypes = {
    fishGroupDetail: PropTypes.object.isRequired
};

export default DetailCard;
