import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMPANY_CONFIG_API } from 'common/constants';
import { API } from 'services';

export const getCompanyConfigs = createAsyncThunk(
    'companyConfigs/getCompanyConfigs',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(COMPANY_CONFIG_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getCompanyConfigByName = createAsyncThunk(
    'companyConfigs/getCompanyConfigByName',
    async ({ name }, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${COMPANY_CONFIG_API}/${name}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateCompanyConfig = createAsyncThunk(
    'companyConfigs/updateCompanyConfig',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${COMPANY_CONFIG_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
