import { notification } from 'antd';
import { NOTIFICATION_PLACEMENT, NOTIFICATION_TYPE } from 'common/constants';

export const pushNotification = ({
    type = NOTIFICATION_TYPE.success,
    title = 'New notification',
    description = '',
    placement = NOTIFICATION_PLACEMENT.topRight,
    duration = 5
}) => {
    notification[type]({
        message: title,
        description: description,
        placement,
        duration
    });
};
