import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Spin, Tag } from 'antd';
import { BUTTON_AS_LINK } from 'common/constants';
import { scrollToTop } from 'common/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getEventFishHealth, updateFilters } from 'redux/thunks';

const FishHealthCondition = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data: eventFishHealth, loading: eventFishHealthLoading = false } = useSelector(
        (s) => s.eventFishHealth
    );
    const { loading: eventDetailLoading = false } = useSelector((s) => s.eventDetail);
    const initialFilters = useSelector((s) => s.filter.data);

    useEffect(() => {
        if (initialFilters.selectedStressEventId) {
            dispatch(getEventFishHealth(initialFilters.selectedStressEventId));
        }
    }, [initialFilters.selectedStressEventId]);

    const handleLastEventClick = () => {
        const { lastEventId, lastEventStopTime } = eventFishHealth.treatment;
        if (!lastEventId || !lastEventStopTime) return;

        scrollToTop();

        const stopTime = moment(lastEventStopTime);

        dispatch(
            updateFilters({
                selectedStressEventId: lastEventId,
                fromDate: initialFilters.fromDate?.isAfter(stopTime)
                    ? stopTime.startOf('day')
                    : initialFilters.fromDate
            })
        );
    };

    return (
        <div className="pt-2 h-full">
            <Card className="h-full">
                <Spin spinning={!eventDetailLoading && eventFishHealthLoading}>
                    <p className="font-medium text-base">
                        {t('eventDetail.fishHealthCondition.title')}
                    </p>

                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <FishHealthMortality
                                title={t('eventDetail.fishHealthCondition.mortalityBeforeEvent')}
                                fishHealth={eventFishHealth.fishHealthBeforeEvent}
                            />

                            <FishHealthMortality
                                title={t('eventDetail.fishHealthCondition.mortalityAfterEvent')}
                                fishHealth={eventFishHealth.fishHealthAfterEvent}
                            />
                        </Col>

                        <Col xs={24} sm={12}>
                            <div className="flex flex-col items-center">
                                <Divider orientation="center" className="!mb-0">
                                    <p className="font-medium text-sm">
                                        {t('eventDetail.fishHealthCondition.treatment')}
                                    </p>
                                </Divider>
                                <div className="ml-5 text-base">
                                    <div className="flex justify-between h-8">
                                        <span className="text-gray-500 mr-1">
                                            {t('eventDetail.fishHealthCondition.daysSinceLast')}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {eventFishHealth.treatment?.lastEventDays ??
                                                t('eventDetail.fishHealthCondition.noEvent')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between h-8">
                                        <span className="text-gray-500 mr-1">
                                            {t('eventDetail.fishHealthCondition.lastTreatment')}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {eventFishHealth.treatment?.lastEventId ? (
                                                <button
                                                    className={BUTTON_AS_LINK}
                                                    onClick={handleLastEventClick}
                                                >
                                                    {t('eventDetail.fishHealthCondition.visit')}
                                                </button>
                                            ) : (
                                                t('eventDetail.fishHealthCondition.noEvent')
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex justify-between h-8">
                                        <span className="text-gray-500 mr-1">
                                            {t(
                                                'eventDetail.fishHealthCondition.averageDaysBetween'
                                            )}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {eventFishHealth.treatment?.avgEventDaysOnPen}
                                        </span>
                                    </div>
                                    <div className="flex justify-between h-8">
                                        <span className="text-gray-500 mr-1">
                                            {t(
                                                'eventDetail.fishHealthCondition.numberOfTreatments'
                                            )}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {eventFishHealth.treatment?.totalEventOnPen}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </Card>
        </div>
    );
};

const FishHealthMortality = ({ title = '', fishHealth = {} }) => {
    const { t } = useTranslation();
    const { level, levelColor, trend, trendColor } = fishHealth;

    return (
        <div className="mb-4">
            <Divider orientation="center" className="!mb-0">
                <p className="font-medium text-sm">{title}</p>
            </Divider>

            <div className="h-8 flex justify-center items-center">
                <span className="text-gray-500 text-base py-1 mr-1">
                    {t('eventDetail.fishHealthCondition.level')}
                </span>
                <Tag color={levelColor} className="font-semibold text-base">
                    {level}
                </Tag>
            </div>

            <div className="h-8 flex justify-center items-center">
                <span className="text-gray-500 text-base py-1 mr-1">
                    {t('eventDetail.fishHealthCondition.trend')}
                </span>
                {trend != null ? (
                    <Tag color={trendColor} className="font-semibold text-base">
                        {trend < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                        {Math.abs(trend)}%
                    </Tag>
                ) : (
                    <span className="italic">
                        {t('eventDetail.fishHealthCondition.cannotCalculateTrend')}
                    </span>
                )}
            </div>
        </div>
    );
};

FishHealthMortality.propTypes = {
    title: PropTypes.string,
    fishHealth: PropTypes.object
};

export default FishHealthCondition;
