import { QuestionOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const FDTooltip = ({ explainInfo = [] }) => {
    const descriptionInfo = explainInfo?.map((explain, index) => (
        <div key={index} className="flex justify-between text-base text-white">
            <span className="mr-2">{explain.title}:</span>
            <span>{explain.description}</span>
        </div>
    ));

    return (
        <Tooltip placement="topRight" title={descriptionInfo}>
            <Button
                data-testid="btn-tool-tip-daily-report"
                type="primary"
                shape="circle"
                size="small"
                icon={<QuestionOutlined />}
            />
        </Tooltip>
    );
};

FDTooltip.propTypes = {
    explainInfo: PropTypes.array
};

export default FDTooltip;
