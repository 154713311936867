import { createSlice } from '@reduxjs/toolkit';
import { getCompanyConfigs, updateCompanyConfig } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const companyConfigSlice = createSlice({
    name: 'companyConfig',
    initialState,
    reducers: {},
    extraReducers: {
        [getCompanyConfigs.pending]: (state) => {
            state.loading = true;
        },
        [getCompanyConfigs.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getCompanyConfigs.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [updateCompanyConfig.pending]: (state) => {
            state.loading = true;
        },
        [updateCompanyConfig.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateCompanyConfig.rejected]: (state) => {
            state.loading = false;
        }
    }
});
