import { useMsal } from '@azure/msal-react';
import { Button, Result } from 'antd';
import { BUTTON_BLUE_CLASS, BUTTON_GRAY_CLASS } from 'common/constants';
import { withPageViewTracking } from 'hoc';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SessionExpired = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <Result
            status="403"
            title={t('sessionExpired.title')}
            subTitle={t('sessionExpired.subTitle')}
            extra={
                <div className="flex justify-center gap-x-2">
                    <Link to="/">
                        <Button className={BUTTON_BLUE_CLASS}>{t('button.backHome')}</Button>
                    </Link>
                    <Button className={BUTTON_GRAY_CLASS} onClick={handleLogout}>
                        {t('button.logout')}
                    </Button>
                </div>
            }
        />
    );
};

export default withPageViewTracking(SessionExpired, 'Session expired');
