import { AppstoreOutlined, DribbbleOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getSitesContainingPens } from 'redux/thunks';

const SiteList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: sites } = useSelector((s) => s.siteContainingPens);

    const [siteListWidth, setSiteListWidth] = useState('100vw');

    useEffect(() => {
        dispatch(getSitesContainingPens());
    }, []);

    useEffect(() => {
        const adjustMainDivWidth = () => {
            const headerItems = document.getElementsByClassName('header-items')[0];
            const sidebar = document.getElementsByClassName('sidebar')[0];

            if (headerItems) {
                const headerItemsWidth = headerItems.offsetWidth;

                var computedStyle = window.getComputedStyle(sidebar);
                var leftValue = computedStyle.getPropertyValue('left');
                const sidebarWidth = leftValue === '-300px' ? 0 : sidebar.offsetWidth;

                setSiteListWidth(`calc(100vw - ${headerItemsWidth + sidebarWidth + 50}px)`);
            }
        };

        // Adjust the width on component mount
        adjustMainDivWidth();

        // Adjust the width on window resize
        window.addEventListener('resize', adjustMainDivWidth);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', adjustMainDivWidth);
        };
    }, [sites]);

    const items = sites.map((site, index) => ({
        label: site.siteName,
        key: `${site.id}-${index}`,
        icon: <AppstoreOutlined />,
        disabled: !site.hasData,
        children: [
            {
                label: (
                    <Link to={`site-detail/${site.id}`}>
                        <AppstoreOutlined /> {t('layout.header.viewSite')}
                    </Link>
                ),
                key: site.id,
                className: 'header-site-menu-item'
            },
            ...(site.pens?.map((pen) => ({
                label: (
                    <Link to={`pen-detail/${pen.id}`}>
                        <DribbbleOutlined /> {pen.penNumber}
                    </Link>
                ),
                key: pen.id,
                className: 'header-site-menu-item',
                disabled: !pen.hasData
            })) || [])
        ]
    }));

    return (
        <Menu
            mode="horizontal"
            selectable={false}
            className="header-site-menu border-none grow"
            items={items}
            style={{ width: siteListWidth }}
        />
    );
};

export default SiteList;
