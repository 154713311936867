import { Spin, Tooltip } from 'antd';
import { DARK_GREEN } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './FDStockCount.scss';

const FDStockCount = () => {
    const { t } = useTranslation();

    const { data: stockCount, loading } = useSelector((s) => s.stockCount);

    const getTooltipContent = (missingPens) => {
        if (!missingPens.length) return '';

        return (
            <div className="missing-pens-tooltip max-h-44 overflow-auto">
                <span className="font-medium">
                    {t('component.stockCount.pensMissingTotalFishCont')}:
                </span>
                {missingPens.map((pen) => (
                    <div key={pen.penId}>{`${t('component.stockCount.pen')} ${pen.penNumber} - ${
                        pen.siteName
                    }`}</div>
                ))}
            </div>
        );
    };

    return (
        <div className="flex flex-col justify-center">
            <span className="font-semibold text-base mb-1">
                {t('component.stockCount.stockCountToday')}
            </span>

            <Tooltip
                title={getTooltipContent(stockCount.pensMissingTotalFishCount)}
                color={DARK_GREEN}
                placement="bottom"
            >
                <div className="stock-count text-center">
                    <div className="text-white text-3xl font-semibold">
                        {loading ? (
                            <Spin />
                        ) : stockCount.count != null ? (
                            stockCount.count.toLocaleString()
                        ) : (
                            '?'
                        )}
                    </div>
                    <div className="text-sm text-slate-300">
                        {stockCount.pensMissingTotalFishCount.length
                            ? t('component.stockCount.seeMissingPens')
                            : ''}
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};

export default FDStockCount;
