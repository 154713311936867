import axios from 'axios';
import moment from 'moment';

export const convertMomentToISOString = (params) => {
    for (let key in params) {
        if (moment.isMoment(params[key])) params[key] = params[key].toISOString();
    }
};

// Map to store ongoing requests
const ongoingRequests = new Map();

const replaceUUID = (str) => {
    const uuidRegex =
        /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/gi;
    return str.replace(uuidRegex, 'uuid');
};

const generateRequestKey = (config) => {
    const { method, url } = config;
    return [method, replaceUUID(url)].join('&');
};

export const cancelDuplicatedRequest = (config) => {
    if (config.skipCancelRequest) return;

    const requestKey = generateRequestKey(config);

    if (ongoingRequests.has(requestKey)) {
        const cancelTokenSource = ongoingRequests.get(requestKey);
        cancelTokenSource.cancel('Duplicated request is canceled');
    }
    const cancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelTokenSource.token;
    ongoingRequests.set(requestKey, cancelTokenSource);
};

export const removeCompletedRequest = (config) => {
    if (config.skipCancelRequest) return;

    const requestKey = generateRequestKey(config);
    ongoingRequests.delete(requestKey);
};
