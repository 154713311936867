import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

export const useDidMountEffect = (func = () => {}, deps = []) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) return func();
        else didMount.current = true;
    }, deps);
};

useDidMountEffect.PropTypes = {
    func: PropTypes.func,
    deps: PropTypes.array
};
