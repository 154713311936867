import { DATE_TIME_FORMAT } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDTable } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getDailyEvent } from 'redux/thunks';
import moment from 'moment';

const EventTable = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: events, loading } = useSelector((s) => s.dailyEvent);

    useEffect(() => {
        dispatch(
            getDailyEvent({
                fromDate: initialFilters.daily.fromDate,
                toDate: initialFilters.daily.toDate,
                siteIds: initialFilters.daily.siteId ? [initialFilters.daily.siteId] : [],
                penIds: initialFilters.daily.penId ? [initialFilters.daily.penId] : []
            })
        );
    }, [initialFilters.daily]);

    const COLUMNS_FOR_DAILY_EVENT = [
        {
            title: 'general.table.site',
            dataIndex: 'site'
        },
        {
            title: 'general.table.pen',
            dataIndex: 'pen'
        },
        {
            title: 'general.table.boat',
            dataIndex: 'boat'
        },
        {
            title: 'general.table.startTime',
            dataIndex: 'startTime',
            render: (startTime) => formatDateTime(startTime, DATE_TIME_FORMAT),
            sorter: {
                compare: (a, b) => moment(a.startTime) - moment(b.startTime),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'general.table.stopTime',
            dataIndex: 'stopTime',
            render: (stopTime) => formatDateTime(stopTime, DATE_TIME_FORMAT),
            sorter: {
                compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'general.table.eventType',
            dataIndex: 'eventType'
        }
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('dailyReport.events.title')}</h2>
            <FDTable headers={COLUMNS_FOR_DAILY_EVENT} data={events} loading={loading} />
        </div>
    );
};

export default EventTable;
