import { Column } from '@ant-design/plots';
import PropTypes from 'prop-types';

const FDStackedColumn = ({ data = [], colors, height, chartConfig, formatValue }) => {
    const config = {
        data,
        color: colors,
        height,
        isStack: true,
        xField: 'name',
        yField: 'value',
        seriesField: 'type',
        legend: {
            position: 'top'
        },
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            }
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                },
                formatter: (value) => (formatValue ? formatValue(value) : value)
            }
        },
        tooltip: {
            formatter: (data) => ({
                name: data.type,
                value: formatValue ? formatValue(data.value) : data.value
            })
        },
        ...chartConfig
    };

    return <Column {...config} />;
};

FDStackedColumn.propTypes = {
    data: PropTypes.array,
    colors: PropTypes.array,
    height: PropTypes.number,
    chartConfig: PropTypes.object,
    formatValue: PropTypes.func
};

export default FDStackedColumn;
