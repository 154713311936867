import { createSelector } from '@reduxjs/toolkit';

const liveStressDetectionSelector = (state) => state.liveStressDetection;

export const stressDetectionServiceBoatsSelector = createSelector(
    liveStressDetectionSelector,
    (stressDetection) => stressDetection.serviceBoats
);

export const stressDetectionStreamingSelector = createSelector(
    liveStressDetectionSelector,
    (stressDetection) => stressDetection.streaming
);
