// Button class name
export const BUTTON_BLUE_CLASS =
    'bg-sky-700 hover:bg-sky-800 focus-within:bg-sky-700 hover:text-white text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none';
export const BUTTON_GRAY_CLASS =
    'bg-slate-500 hover:bg-slate-600 focus-within:bg-slate-500 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none';
export const BUTTON_RED_CLASS =
    'bg-red-600 hover:bg-red-700 focus-within:bg-red-600 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none';
export const BUTTON_GREEN_CLASS =
    'bg-cyan-800 hover:bg-cyan-900 focus-within:bg-cyan-800 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none';
export const BUTTON_LIGHT_GREEN_CLASS =
    'bg-green-700 hover:bg-green-800 focus-within:bg-green-700 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none';
export const BUTTON_WHITE_CLASS =
    'bg-white hover:bg-slate-50 hover:border-slate-400 text-slate-500 font-semibold py-1 px-2 rounded border-2 mb-2';
export const BUTTON_WHITE_1_CLASS =
    'bg-slate-200 hover:bg-slate-300 text-slate-600 font-semibold py-1 px-2 rounded border-none outline-1';
export const BUTTON_PURPLE_CLASS =
    'bg-slate-700 hover:bg-indigo-800 focus-within:bg-slate-700 hover:border-slate-400 text-black focus-within:text-black font-semibold py-1 px-2 rounded border-2 mb-2';

export const BUTTON_BLUE_SELECTED =
    'bg-sky-700 focus-within:bg-sky-700 text-white hover:text-white focus-within:text-white font-semibold py-1 px-4 border border-sky-700 hover:border-sky-700 focus-within:border-sky-700 rounded cursor-not-allowed transition-all';
export const BUTTON_BLUE_UN_SELECTED =
    'bg-transparent hover:bg-gray-100 text-sky-700 hover:text-sky-700 font-semibold py-1 px-4 border border-sky-700 hover:border-sky-700 rounded transition-all';

export const BUTTON_GRAY_SELECTED =
    'bg-slate-500 focus-within:bg-slate-500 hover:bg-slate-600 text-white hover:text-white focus-within:text-white font-semibold py-1 px-2 rounded border border-gray-600 focus-within:border-gray-600 hover:border-gray-600';
export const BUTTON_GRAY_UN_SELECTED =
    'bg-transparent hover:bg-gray-200 focus-within:bg-transparent text-gray-700 hover:text-gray-700 focus-within:text-gray-700 font-semibold py-1 px-2 rounded border border-gray-600 focus-within:border-gray-600 hover:border-gray-600';

export const BUTTON_AS_LINK =
    'font-semibold text-sky-700 hover:text-sky-600 underline cursor-pointer bg-transparent border-none';
