import { createSlice } from '@reduxjs/toolkit';
import { getReportSalmonWound } from 'redux/thunks';

const initialState = {
    data: {
        counts: [
            { title: 'withWounds', totalAmount: 0 },
            { title: 'withoutWounds', totalAmount: 0 },
            { title: 'total', totalAmount: 0 }
        ],
        charts: []
    },
    loading: false
};

export const reportSalmonWoundSlice = createSlice({
    name: 'reportSalmonWound',
    initialState,
    reducers: {},
    extraReducers: {
        [getReportSalmonWound.pending]: (state) => {
            state.loading = true;
        },
        [getReportSalmonWound.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getReportSalmonWound.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
