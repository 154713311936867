import { Card, Spin } from 'antd';
import { OXYGEN_ZONES } from 'common/constants';
import { formatNumberToFixedDigits } from 'common/utils';
import { FDColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const OxygenLevelPerThrow = () => {
    const { t } = useTranslation();

    const { data: oxygenLevelsPerThrow, loading: oxygenLevelsPerThrowLoading } = useSelector(
        (s) => s.stressAnalysis.oxygenLevelPerThrow
    );

    const getOxygenZoneName = (oxygenLevel) => {
        var oxygenZone = OXYGEN_ZONES.find(
            (zone) => zone.min < oxygenLevel && oxygenLevel <= zone.max
        );
        return oxygenZone?.name;
    };

    const getOxygenZoneColor = (oxygenZoneName) => {
        var oxygenZone = OXYGEN_ZONES.find((zone) => zone.name === oxygenZoneName);
        return oxygenZone?.color;
    };

    const formattedData = oxygenLevelsPerThrow.map((item, index) => ({
        name: String(index + 1),
        value: formatNumberToFixedDigits(item.average),
        type: getOxygenZoneName(item.average)
    }));

    return (
        <Spin spinning={oxygenLevelsPerThrowLoading}>
            <Card>
                <p className="font-medium text-base">
                    {t('stressAnalysis.averageOxygenLevelPerThrow')}
                </p>
                <FDColumn
                    data={formattedData}
                    height={320}
                    color={({ type }) => getOxygenZoneColor(type)}
                    legend={{
                        itemName: {
                            formatter: (type) => t(type)
                        }
                    }}
                    tooltip={{
                        customItems: (items) =>
                            items.map((item) => ({
                                ...item,
                                name: t('stressAnalysis.average')
                            }))
                    }}
                />
            </Card>
        </Spin>
    );
};

export default OxygenLevelPerThrow;
