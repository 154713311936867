import { Button, Col, Row } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDMultipleSelection, FDRangeDate, FDSliderSelection } from 'components';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    getFishGroupTreeByFishwellFishGroupId,
    getFishwellFishIdsSelection,
    getPensSelection,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: fishwellFishIdsSelection, loading } = useSelector(
        (s) => s.fishwellFishIdSelection
    );

    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [filters, setFilters] = useState({
        siteIds: initialFilters.siteIds,
        penIds: initialFilters.penIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useEffect(() => {
        dispatch(
            getFishwellFishIdsSelection({
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );
    }, [
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useDidMountEffect(() => {
        handleFishwellFishIdChange(
            (fishwellFishIdsSelection.some((e) => e.id === initialFilters.selectedFishGroupId) &&
                initialFilters.selectedFishGroupId) ||
                fishwellFishIdsSelection[0]?.id ||
                null
        );
    }, [fishwellFishIdsSelection]);

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.penIds;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens
        });
    };

    const handlePensChange = (penIds) => {
        setFilters({
            ...filters,
            penIds: penIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterApply = () => {
        dispatch(
            updateFilters({
                siteIds: filters.siteIds,
                penIds: filters.penIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );

        appInsights.trackEvent({ name: 'FFI filter apply' });
    };

    const handleFishwellFishIdChange = (selectedFishGroupId) => {
        dispatch(
            updateFilters({
                selectedFishGroupId
            })
        );

        if (selectedFishGroupId)
            dispatch(getFishGroupTreeByFishwellFishGroupId(selectedFishGroupId));

        appInsights.trackEvent({
            name: 'Selected fish group change',
            properties: { selectedFishGroupId }
        });
    };

    return (
        <div className="filter-bar mb-2">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>

            <div className="filter md:text-base rounded-lg">
                <Row>
                    <Col xs={24} lg={12} xl={6}>
                        <div className="font-semibold">{t('general.filterBar.site')}</div>
                        <FDMultipleSelection
                            placeholder={t('general.filterBar.sitesPlaceholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSitesChange}
                            value={filters.siteIds}
                        />
                    </Col>

                    <Col xs={24} lg={12} xl={6}>
                        <div className="font-semibold">{t('general.filterBar.pen')}</div>
                        <FDMultipleSelection
                            placeholder={t('general.filterBar.pensPlaceholder')}
                            listSelectItem={pensSelection}
                            onChange={handlePensChange}
                            value={filters.penIds}
                        />
                    </Col>

                    <Col xs={24} lg={12} xl={7}>
                        <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                        <FDRangeDate
                            placeholder={[
                                t('general.filterBar.startDatePlaceholder'),
                                t('general.filterBar.endDatePlaceholder')
                            ]}
                            onChange={handleRangeDateChange}
                            value={[filters.fromDate, filters.toDate]}
                        />
                    </Col>

                    <Col className="xs:mt-0 sm:mt-6">
                        <Button className={BUTTON_BLUE_CLASS} onClick={handleFilterApply}>
                            {t('general.filterBar.apply')}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24}>
                        <div className="font-semibold">{t('general.filterBar.fishGroups')}</div>
                        <FDSliderSelection
                            selectedId={initialFilters.selectedFishGroupId}
                            listSelectItem={fishwellFishIdsSelection.map((item) => ({
                                id: item.id,
                                content: (
                                    <div>
                                        <div>{formatDateTime(item.actionDate)}</div>
                                        <div>{item.text}</div>
                                        <div>{item.siteName}</div>
                                        <div>
                                            {t('general.table.penNumber')}: {item.penNumber}
                                        </div>
                                    </div>
                                )
                            }))}
                            loading={loading}
                            onSelected={handleFishwellFishIdChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FilterBar;
