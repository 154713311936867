import { createSlice } from '@reduxjs/toolkit';
import {
    getStressAnalysisForCrowdingTimePerThrow,
    getStressAnalysisForEvent,
    getStressAnalysisForFish,
    getStressAnalysisForMortalityPerEvent,
    getStressAnalysisForOxygenLevelPerThrow,
    getStressAnalysisForStressLevelPerThrow,
    getStressAnalysisForStressZonePerThrow
} from 'redux/thunks';

const initialState = {
    event: { data: [], loading: false },
    fish: { data: [], loading: false },
    stressLevelPerThrow: { data: [], loading: false },
    crowdingTimePerThrow: { data: [], loading: false },
    stressZonePerThrow: { data: [], loading: false },
    oxygenLevelPerThrow: { data: [], loading: false },
    mortalityPerEvent: { data: [], loading: false }
};

export const stressAnalysisSlice = createSlice({
    name: 'stressAnalysis',
    initialState,
    reducers: {},
    extraReducers: {
        [getStressAnalysisForEvent.pending]: (state) => {
            state.event.loading = true;
        },
        [getStressAnalysisForEvent.fulfilled]: (state, action) => {
            state.event.loading = false;
            state.event.data = action.payload;
        },
        [getStressAnalysisForEvent.rejected]: (state) => {
            state.event.loading = false;
            state.event.data = initialState.event.data;
        },

        [getStressAnalysisForFish.pending]: (state) => {
            state.fish.loading = true;
        },
        [getStressAnalysisForFish.fulfilled]: (state, action) => {
            state.fish.loading = false;
            state.fish.data = action.payload;
        },
        [getStressAnalysisForFish.rejected]: (state) => {
            state.fish.loading = false;
            state.fish.data = initialState.fish.data;
        },

        [getStressAnalysisForStressLevelPerThrow.pending]: (state) => {
            state.stressLevelPerThrow.loading = true;
        },
        [getStressAnalysisForStressLevelPerThrow.fulfilled]: (state, action) => {
            state.stressLevelPerThrow.loading = false;
            state.stressLevelPerThrow.data = action.payload;
        },
        [getStressAnalysisForStressLevelPerThrow.rejected]: (state) => {
            state.stressLevelPerThrow.loading = false;
            state.stressLevelPerThrow.data = initialState.stressLevelPerThrow.data;
        },

        [getStressAnalysisForCrowdingTimePerThrow.pending]: (state) => {
            state.crowdingTimePerThrow.loading = true;
        },
        [getStressAnalysisForCrowdingTimePerThrow.fulfilled]: (state, action) => {
            state.crowdingTimePerThrow.loading = false;
            state.crowdingTimePerThrow.data = action.payload;
        },
        [getStressAnalysisForCrowdingTimePerThrow.rejected]: (state) => {
            state.crowdingTimePerThrow.loading = false;
            state.crowdingTimePerThrow.data = initialState.crowdingTimePerThrow.data;
        },

        [getStressAnalysisForStressZonePerThrow.pending]: (state) => {
            state.stressZonePerThrow.loading = true;
        },
        [getStressAnalysisForStressZonePerThrow.fulfilled]: (state, action) => {
            state.stressZonePerThrow.loading = false;
            state.stressZonePerThrow.data = action.payload;
        },
        [getStressAnalysisForStressZonePerThrow.rejected]: (state) => {
            state.stressZonePerThrow.loading = false;
            state.stressZonePerThrow.data = initialState.stressZonePerThrow.data;
        },

        [getStressAnalysisForOxygenLevelPerThrow.pending]: (state) => {
            state.oxygenLevelPerThrow.loading = true;
        },
        [getStressAnalysisForOxygenLevelPerThrow.fulfilled]: (state, action) => {
            state.oxygenLevelPerThrow.loading = false;
            state.oxygenLevelPerThrow.data = action.payload;
        },
        [getStressAnalysisForOxygenLevelPerThrow.rejected]: (state) => {
            state.oxygenLevelPerThrow.loading = false;
            state.oxygenLevelPerThrow.data = initialState.oxygenLevelPerThrow.data;
        },

        [getStressAnalysisForMortalityPerEvent.pending]: (state) => {
            state.mortalityPerEvent.loading = true;
        },
        [getStressAnalysisForMortalityPerEvent.fulfilled]: (state, action) => {
            state.mortalityPerEvent.loading = false;
            state.mortalityPerEvent.data = action.payload;
        },
        [getStressAnalysisForMortalityPerEvent.rejected]: (state) => {
            state.mortalityPerEvent.loading = false;
            state.mortalityPerEvent.data = initialState.mortalityPerEvent.data;
        }
    }
});
