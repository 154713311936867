import { Image } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import emptyLogo from 'assets/Empty_Box.png';

const WoundAndSizeAttachment = ({ attachments = [], limit = 2 }) => {
    return (
        <div className="flex flex-wrap gap-1">
            {attachments.length ? (
                attachments
                    .slice(0, limit)
                    .map((attachment, index) => (
                        <Image
                            className="rounded-sm border-inherit object-contain border border-solid border-slate-400"
                            width={60}
                            height={60}
                            src={attachment}
                            key={index}
                        />
                    ))
            ) : (
                <img alt="emptyLogo" src={emptyLogo} />
            )}
        </div>
    );
};

WoundAndSizeAttachment.propTypes = {
    attachments: PropTypes.array,
    limit: PropTypes.number
};

export default WoundAndSizeAttachment;
