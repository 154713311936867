import { Button, Card, Col, Row, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { formatDateTime } from 'common/utils';
import { FDMultipleSelection, FDRangeDate } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { processListSelector } from 'redux/selector';
import { useAppDispatch } from 'redux/store';
import {
    getPensSelection,
    getProcessList,
    getServiceBoatsSelection,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';

const FilterProcesses = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: processes, loading } = useSelector(processListSelector);
    const serviceBoatsSelection = useSelector((s) => s.serviceBoatSelection.data);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [filters, setFilters] = useState({
        serviceBoatIds: initialFilters.serviceBoatIds,
        siteIds: initialFilters.siteIds,
        penIds: initialFilters.penIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(getServiceBoatsSelection());
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useEffect(() => {
        dispatch(
            getProcessList({
                serviceBoatIds: initialFilters.serviceBoatIds,
                siteIds: initialFilters.siteIds,
                penIds: initialFilters.penIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );
    }, [
        initialFilters.serviceBoatIds,
        initialFilters.siteIds,
        initialFilters.penIds,
        initialFilters.fromDate,
        initialFilters.toDate
    ]);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.siteIds.length)
            pensSelection = allPens.filter((pen) => initialFilters.siteIds.includes(pen.siteId));

        setPensSelection(pensSelection);
    }, [allPens]);

    useEffect(() => {
        if (processes.some((process) => process.id === initialFilters.selectedProcessId)) {
            return;
        }

        handleProcessChange(processes[0]?.id || null);
    }, [processes]);

    const handleServiceBoatsChange = (serviceBoatIds) => {
        setFilters({
            ...filters,
            serviceBoatIds: serviceBoatIds
        });
    };

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.penIds;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens
        });
    };

    const handlePensChange = (penIds) => {
        setFilters({
            ...filters,
            penIds: penIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterSubmit = () => {
        dispatch(
            updateFilters({
                serviceBoatIds: filters.serviceBoatIds,
                siteIds: filters.siteIds,
                penIds: filters.penIds,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );

        appInsights.trackEvent({ name: 'Process filter apply' });
    };

    const handleProcessChange = (processId) => {
        dispatch(
            updateFilters({
                selectedProcessId: processId
            })
        );

        appInsights.trackEvent({ name: 'Selected process change', properties: { processId } });
    };

    return (
        <div className="filter-bar flex flex-col">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>
            <div className="filter md:text-base rounded-lg flex flex-wrap">
                <div className="filter-bar grow">
                    <Row>
                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.site')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.sitesPlaceholder')}
                                listSelectItem={sitesSelection}
                                onChange={handleSitesChange}
                                value={filters.siteIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.pen')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.pensPlaceholder')}
                                listSelectItem={pensSelection}
                                onChange={handlePensChange}
                                value={filters.penIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.boat')}</div>
                            <FDMultipleSelection
                                placeholder={t('general.filterBar.boatsPlaceholder')}
                                listSelectItem={serviceBoatsSelection}
                                onChange={handleServiceBoatsChange}
                                value={filters.serviceBoatIds}
                            />
                        </Col>

                        <Col xs={24} md={12} xl={12} xxl={6}>
                            <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                            <FDRangeDate
                                placeholder={[
                                    t('general.filterBar.startDatePlaceholder'),
                                    t('general.filterBar.endDatePlaceholder')
                                ]}
                                onChange={handleRangeDateChange}
                                value={[filters.fromDate, filters.toDate]}
                            />
                        </Col>

                        <Col>
                            <Button
                                loading={loading}
                                className={BUTTON_BLUE_CLASS}
                                onClick={handleFilterSubmit}
                            >
                                {t('general.filterBar.apply')}
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className="processes min-w-[200px] pt-2.5 pr-2.5 pl-2.5 mb-2.5 grow xl:grow-0">
                    <div className="font-semibold">{t('process.processes')}</div>
                    <Card className="h-[110px]">
                        <Spin spinning={loading}>
                            {!(processes.length || loading) ? (
                                <p className="m-0 text-center text-red-600">
                                    {t('process.noProcessesFound')}
                                </p>
                            ) : (
                                <div className="max-h-[90px] overflow-y-auto overflow-x-hidden">
                                    <table className="whitespace-nowrap">
                                        <tbody>
                                            {processes.map((process) => (
                                                <tr
                                                    key={process.id}
                                                    className={`cursor-pointer hover:font-medium transition px-2 
                                                            ${
                                                                process.id ===
                                                                    initialFilters.selectedProcessId &&
                                                                'bg-sky-700 text-white font-medium'
                                                            }`}
                                                    onClick={() => handleProcessChange(process.id)}
                                                >
                                                    <td className="px-2">
                                                        {formatDateTime(process.startTime)}
                                                    </td>
                                                    <td className="px-2">{process.siteName}</td>
                                                    <td className="px-2">{process.penNumber}</td>
                                                    <td className="px-2">{process.boatName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Spin>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default FilterProcesses;
