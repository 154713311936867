import { withPageViewTracking } from 'hoc';
import { useSelector } from 'react-redux';
import FilterMortalities from './FilterMortalities';
import MortalityDetail from './MortalityDetail';

const Mortality = () => {
    const { selectedMortalityId } = useSelector((s) => s.filter.data);

    return (
        <>
            <FilterMortalities />

            {selectedMortalityId && <MortalityDetail />}
        </>
    );
};

export default withPageViewTracking(Mortality, 'Mortality detail');
