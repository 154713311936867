export const COMPANY_NOTIFICATION_EXPLANATION = [
    {
        title: 'Mortality',
        description: 'Description 1'
    },
    {
        title: 'Above average',
        description: 'Description 2'
    },
    {
        title: 'Contribution',
        description: 'Description 3'
    },
    {
        title: 'Pens counted',
        description: 'Description 4'
    }
];

export const SITE_NOTIFICATION_EXPLANATION = [
    {
        title: 'Mortality',
        description: 'Description 1'
    },
    {
        title: 'Above average',
        description: 'Description 2'
    },
    {
        title: 'Contribution',
        description: 'Description 3'
    },
    {
        title: 'Counted',
        description: 'Description 4'
    }
];

export const PEN_NOTIFICATION_EXPLANATION = [
    {
        title: 'Mortality',
        description: 'Description 1'
    },
    {
        title: 'Pen average',
        description: 'Description 2'
    },
    {
        title: 'Site average',
        description: 'Description 3'
    },
    {
        title: 'Company average',
        description: 'Description 4'
    },
    {
        title: 'Counted',
        description: 'Description 5'
    }
];
