import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { BUTTON_GREEN_CLASS } from 'common/constants';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PenList = ({ isOpen, onClose = () => {}, onEngineStart = async () => {} }) => {
    const { t } = useTranslation();

    const INCREASING_NUMBER = 4;
    const DEFAULT_NUMBER_OF_PENS = 12;

    const [numberOfPens, setNumberOfPens] = useState(DEFAULT_NUMBER_OF_PENS);
    const [selectedPenNumber, setSelectedPenNumber] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setSelectedPenNumber();
            setNumberOfPens(DEFAULT_NUMBER_OF_PENS);
        }
    }, [isOpen]);

    const handleEngineStart = async () => {
        try {
            setLoading(true);
            await onEngineStart(selectedPenNumber);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={t('liveFishEvent.choosePen')}
            open={isOpen}
            onCancel={onClose}
            onOk={onEngineStart}
            className="pen-list-modal"
            footer={
                <Button
                    onClick={() => handleEngineStart()}
                    disabled={!selectedPenNumber}
                    icon={<CaretRightOutlined />}
                    loading={loading}
                    className={`${BUTTON_GREEN_CLASS} w-full text-lg flex items-center justify-center`}
                    data-testid="start-button"
                >
                    {t('button.start')}
                </Button>
            }
        >
            <Row gutter={[8, 8]}>
                {Array.from({ length: numberOfPens }, (_, i) => i + 1).map((penNumber) => {
                    return (
                        <Col xs={12} sm={8} md={6} lg={6} xl={6} xxl={6} key={penNumber}>
                            <Button
                                onClick={() => setSelectedPenNumber(penNumber)}
                                className={`h-20 w-full sm:text-xl text-black bg-transparent rounded font-semibold border-solid hover:bg-slate-600 hover:text-white 
                                    cursor-pointer flex justify-center items-center align-middle transition ${
                                        penNumber === selectedPenNumber &&
                                        '!bg-slate-600 !text-white'
                                    }`}
                                data-testid="pen-option"
                            >
                                {penNumber}
                            </Button>
                        </Col>
                    );
                })}
            </Row>

            <div className="mt-3 flex justify-center">
                <Button
                    className="my-auto"
                    onClick={() => setNumberOfPens(numberOfPens + INCREASING_NUMBER)}
                >
                    {t('button.more')}
                </Button>
            </div>
        </Modal>
    );
};

PenList.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onEngineStart: PropTypes.func
};

export default memo(PenList);
