import { Button, Modal } from 'antd';
import { BUTTON_BLUE_CLASS, BUTTON_WHITE_CLASS } from 'common/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const FDDialogBox = ({ showDialog, message, cancelNavigation, confirmNavigation }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={showDialog}
            onOk={confirmNavigation}
            onCancel={cancelNavigation}
            closable={true}
            footer={[
                <Button onClick={cancelNavigation} className={BUTTON_WHITE_CLASS} key="1">
                    {t('dialogBox.cancel')}
                </Button>,
                <Button onClick={confirmNavigation} className={`${BUTTON_BLUE_CLASS}`} key="2">
                    {t('dialogBox.leave')}
                </Button>
            ]}
        >
            {message ? t(message) : t('dialogBox.defaultMessage')}
        </Modal>
    );
};

FDDialogBox.propTypes = {
    showDialog: PropTypes.bool,
    message: PropTypes.string,
    cancelNavigation: PropTypes.func,
    confirmNavigation: PropTypes.func
};

export default FDDialogBox;
