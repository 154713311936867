import { Card, Spin } from 'antd';
import { GRAY, GREEN, ORANGE, RED, STRESS_ZONES, YELLOW } from 'common/constants';
import { formatSeconds } from 'common/utils';
import { FDStackedColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StressZonePerThrow = () => {
    const { t } = useTranslation();

    const { data: stressZonesPerThrow, loading: stressZonesPerThrowLoading } = useSelector(
        (s) => s.stressAnalysis.stressZonePerThrow
    );

    const formattedData = stressZonesPerThrow.flatMap((item, index) =>
        item.stressZones.map((stressZone) => ({
            name: String(index + 1),
            value: Math.round(stressZone.value),
            type: t(stressZone.name)
        }))
    );

    return (
        <Spin spinning={stressZonesPerThrowLoading}>
            <Card>
                <p className="font-medium text-base">
                    {t('stressAnalysis.averageStressZonePerThrow')}
                </p>
                <FDStackedColumn
                    data={formattedData}
                    colors={[GRAY, RED, ORANGE, YELLOW, GREEN]}
                    height={320}
                    chartConfig={{
                        meta: {
                            type: { values: STRESS_ZONES.map((stressZone) => t(stressZone.name)) }
                        }
                    }}
                    formatValue={formatSeconds}
                />
            </Card>
        </Spin>
    );
};

export default StressZonePerThrow;
