import { createSlice } from '@reduxjs/toolkit';
import { getCompanyConfigByName } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const companyConfigDetailSlice = createSlice({
    name: 'companyConfigDetail',
    initialState,
    reducers: {},
    extraReducers: {
        [getCompanyConfigByName.pending]: (state) => {
            state.loading = true;
        },
        [getCompanyConfigByName.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getCompanyConfigByName.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
