import { Button, Col, Row } from 'antd';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { FDMultipleSelection, FDRangeDate } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import {
    getPenComparison,
    getPensSelection,
    getSiteComparison,
    getSitesSelection,
    updateFilters
} from 'redux/thunks';
import { appInsights } from 'AppInsights';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const allPens = useSelector((s) => s.penSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [filters, setFilters] = useState({
        siteIds: initialFilters.comparison.siteIds,
        penIds: initialFilters.comparison.penIds,
        fromDate: initialFilters.fromDate,
        toDate: initialFilters.toDate
    });

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
        if (filters.siteIds.length) dispatch(getSiteComparison(filters));
        if (filters.penIds.length) dispatch(getPenComparison(filters));
    }, []);

    useEffect(() => {
        if (!sitesSelection.length || filters.siteIds.length || filters.penIds.length) return;

        const siteIds = sitesSelection.map((s) => s.id);

        dispatch(
            getSiteComparison({
                siteIds: siteIds,
                fromDate: initialFilters.fromDate,
                toDate: initialFilters.toDate
            })
        );

        setFilters({ ...filters, siteIds });

        dispatch(
            updateFilters({
                comparison: {
                    siteIds,
                    penIds: []
                }
            })
        );
    }, [sitesSelection]);

    useEffect(() => {
        let pensSelection = allPens;

        if (initialFilters.comparison.siteIds.length)
            pensSelection = allPens.filter((pen) =>
                initialFilters.comparison.siteIds.includes(pen.siteId)
            );

        setPensSelection(pensSelection);
    }, [allPens]);

    const handleSitesChange = (siteIds) => {
        let selectedPens = filters.penIds;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) => siteIds.includes(pen.siteId));

            selectedPens = filters.penIds.filter((penId) =>
                newPensSelections.some((penSelection) => penSelection.id === penId)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            siteIds: siteIds,
            penIds: selectedPens
        });
    };

    const handlePensChange = (penIds) => {
        setFilters({
            ...filters,
            penIds: penIds
        });
    };

    const handleRangeDateChange = (fromDate, toDate) => {
        setFilters({
            ...filters,
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const handleFilterApply = () => {
        dispatch(getSiteComparison(filters));
        dispatch(getPenComparison(filters));

        dispatch(
            updateFilters({
                comparison: {
                    siteIds: filters.siteIds,
                    penIds: filters.penIds
                },
                fromDate: filters.fromDate,
                toDate: filters.toDate
            })
        );

        appInsights.trackEvent({ name: 'Comparison filter apply' });
    };

    return (
        <div className="filter-bar" data-testid="filter-bar">
            <h2 className="mb-2">{t('general.filterBar.title')}</h2>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                    <div className="font-semibold">{t('general.filterBar.site')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.sitesPlaceholder')}
                        listSelectItem={sitesSelection}
                        onChange={handleSitesChange}
                        value={filters.siteIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                    <div className="font-semibold">{t('general.filterBar.pen')}</div>
                    <FDMultipleSelection
                        placeholder={t('general.filterBar.pensPlaceholder')}
                        listSelectItem={pensSelection}
                        onChange={handlePensChange}
                        value={filters.penIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="font-semibold">{t('general.filterBar.rangeDate')}</div>
                    <FDRangeDate
                        placeholder={[
                            t('general.filterBar.startDatePlaceholder'),
                            t('general.filterBar.endDatePlaceholder')
                        ]}
                        onChange={handleRangeDateChange}
                        value={[filters.fromDate, filters.toDate]}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3} className=" ">
                    <Button className={`${BUTTON_BLUE_CLASS} mt-6`} onClick={handleFilterApply}>
                        {t('general.filterBar.apply')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
