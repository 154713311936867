import { Col, Row } from 'antd';
import StressLevelPerThrow from './StressLevelPerThrow';
import CrowdingTimePerThrow from './CrowdingTimePerThrow';
import MortalityPerEvent from './MortalityPerEvent';
import StressZonePerThrow from './StressZonePerThrow';
import OxygenLevelPerThrow from './OxygenLevelPerThrow';

const AnalysisCharts = () => {
    return (
        <div className="mt-2">
            <Row gutter={[8, 8]}>
                <Col xs={24} xl={12}>
                    <StressLevelPerThrow />
                </Col>
                <Col xs={24} xl={12}>
                    <CrowdingTimePerThrow />
                </Col>
                <Col xs={24} xl={12}>
                    <StressZonePerThrow />
                </Col>
                <Col xs={24} xl={12}>
                    <OxygenLevelPerThrow />
                </Col>
                <Col xs={24} xl={12}>
                    <MortalityPerEvent />
                </Col>
            </Row>
        </div>
    );
};

export default AnalysisCharts;
