import { createSlice } from '@reduxjs/toolkit';
import {
    getDeadFishCountingEngineStatus,
    getDeadFishCountingSites,
    stopDeadFishCountingEngine
} from 'redux/thunks';

const initialState = {
    sites: { data: [], loading: false },
    streaming: {
        isCounting: false,
        engineBaseUrl: null,
        siteId: null,
        siteName: null,
        localityNumber: null,
        penNumber: null,
        counts: []
    }
};

export const liveDeadFishCountingSlice = createSlice({
    name: 'liveDeadFishCounting',
    initialState,
    reducers: {
        resetDeadFishCountingSites: (state) => {
            state.sites = initialState.sites;
        },
        resetDeadFishCountingStreaming: (state) => {
            state.streaming = initialState.streaming;
        },
        setDeadFishCountingStreaming: (state, action) => {
            state.streaming = { ...state.streaming, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDeadFishCountingSites.pending, (state) => {
            state.sites.loading = true;
        });
        builder.addCase(getDeadFishCountingSites.fulfilled, (state, action) => {
            state.sites.loading = false;
            state.sites.data = action.payload || initialState.sites.data;
        });
        builder.addCase(getDeadFishCountingSites.rejected, (state) => {
            state.sites.loading = false;
            state.sites.data = initialState.sites.data;
        });

        builder.addCase(getDeadFishCountingEngineStatus.pending, (state, action) => {
            const { siteId } = action.meta.arg;
            const index = state.sites.data.findIndex((site) => site.id === siteId);

            const newSiteArray = [...state.sites.data];
            newSiteArray[index] = { ...state.sites.data[index], loading: true };
            state.sites.data = newSiteArray;
        });
        builder.addCase(getDeadFishCountingEngineStatus.fulfilled, (state, action) => {
            const { siteId } = action.meta.arg;
            const index = state.sites.data.findIndex((site) => site.id === siteId);

            const newSiteArray = [...state.sites.data];
            newSiteArray[index] = {
                ...state.sites.data[index],
                ...action.payload,
                loading: false
            };
            state.sites.data = newSiteArray;
        });
        builder.addCase(getDeadFishCountingEngineStatus.rejected, (state, action) => {
            const { siteId } = action.meta.arg;
            const index = state.sites.data.findIndex((site) => site.id === siteId);

            const newSiteArray = [...state.sites.data];
            newSiteArray[index] = { ...state.sites.data[index], loading: false };
            state.sites.data = newSiteArray;
        });

        builder.addCase(stopDeadFishCountingEngine.pending, (state, action) => {
            const { siteId } = action.meta.arg;
            const index = state.sites.data.findIndex((site) => site.id === siteId);

            const newSiteArray = [...state.sites.data];
            newSiteArray[index] = { ...state.sites.data[index], loading: true };
            state.sites.data = newSiteArray;
        });
        builder.addCase(stopDeadFishCountingEngine.fulfilled, (state, action) => {
            const { siteId } = action.meta.arg;
            const index = state.sites.data.findIndex((site) => site.id === siteId);

            const newSiteArray = [...state.sites.data];
            newSiteArray[index] = { ...state.sites.data[index], loading: false };
            state.sites.data = newSiteArray;
        });
        builder.addCase(stopDeadFishCountingEngine.rejected, (state, action) => {
            const { siteId } = action.meta.arg;
            const index = state.sites.data.findIndex((site) => site.id === siteId);

            const newSiteArray = [...state.sites.data];
            newSiteArray[index] = { ...state.sites.data[index], loading: false };
            state.sites.data = newSiteArray;
        });
    }
});

export const {
    resetDeadFishCountingSites,
    resetDeadFishCountingStreaming,
    setDeadFishCountingStreaming
} = liveDeadFishCountingSlice.actions;
