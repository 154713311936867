import { Button, Result } from 'antd';
import { withPageViewTracking } from 'hoc';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <Result
            status="404"
            title={t('notFound.title')}
            subTitle={t('notFound.subTitle')}
            extra={
                <Link to="/">
                    <Button type="primary">{t('button.backHome')}</Button>
                </Link>
            }
        />
    );
};

export default withPageViewTracking(NotFound, 'Not found');
