import { Column } from '@ant-design/plots';
import PropTypes from 'prop-types';

const FDColumn = ({
    data = [],
    height,
    color,
    tooltip = {},
    legend = { position: 'top' },
    className = '',
    chartConfig = {}
}) => {
    const config = {
        data,
        height,
        color,
        tooltip,
        isGroup: true,
        xField: 'name',
        yField: 'value',
        seriesField: 'type',
        legend: legend,
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            },
            ...chartConfig.xAxis
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            },
            ...chartConfig.yAxis
        },
        ...chartConfig
    };

    return <Column {...config} className={className} />;
};

FDColumn.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.array]),
    tooltip: PropTypes.object,
    legend: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
    className: PropTypes.string,
    chartConfig: PropTypes.object
};

export default FDColumn;
