// Graph type
export const AGGREGATE_GRAPH = 'Aggregate';
export const PER_DAY_GRAPH = 'PerDay';
export const DEFAULT_GRAPH = PER_DAY_GRAPH;
export const WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH = 'WoundTypeWithDeadFishCount';
export const WOUND_TYPE_WITH_STOCK_COUNT_GRAPH = 'WoundTypeWithStockCount';

// Chart type
export const LINE_CHART = 'LineChart';
export const BAR_CHART = 'BarChart';
export const DEFAULT_CHART_TYPE = LINE_CHART;

export const TOGGLE_FFI_PEN = {
    FFI: 'FFI',
    PEN: 'PEN'
};

export const EFFICIENCY_TOGGLE = {
    TON_PER_HOUR: 'TON_PER_HOUR',
    FISH_PER_30_SECONDS: 'FISH_PER_30_SECONDS'
};
