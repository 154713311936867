import { createSlice } from '@reduxjs/toolkit';
import {
    getServiceBoats,
    getStreamingStressLevels,
    getStressEngineStatus,
    stopStressEngine
} from 'redux/thunks';

const initialState = {
    serviceBoats: { data: [], loading: false },
    streaming: {
        isDetecting: false,
        engineBaseUrl: null,
        serviceBoatId: null,
        serviceBoatName: null,
        penNumber: null,
        stressLevels: []
    }
};

export const liveStressDetectionSlice = createSlice({
    name: 'liveStressDetection',
    initialState,
    reducers: {
        resetStressDetectionServiceBoats: (state) => {
            state.serviceBoats = initialState.serviceBoats;
        },
        resetStressDetectionStreaming: (state) => {
            state.streaming = initialState.streaming;
        },
        setStressDetectionStreaming: (state, action) => {
            state.streaming = { ...state.streaming, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getServiceBoats.pending, (state) => {
            state.serviceBoats.loading = true;
        });
        builder.addCase(getServiceBoats.fulfilled, (state, action) => {
            state.serviceBoats.loading = false;
            state.serviceBoats.data = action.payload || initialState.serviceBoats.data;
        });
        builder.addCase(getServiceBoats.rejected, (state) => {
            state.serviceBoats.loading = false;
            state.serviceBoats.data = initialState.serviceBoats.data;
        });

        builder.addCase(getStressEngineStatus.pending, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: true };
            state.serviceBoats.data = newServiceBoatArray;
        });
        builder.addCase(getStressEngineStatus.fulfilled, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = {
                ...state.serviceBoats.data[index],
                ...action.payload,
                loading: false
            };
            state.serviceBoats.data = newServiceBoatArray;
        });
        builder.addCase(getStressEngineStatus.rejected, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };
            state.serviceBoats.data = newServiceBoatArray;
        });

        builder.addCase(stopStressEngine.pending, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: true };
            state.serviceBoats.data = newServiceBoatArray;
        });
        builder.addCase(stopStressEngine.fulfilled, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };

            state.serviceBoats.data = newServiceBoatArray;
        });
        builder.addCase(stopStressEngine.rejected, (state, action) => {
            const { serviceBoatId } = action.meta.arg;
            const index = state.serviceBoats.data.findIndex((boat) => boat.id === serviceBoatId);

            const newServiceBoatArray = [...state.serviceBoats.data];
            newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };

            state.serviceBoats.data = newServiceBoatArray;
        });

        builder.addCase(getStreamingStressLevels.fulfilled, (state, action) => {
            state.streaming.stressLevels = action.payload;
        });
        builder.addCase(getStreamingStressLevels.rejected, (state) => {
            state.streaming.stressLevels = initialState.streaming.stressLevels;
        });
    }
});

export const {
    resetStressDetectionServiceBoats,
    resetStressDetectionStreaming,
    setStressDetectionStreaming
} = liveStressDetectionSlice.actions;
