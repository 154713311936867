import { createSelector } from '@reduxjs/toolkit';

const liveDeadFishCountingSelector = (state) => state.liveDeadFishCounting;

export const deadFishCountingSitesSelector = createSelector(
    liveDeadFishCountingSelector,
    (liveDeadFishCounting) => liveDeadFishCounting.sites
);

export const deadFishCountingStreamingSelector = createSelector(
    liveDeadFishCountingSelector,
    (liveDeadFishCounting) => liveDeadFishCounting.streaming
);
