import { Card, Col, Row, Spin } from 'antd';
import { COMPANY_NOTIFICATION_EXPLANATION } from 'common/constants';
import { getMortalityStatus } from 'common/utils';
import { FDGauge, FDTooltip } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCompanyNotifications } from 'redux/thunks';

const CompanyNotification = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: companyNotification, loading } = useSelector((s) => s.companyNotification);

    useEffect(() => {
        dispatch(getCompanyNotifications({ date: initialFilters.daily.fromDate }));
    }, [initialFilters.daily]);

    return (
        <Spin spinning={loading}>
            {!companyNotification.companyName ? (
                <Card className="text-center">
                    <img alt="ava" src={require('assets/Empty_Box.png')} />
                    <p className="text-neutral-300">No Data</p>
                </Card>
            ) : (
                <Card>
                    <div className="flex justify-end">
                        <FDTooltip explainInfo={COMPANY_NOTIFICATION_EXPLANATION} />
                    </div>

                    <Row gutter={24}>
                        <Col
                            xxl={5}
                            xl={8}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="flex flex-col justify-center items-center pb-8"
                        >
                            <span className="font-semibold text-xl">
                                Company {companyNotification.companyName}
                            </span>
                            <FDGauge
                                width={280}
                                height={180}
                                percent={companyNotification.deviation}
                            />
                            <div className="flex justify-center mt-4">
                                <table role="presentation">
                                    <tbody>
                                        <tr className="bg-slate">
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base mr-2">
                                                    {t('dailyReport.mortalityStatistics.mortality')}
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl text-left">
                                                <span>{companyNotification.totalCount}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">
                                                <span className="text-gray-500 text-base mr-2">
                                                    {t(
                                                        getMortalityStatus(
                                                            companyNotification.deviation
                                                        )
                                                    )}
                                                    :
                                                </span>
                                            </td>
                                            <td className="font-semibold text-xl text-left">
                                                <span>
                                                    {Math.round(
                                                        companyNotification.deviation * 100
                                                    ) + '%'}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>

                        <Col xxl={19} xl={16} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[16, 16]}>
                                {companyNotification.notificationSites?.map((site, index) => {
                                    return (
                                        <Col
                                            xxl={4}
                                            xl={8}
                                            lg={8}
                                            md={12}
                                            sm={8}
                                            xs={12}
                                            className="text-center"
                                            key={`siteData-${index}`}
                                        >
                                            <span className="font-semibold text-base">
                                                Site {site.siteName}
                                            </span>

                                            <FDGauge
                                                height={90}
                                                percent={site.deviation}
                                                isSmall={true}
                                            />

                                            <div className="bg-slate text-[15px]">
                                                <div>
                                                    <span className="mr-2 text-gray-500">
                                                        {t(getMortalityStatus(site.deviation))}
                                                    </span>
                                                    <span className="font-semibold">
                                                        {Math.round(site.deviation * 100)}%
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="mr-2 text-gray-500">
                                                        {t(
                                                            'dailyReport.mortalityStatistics.contribution'
                                                        ) + ':'}
                                                    </span>
                                                    <span className="font-semibold">
                                                        {Math.round(site.contribution * 100) + '%'}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="mr-2 text-gray-500">
                                                        {t(
                                                            'dailyReport.mortalityStatistics.pensCounted'
                                                        ) + ':'}
                                                    </span>
                                                    <span className="font-semibold">
                                                        {site.totalCountedPen}/{site.totalPen}
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Card>
            )}
        </Spin>
    );
};

export default CompanyNotification;
