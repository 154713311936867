import { ABOVE_AVERAGE, AVERAGE, BELOW_AVERAGE } from 'common/constants';

export const formatNumberToFixedDigits = (number, digit = 2) => {
    if (isNaN(number)) {
        return number;
    }

    return parseFloat(Number(number).toFixed(digit));
};

export const getMortalityStatus = (deviation = 0) => {
    deviation = deviation * 100;

    if (deviation < 0) {
        return BELOW_AVERAGE;
    } else if (deviation <= 80) {
        return AVERAGE;
    } else {
        return ABOVE_AVERAGE;
    }
};

export const getRandomBoolean = () => {
    const THRESHOLD = 128;

    return crypto.getRandomValues(new Uint8Array(1))[0] < THRESHOLD;
};
