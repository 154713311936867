import { useMsal } from '@azure/msal-react';
import { getRandomBoolean } from 'common/utils';
import { FDFishMortality, FDGeneralInfo, FDItemList } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getDeadFishDailyCounting, getStockCount } from 'redux/thunks';
import './index.scss';

const DashBoard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { accounts } = useMsal();

    const { data: site, loading: siteLoading } = useSelector((s) => s.siteContainingPens);
    const { data: currentProfile, loading } = useSelector((s) => s.profile);

    useEffect(() => {
        dispatch(getDeadFishDailyCounting());
        dispatch(getStockCount());
    }, []);

    return (
        <div className="dashboard">
            <div>
                <h2 className="mb-2">{t('dashboard.generalInformation.title')}</h2>
                <FDGeneralInfo
                    loading={loading}
                    logo={currentProfile.company?.logoUrl}
                    infoList={[
                        {
                            key: t('dashboard.generalInformation.companyName'),
                            value: currentProfile.company?.name
                        },
                        {
                            key: t('dashboard.generalInformation.name'),
                            value: currentProfile.name
                        },
                        {
                            key: t('dashboard.generalInformation.userRole'),
                            value: accounts[0]?.idTokenClaims?.roles
                        }
                    ]}
                    status={currentProfile.company?.status}
                />
            </div>

            <FDFishMortality />

            <div className="mt-5">
                <FDItemList
                    loading={siteLoading}
                    title={t('dashboard.fishMortality.siteList.title')}
                    itemList={site.map((item) => ({
                        id: item.id,
                        name: item.siteName,
                        link: `../site-detail/${item.id}`,
                        hasData: item.hasData,
                        hasNotification: getRandomBoolean()
                    }))}
                />
            </div>
        </div>
    );
};

export default withPageViewTracking(DashBoard, 'Dashboard');
