import { formatDateTime } from 'common/utils';
import { FDTable } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ComparisonChart from '../ComparisonChart';

const PensComparison = () => {
    const { t } = useTranslation();

    const { data: penComparison, loading } = useSelector((s) => s.penComparison);

    return (
        <div className="pens-comparison mt-5" data-testid="pen-comparison">
            <h2 className="mb-2">{t('comparison.pensComparison')}</h2>
            <ComparisonChart
                loading={loading}
                charts={penComparison.charts?.map((chart) => ({
                    type: `${chart.penNumber} - ${chart.siteName}`,
                    value: chart.count,
                    name: formatDateTime(chart.countingDate)
                }))}
            />

            <FDTable
                loading={loading}
                headers={
                    penComparison.tablePens?.length
                        ? [
                              {
                                  id: 1,
                                  title: 'comparison.penColumn',
                                  dataIndex: 'penNumber',
                                  width: '20%'
                              },
                              ...(penComparison.tablePens[0].fishes?.map((fish) => ({
                                  id: fish.fishName,
                                  title: `general.fishMortality.${fish.fishName}`,
                                  dataIndex: fish.fishName,
                                  key: fish.fishName,
                                  width: '20%'
                              })),
                              [])
                          ]
                        : []
                }
                data={penComparison.tablePens?.map((pen) =>
                    pen.fishes.reduce(
                        (acc, fish) => {
                            acc[fish.fishName] = fish.count.toString();
                            return acc;
                        },
                        { key: pen.penId, penNumber: `${pen.penNumber} - ${pen.siteName}` }
                    )
                )}
            />
        </div>
    );
};

export default PensComparison;
