import { useMsal } from '@azure/msal-react';
import { Button, Result } from 'antd';
import { BUTTON_GRAY_CLASS } from 'common/constants';
import { withPageViewTracking } from 'hoc';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <Result
            status="403"
            title={t('unauthorized.title')}
            subTitle={t('unauthorized.subTitle')}
            extra={
                <div className="flex justify-center">
                    <Button className={BUTTON_GRAY_CLASS} onClick={handleLogout}>
                        {t('button.logout')}
                    </Button>
                </div>
            }
        />
    );
};

export default withPageViewTracking(Unauthorized, 'Unauthorized');
