import { createAsyncThunk } from '@reduxjs/toolkit';
import { MORTALITY_API } from 'common/constants';
import { API } from 'services';

export const getMortalityList = createAsyncThunk(
    'mortalities/getMortalityList',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${MORTALITY_API}/list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getMortalityDetail = createAsyncThunk(
    'mortalities/getMortalityDetail',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${MORTALITY_API}/detail`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
