import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CountingResult = ({ loading = false, siteName = '', penNumber = '', result = {} }) => {
    const { t } = useTranslation();

    return (
        <div data-testid="counting-result">
            <div className="title text-center text-gray-700 font-semibold text-lg m-3">
                {`${t('countingPage.countingResult.countingResultOfSite')} ${siteName} - ${t(
                    'countingPage.countingResult.pen'
                )} ${penNumber}`}
            </div>

            <Spin spinning={loading}>
                <div className="flex justify-center">
                    <div className="flex flex-col justify-center items-center content-center xs:w-1/3 sm:w-36 h-32 xs:m-1 sm:m-4 bg-slate-600 rounded">
                        <p className="text-2xl text-white font-semibold m-0 py-2">
                            {result.laks || 0}
                        </p>
                        <p className="xs:text-base sm:text-lg text-slate-300 text-center m-0">
                            {t('general.fishMortality.productionFish')}
                        </p>
                    </div>

                    <div className="flex flex-col justify-center items-center content-center xs:w-1/3 sm:w-36 h-32 xs:m-1 sm:m-4 bg-slate-600 rounded">
                        <p className="text-2xl text-white font-semibold m-0 py-2">
                            {result.berggylt || 0}
                        </p>
                        <p className="xs:text-base sm:text-lg text-slate-300 m-0">
                            {t('general.fishMortality.berggylt')}
                        </p>
                    </div>

                    <div className="flex flex-col justify-center items-center content-center xs:w-1/3 sm:w-36 h-32 xs:m-1 sm:m-4 bg-slate-600 rounded">
                        <p className="text-2xl text-white font-semibold m-0 py-2">
                            {result.rognkjeks || 0}
                        </p>
                        <p className="xs:text-base sm:text-lg text-slate-300 m-0">
                            {t('general.fishMortality.rognkjeks')}
                        </p>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

CountingResult.propTypes = {
    loading: PropTypes.bool,
    siteName: PropTypes.string,
    penNumber: PropTypes.string,
    result: PropTypes.object
};

export default CountingResult;
