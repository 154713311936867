import {
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Row, Space, Spin, Tabs } from 'antd';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    LIGHT_GRAY,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_RESTORE_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDTable } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { deletePen, getPens, getSitesContainingPens, restorePen } from 'redux/thunks';
import Swal from 'sweetalert2';
import PenForm from './PenForm';

const Pen = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: pens, loading } = useSelector((s) => s.pen);

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [penFormModalState, setPenFormModalState] = useState({ open: false, pen: null });

    const columns = [
        {
            dataIndex: 'penNumber',
            title: 'general.table.penNumber',
            sorter: {
                compare: (a, b) => a.penNumber.localeCompare(b.penNumber),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'siteName',
            title: 'general.table.siteName',
            sorter: {
                compare: (a, b) => a.siteName.localeCompare(b.siteName),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'localityNumber',
            title: 'pen.table.localityNumber',
            sorter: {
                compare: (a, b) => a.localityNumber - b.localityNumber,
                multiple: 3
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'description',
            title: 'general.table.description'
        },
        {
            title: 'general.table.action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={BUTTON_GRAY_CLASS}
                            onClick={() => handleRestorePen(record.id)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={BUTTON_GRAY_CLASS}
                                onClick={() => handlePenFormShowModal(record)}
                            >
                                <EditOutlined />
                                <span>{t('general.action.edit')}</span>
                            </Button>
                            <Button
                                className={BUTTON_GREEN_CLASS}
                                onClick={() => handleDeletePen(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('general.action.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getPens({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleTabChange = (isDeleted) => {
        setIsDeleteTab(isDeleted);

        appInsights.trackEvent({ name: 'Pen tab change', properties: { isDeleted } });
    };

    const handleReload = (isDeleted) => {
        if (isDeleteTab === isDeleted) dispatch(getPens({ isDeleted: isDeleted }));

        dispatch(getSitesContainingPens());

        setIsDeleteTab(isDeleted);
    };

    const handlePenFormShowModal = (pen) => setPenFormModalState({ open: true, pen });

    const handleDeletePen = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deletePen(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(SUCCESSFULLY_DELETION_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });

        appInsights.trackEvent({ name: 'Pen delete', properties: { penId: id } });
    };

    const handleRestorePen = (id) => {
        Swal.fire({
            title: t('general.action.restoreModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.restoreModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restorePen(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(SUCCESSFULLY_RESTORE_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });

        appInsights.trackEvent({ name: 'Pen restore', properties: { penId: id } });
    };

    return (
        <>
            <h2 className="mb-2">{t('pen.title')}</h2>
            <Row>
                <Space>
                    <Button
                        className={`${BUTTON_BLUE_CLASS}`}
                        onClick={() => handlePenFormShowModal(null)}
                    >
                        <PlusOutlined />
                        <span>{t('pen.newPenBtn')}</span>
                    </Button>
                </Space>
            </Row>

            <Spin spinning={loading}>
                <Tabs
                    activeKey={isDeleteTab}
                    animated={true}
                    onChange={handleTabChange}
                    items={[
                        {
                            label: (
                                <span>
                                    <GlobalOutlined />
                                    <span>{t('pen.activePens')}</span>
                                </span>
                            ),
                            key: false,
                            children: <FDTable headers={columns} data={pens} rowKey="id" />
                        },
                        {
                            label: (
                                <span>
                                    <RestOutlined />
                                    <span>{t('pen.deletedPens')}</span>
                                </span>
                            ),
                            key: true,
                            children: <FDTable headers={columns} data={pens} rowKey="id" />
                        }
                    ]}
                />
            </Spin>

            <PenForm
                open={penFormModalState.open}
                pen={penFormModalState.pen}
                onClose={() => setPenFormModalState({ open: false, pen: null })}
                onReload={handleReload}
            />
        </>
    );
};

export default withPageViewTracking(Pen, 'Pen management');
