import { withPageViewTracking } from 'hoc';
import { useSelector } from 'react-redux';
import FilterEvents from './FilterEvents';
import ScoringDetail from './ScoringDetail/ScoringDetail';

const ScoringEvent = () => {
    const { selectedScoringEventId } = useSelector((s) => s.filter.data);

    return (
        <>
            <FilterEvents />
            {selectedScoringEventId && <ScoringDetail />}
        </>
    );
};

export default withPageViewTracking(ScoringEvent, 'Scoring event detail');
