import { Card } from 'antd';
import { CROWDING_CHART_CONFIG, GREEN } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { reduce } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { crowdingAreasSelector, eventDetailStartTimeSelector } from 'redux/selector';

const CrowdingArea = ({ sliderValues }) => {
    const { t } = useTranslation();

    const crowdingAreas = useSelector(crowdingAreasSelector);
    const eventStartTime = useSelector(eventDetailStartTimeSelector);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const filteredData = reduce(
            crowdingAreas,
            (accumulator, crowdingArea) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    eventStartTime,
                    crowdingArea.stop_time
                );

                if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                    accumulator.push({
                        name: formatSeconds(differenceInSeconds),
                        value: crowdingArea.area
                    });

                return accumulator;
            },
            []
        );

        setChartData(filteredData);
    }, [sliderValues]);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.crowdingArea')}</p>
            <FDLine
                data={chartData}
                height={300}
                isSingleLine={true}
                color={GREEN}
                chartConfig={CROWDING_CHART_CONFIG}
            />
        </Card>
    );
};

CrowdingArea.propTypes = {
    sliderValues: PropTypes.array
};

export default CrowdingArea;
