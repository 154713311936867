import { MessageOutlined } from '@ant-design/icons';
import { Button, Spin, Tooltip } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EventData from './EventData';
import EventNotes from './EventNotes';
import FilterEvents from './FilterEvents';
import FishMortality from './FishMortality';
import './StressEvent.scss';

const Event = () => {
    const { t } = useTranslation();

    const { showEventNotes } = useFlags();

    const { selectedStressEventId } = useSelector((s) => s.filter.data);
    const { loading } = useSelector((s) => s.eventDetail);

    const [isOpenEventNotes, setIsOpenEventNotes] = useState(false);
    const [hasNewEventNote, setHasNewEventNote] = useState(false);

    const toggleEventNotesCollapsible = () => {
        setIsOpenEventNotes(!isOpenEventNotes);

        appInsights.trackEvent({
            name: 'Event notes collapsible toggle',
            properties: { isOpenEventNotes: !isOpenEventNotes }
        });
    };

    return (
        <>
            <FilterEvents />

            {selectedStressEventId && (
                <div className="event-detail mt-2">
                    <Spin spinning={loading}>
                        <EventData />

                        <FishMortality />

                        {showEventNotes && (
                            <>
                                <EventNotes
                                    isOpenEventNotes={isOpenEventNotes}
                                    toggleEventNotesCollapsible={toggleEventNotesCollapsible}
                                    setHasNewEventNote={setHasNewEventNote}
                                />
                                <div className="fixed bottom-4 right-7">
                                    <Tooltip
                                        placement="topRight"
                                        title={t('eventDetail.eventNote.title')}
                                    >
                                        <Button
                                            loading={loading}
                                            onClick={toggleEventNotesCollapsible}
                                            className={`${BUTTON_BLUE_CLASS} rounded-full w-10 h-10 mb-2 ${
                                                hasNewEventNote ? 'note-button' : ''
                                            }`}
                                            icon={<MessageOutlined className="text-xl" />}
                                        />
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </Spin>
                </div>
            )}
        </>
    );
};

export default withPageViewTracking(Event, 'Event detail');
