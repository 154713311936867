import { DATE_FORMAT, FORMAT_TIME } from 'common/constants';
import moment from 'moment';
import PropTypes from 'prop-types';

export const formatDateTime = (value, format = DATE_FORMAT) => {
    if (!value) return;
    return moment(value).format(format);
};

export const formatSeconds = (seconds) => moment.utc(seconds * 1000).format(FORMAT_TIME);

export const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const getTimeAgo = (utcTimeString) => {
    const timeAgo = moment(utcTimeString).fromNow();

    return timeAgo;
};

export const getPreviousDate = (n) => moment().startOf('day').subtract(n, 'days');

export const calculateTimeDifference = (startTime, stopTime) => {
    if (!startTime || !stopTime) {
        return '-';
    }
    const start = moment(startTime);
    const stop = moment(stopTime);

    const duration = moment.duration(stop.diff(start));

    const hours = (duration.hours() + duration.days() * 24).toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    return `${hours}.${minutes}.${seconds}`;
};

export const getDateRange = (dateString, subtract = 0, add = 0) => {
    const fromDate = moment(dateString).startOf('days').subtract(subtract, 'days');
    const toDate = moment(dateString).endOf('day').add(add, 'days');

    return {
        fromDate,
        toDate
    };
};

export const getDifferenceInSeconds = (startTime, stopTime) => {
    return Math.round((moment(stopTime) - moment(startTime)) / 1000);
};

getDateRange.propTypes = {
    dateString: PropTypes.string,
    subtract: PropTypes.number,
    add: PropTypes.number
};

calculateTimeDifference.propTypes = {
    startTime: PropTypes.string,
    stopTime: PropTypes.string
};
