import { DribbbleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    SUCCESSFULLY_RESTORE_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDTree } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { restoreSite } from 'redux/thunks';
import './index.scss';

const RestoreSite = ({ open = false, site, onClose = () => {}, onReload = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const upsertLoading = useSelector((s) => s.site.loading);

    const [checkedKeys, setCheckedKeys] = useState([]);

    const onRestoreSite = async () => {
        const request = {
            id: site.id,
            penIds: checkedKeys.filter((key) => site.id !== key)
        };

        try {
            await dispatch(restoreSite(request)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_RESTORE_MESSAGE);

            onReload();
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({ name: 'Site restore', properties: { siteId: site.id } });
    };

    return (
        <Modal
            title={`${t('site.restoreSite.title')} ${site.siteName}`}
            open={open}
            onCancel={onClose}
            onOk={onClose}
            footer={
                <>
                    <Button onClick={onClose} className={BUTTON_WHITE_CLASS}>
                        {t('site.restoreSite.cancel')}
                    </Button>

                    <Button onClick={onRestoreSite} className={`${BUTTON_BLUE_CLASS} restore`}>
                        {t('site.restoreSite.restore')}
                    </Button>
                </>
            }
            width="350px"
            className="restore-site"
        >
            <Spin spinning={upsertLoading}>
                <div className="font-medium text-base mb-2">{t('site.restoreSite.selectPens')}</div>
                <FDTree
                    className="tree overflow-y-auto font-medium"
                    onCheck={(checkedKeysValue) => setCheckedKeys(checkedKeysValue)}
                    checkedKeys={checkedKeys}
                    treeData={[
                        {
                            title: t('site.restoreSite.allPens'),
                            key: site.id,
                            children: site.pens?.map((pen) => ({
                                title: pen.penNumber,
                                key: pen.id,
                                icon: <DribbbleOutlined />
                            }))
                        }
                    ]}
                />
            </Spin>
        </Modal>
    );
};

RestoreSite.propTypes = {
    open: PropTypes.bool,
    site: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default RestoreSite;
