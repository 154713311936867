import { createAsyncThunk } from '@reduxjs/toolkit';
import { msalInstance } from 'authConfig';
import { SERVICE_API } from 'common/constants';
import { GLOBAL_API } from 'services';

export const getServices = createAsyncThunk(
    'service/getServices',
    async (filters, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${SERVICE_API}?azureAdUserId=${localAccountId}`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
